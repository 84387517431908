import React, { useEffect, useMemo, useState } from 'react'
import ReportPayment from './ReportPayment';
import api from '../../../apiConfig';
import ReportLoader from '../../../loader-component/ReportLoader';
import toast from 'react-hot-toast';

const StartupFullReportGenerate = () => {
  const [fullReportPayment, setFullReportPayment] = useState(false);
  const [brandingDetail, setBrandingDetail] = useState([]);
  const [industryInsightDetail, setIndustryInsightDetail] = useState([]);
  const [marketResearchDetail, setMarketResearchDetail] = useState([]);
  const [swotAnalysisDetail, setSwotAnalysisDetail] = useState([]);
  const [pestleAnalysisDetail, setPestleAnalysisDetail] = useState([]);
  const [productRequirementDetail, setProductRequirementDetail] = useState([]);
  const [pathToMVPDetail, setPathToMVPDetail] = useState([]);
  const [businessOnePagerDetail, setBusinessOnePagerDetail] = useState([]);
  const [marketingPlanDetail, setMarketingPlanDetail] = useState([]);
  const [goToStrategyDetail, setGoToStrategyDetail] = useState([]);
  const [additionalFirstQuery, setAdditionalFirstQuery] = useState([]);
  const [additionalSecondQuery, setAdditionalSecondQuery] = useState([]);
  const [additionalThirdQuery, setAdditionalThirdQuery] = useState([]);

  const [loading, setLoading] = useState(true);

  const fullReportInfo = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("token"))
      if (token) {
        const response = await api.get("/reports/get-report-detail")
        if (response.data.success) {
          setFullReportPayment(response.data.reportData.fullReportPayment)
          const reportData = response.data.reportData;
          if (reportData && reportData.fullReportPayment) {
            const brandingDetailResponse = reportData.geminiBranding || [];
            const industryInsightDetailResponse = reportData.geminiIndustryInsight || [];
            const marketResearchDetailResponse = reportData.geminiMarketResearch || [];
            const swotAnalysisDetailResponse = reportData.geminiSwotAnalysis || [];
            const pestleAnalysisDetailResponse = reportData.geminiPestleAnalysis || [];
            const productRequirementDetailResponse = reportData.geminiProductRequirement || [];
            const pathToMVPDetailResponse = reportData.geminiPathToMVP || [];
            const businessOnePagerDetailResponse = reportData.geminiBusinessOnePager || [];
            const marketingPlanDetailResponse = reportData.geminiMarketingPlan || [];
            const goToStrategyDetailResponse = reportData.geminiGoToStrategy || [];
            const additionalFirstQueryResponse = reportData.geminiAdditionalQueryResultFirst || "No Data Available";
            const additionalSecondQueryResponse = reportData.geminiAdditionalQueryResultSecond || "No Data Available";
            const additionalThirdQueryResponse = reportData.geminiAdditionalQueryResultThird || "No Data Available";

            if (brandingDetailResponse.length > 0 && industryInsightDetailResponse.length > 0 && marketResearchDetailResponse.length > 0 && swotAnalysisDetailResponse.length > 0 && pestleAnalysisDetailResponse.length > 0 && productRequirementDetailResponse.length > 0 && pathToMVPDetailResponse.length > 0 && businessOnePagerDetailResponse.length > 0 && marketingPlanDetailResponse.length > 0 && goToStrategyDetailResponse.length > 0) {
              setBrandingDetail(brandingDetailResponse)
              setIndustryInsightDetail(industryInsightDetailResponse)
              setMarketResearchDetail(marketResearchDetailResponse)
              setSwotAnalysisDetail(swotAnalysisDetailResponse)
              setPestleAnalysisDetail(pestleAnalysisDetailResponse)
              setProductRequirementDetail(productRequirementDetailResponse)
              setPathToMVPDetail(pathToMVPDetailResponse)
              setBusinessOnePagerDetail(businessOnePagerDetailResponse)
              setMarketingPlanDetail(marketingPlanDetailResponse)
              setGoToStrategyDetail(goToStrategyDetailResponse)
              if (additionalFirstQueryResponse.length > 0) {
                setAdditionalFirstQuery(additionalFirstQueryResponse)
              }
              if (additionalSecondQueryResponse.length > 0) {
                setAdditionalSecondQuery(additionalSecondQueryResponse)
              }
              if (additionalThirdQueryResponse.length > 0) {
                setAdditionalThirdQuery(additionalThirdQueryResponse)
              }
              setLoading(false);
              return;
            }
          }
        }
      }
    }
    catch (err) {
      toast.error(err.response.data.message);
    }
  }

  useEffect(() => {
    let timer;
    const fetchData = async () => {
      await fullReportInfo();
      timer = setTimeout(fetchData, 5000);
    };
    fetchData();
    setTimeout(() => {
      clearTimeout(timer);
      setLoading(false);
    }, 15000);
    return () => clearTimeout(timer);
  }, []); 

  const formatContent = useMemo(() => {
    return (text) => {
      if (!text) return '';
      const lines = text.split('\n');
      for (let i = 0; i < lines.length; i++) {
        if (lines[i].startsWith('##')) {
          lines[i] = '<strong>' + lines[i].substring(3) + '</strong>';
        }
      }
      text = lines.join('\n');
      text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      text = text.replace(/^(.*?):/gm, '<strong>$1</strong>:');
      text = text.replace(/\* (.*?)(\n|$)/g, '<li>$1</li>');
      text = text.replace(/`(.*?)`/g, '<code>$1</code>');
      text = text.replace(/\b(https?:\/\/\S+)\b/g, '<a href="$1">$1</a>');
      text = text.replace(/(?:\r\n?|\n)+/g, '<br>');
      return text;
    };
  }, []);

  //Table Generation dynamic function

  // const formatContent = useMemo(() => {
  //   return (text) => {
  //     if (!text) return '';

  //     const lines = text.split('\n');
  //     let tableContent = [];
  //     let isInTable = false;
  //     let currentParagraph = '';

  //     for (let i = 0; i < lines.length; i++) {
  //       if (lines[i].trim().startsWith('|')) {
  //         // If not already in table, start table
  //         if (!isInTable) {
  //           tableContent.push('<table>');
  //           isInTable = true;
  //         }

  //         // Split the line into cells
  //         const cells = lines[i].split('|').map(cell => cell.trim()).filter(cell => cell !== '');

  //         // Generate table row
  //         const rowContent = cells.map((cell, index) => {
  //           return (i === 0) ? `<th>${cell}</th>` : `<td>${cell}</td>`;
  //         }).join('');

  //         tableContent.push('<tr>' + rowContent + '</tr>');
  //       } else {
  //         // If in table, close the table
  //         if (isInTable) {
  //           tableContent.push('</table>');
  //           isInTable = false;
  //         }

  //         // Apply other formatting rules
  //         lines[i] = lines[i].replace(/^##(.*?)$/gm, '<strong>$1</strong>'); // ##text to <strong>text</strong>
  //         lines[i] = lines[i].replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>'); // **text** to <strong>text</strong>
  //         lines[i] = lines[i].replace(/^(.*?):/gm, '<strong>$1</strong>:'); // text: to <strong>text</strong>:
  //         lines[i] = lines[i].replace(/\* (.*?)(\n|$)/g, '<li>$1</li>'); // * text to <li>text</li>
  //         lines[i] = lines[i].replace(/`(.*?)`/g, '<code>$1</code>'); // `text` to <code>text</code>
  //         lines[i] = lines[i].replace(/\b(https?:\/\/\S+)\b/g, '<a href="$1">$1</a>'); // URLs to clickable links

  //         // Accumulate lines into a paragraph
  //         currentParagraph += lines[i];

  //         // If next line is empty, close the current paragraph and reset
  //         if (lines[i + 1] === '') {
  //           tableContent.push('<p>' + currentParagraph + '</p>');
  //           currentParagraph = '';
  //         }
  //       }
  //     }

  //     // If still in table, close the table
  //     if (isInTable) {
  //       tableContent.push('</table>');
  //     }

  //     // Append the remaining paragraph if any
  //     if (currentParagraph !== '') {
  //       tableContent.push('<p>' + currentParagraph + '</p>');
  //     }

  //     return tableContent.join('\n');
  //   };
  // }, []);

  return (
    <div>
      {
        fullReportPayment ? (<div>
          {loading ?
            (
              <ReportLoader loading={loading} />
            ) :
            (<div>
              <h5 className="my-3 text-center"><strong>Branding</strong></h5>
              <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(brandingDetail) }} />
              <h5 className="my-3 text-center"><strong>Industry Insight</strong></h5>
              <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(industryInsightDetail) }} />
              <h5 className="my-3 text-center"><strong>Market Research</strong></h5>
              <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(marketResearchDetail) }} />
              <h5 className="my-3 text-center"><strong>SWOT Analysis</strong></h5>
              <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(swotAnalysisDetail) }} />
              <h5 className="my-3 text-center"><strong>Pestle Analysis</strong></h5>
              <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(pestleAnalysisDetail) }} />
              <h5 className="my-3 text-center"><strong>Product Requirement</strong></h5>
              <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(productRequirementDetail) }} />
              <h5 className="my-3 text-center"><strong>Path to MVP</strong></h5>
              <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(pathToMVPDetail) }} />
              <h5 className="my-3 text-center"><strong>Business OnePager</strong></h5>
              <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(businessOnePagerDetail) }} />
              <h5 className="my-3 text-center"><strong>Marketing Plan</strong></h5>
              <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(marketingPlanDetail) }} />
              <h5 className="my-3 text-center"><strong>Go-To-Strategy</strong></h5>
              <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(goToStrategyDetail) }} />
              <h5 className="my-3 text-center"><strong>First Additional Query</strong></h5>
              <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(additionalFirstQuery) }} />
              <h5 className="my-3 text-center"><strong>Second Additional Query</strong></h5>
              <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(additionalSecondQuery) }} />
              <h5 className="my-3 text-center"><strong>Third Additional Query</strong></h5>
              <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(additionalThirdQuery) }} />
            </div>)
          }
        </div>) :
          (<ReportPayment />)
      }
    </div>
  )
}

export default StartupFullReportGenerate