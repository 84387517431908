import React, { useContext, useEffect, useMemo, useState } from "react"
import api from "../apiConfig";
import { AuthContext } from "../../context/Auth.context";
import PaymentModal from "./PaymentModal";

const IndustryInsightsReport = ({ promptDataId }) => {

    const [currentMarketSize, setCurrentMarketSize] = useState('');
    const [growthTrajectory, setGrowthTrajectory] = useState('');
    const [trendsAndFactors, setTrendsAndFactors] = useState('');
    const [potentialChallenges, setPotentialChallenges] = useState('');
    const [customerNeeds, setCustomerNeeds] = useState('');
    const [improvedExistingSolutions, setImprovedExistingSolutions] = useState('');
    const [potentialBarriers, setPotentialBarriers] = useState('');
    const [navigateBarriers, setNavigateBarriers] = useState('');
    const [exitStrategies, setExitStrategies] = useState('');
    const [exitPatterns, setExitPatterns] = useState('');
    const { state } = useContext(AuthContext);

    const fullReportInfo = async () => {
        if (promptDataId) {
            try {
                const token = JSON.parse(localStorage.getItem("token"))
                if (token) {
                    const response = await api.post("/buzz-startup-ai/get-buzzstartups-full-report", { token, promptDataId })
                    if (response.data.success) {
                        setCurrentMarketSize(response.data.getFullReport?.industryInsightCurrentMarketSize);
                        setGrowthTrajectory(response.data.getFullReport?.industryInsightGrowthTrajectory)
                        setTrendsAndFactors(response.data.getFullReport?.industryInsightTrendsAndFactors);
                        setPotentialChallenges(response.data.getFullReport?.industryInsightPotentialChallenges);
                        setCustomerNeeds(response.data.getFullReport?.industryInsightCustomerNeeds)
                        setImprovedExistingSolutions(response.data.getFullReport?.industryInsightImprovedExistingSolutions);
                        setPotentialBarriers(response.data.getFullReport?.industryInsightPotentialBarriers)
                        setNavigateBarriers(response.data.getFullReport?.industryInsightNavigateBarriers);
                        setExitStrategies(response.data.getFullReport?.industryInsightExitStrategies);
                        setExitPatterns(response.data.getFullReport?.industryInsightExitPatterns)
                        return;
                    }
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else {
            console.log("Please provide prompt first");
        }
    }

    useEffect(() => {
        fullReportInfo();
    }, [])

    const formatContent = useMemo(() => {
        return (text) => {
            if (!text) return '';
            text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
            text = text.replace(/`(.*?)`/g, '<code>$1</code>');
            text = text.replace(/\b(https?:\/\/\S+)\b/g, '<a href="$1">$1</a>');
            text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
            text = text.replace(/(\d+\.\s[a-zA-Z]+:)/g, '<strong>$1</strong>');
            text = text.replace(/(\d+\.\s.*?:)/g, '<strong>$1</strong>');
            text = text.replace(/# (.*)/g, '<h1 style="font-size: 1.2em;">$1</h1>');
            return text;
        };
    }, []);

    return (
        <div>
            {state?.user?.paymentStatus ? 
            (<div>
                <h2 className="text-center">Industry Insights</h2>
                <h5 className="mb-0 mt-3"><strong>Current Size of the Target Market</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(currentMarketSize) }} />
                <h5 className="mb-1 mt-3"><strong>Growth Trajectory</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(growthTrajectory) }} />
                <h5 className="mb-1 mt-3"><strong>Trends and Factors Influencing Market Dynamics</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(trendsAndFactors) }} />
                <h5 className="mb-1 mt-3"><strong>Potential Challenges</strong></h5>
                <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(potentialChallenges) }} />
                <h5 className="mb-0 mt-3"><strong>Customer Needs and Pain Points</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(customerNeeds) }} />
                <h5 className="mb-1 mt-3"><strong>Improved Existing Solutions</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(improvedExistingSolutions) }} />
                <h5 className="mb-1 mt-3"><strong>Potential Barriers to Entry</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(potentialBarriers) }} />
                <h5 className="mb-1 mt-3"><strong> Strategies to Overcome or Navigate Barriers</strong></h5>
                <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(navigateBarriers) }} />
                <h5 className="mb-1 mt-3"><strong>Potential Exit Strategies</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(exitStrategies) }} />
                <h5 className="mb-1 mt-3"><strong>Exit Patterns</strong></h5>
                <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(exitPatterns) }} />
            </div>) :
                (
                    <div>
                        <PaymentModal />
                    </div>
                )}
        </div>
    )
}

export default IndustryInsightsReport