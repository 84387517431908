import React, { useContext, useEffect, useMemo, useState } from "react"
import api from "../apiConfig";
import { AuthContext } from "../../context/Auth.context";
import PaymentModal from "./PaymentModal";

const MarketPlanerComponent = ({ promptDataId }) => {

    const [businessAndProductOverview, setBusinessAndProductOverview] = useState('');
    const [targetAudience, setTargetAudience] = useState('');
    const [marketAnalysis, setMarketAnalysis] = useState('');
    const [USP, setUSP] = useState('');
    const [PositioningStrategy, setPositioningStrategy] = useState('');
    const [digitalMarketingStrategy, setDigitalMarketingStrategy] = useState('');
    const [campaignEvaluation, setCampaignEvaluation] = useState('');
    const { state } = useContext(AuthContext);

    const fullReportInfo = async () => {
        if (promptDataId) {
            try {
                const token = JSON.parse(localStorage.getItem("token"))
                if (token) {
                    const response = await api.post("/buzz-startup-ai/get-buzzstartups-full-report", { token, promptDataId })
                    if (response.data.success) {
                        const businessAndProductOverview = response?.data?.getFullReport?.marketPlanProductOverview;
                        const targetAudienceData = response?.data?.getFullReport?.marketPlanTargetAudience;
                        const marketAnalysisData = response?.data?.getFullReport?.marketPlanMarketAnalysis;
                        const uspData = response?.data?.getFullReport?.marketPlanUSP;
                        const positioningStrategyData = response?.data?.getFullReport?.marketPlanPositioningStrategy;
                        const digitalMarketingStrategyData = response?.data?.getFullReport?.marketPlanDigitalMarketingStrategy;
                        const campaignEvaluationData = response?.data?.getFullReport?.marketPlanCampaignEvaluation;
                        setBusinessAndProductOverview(businessAndProductOverview);
                        setTargetAudience(targetAudienceData);
                        setMarketAnalysis(marketAnalysisData);
                        setUSP(uspData);
                        setPositioningStrategy(positioningStrategyData);
                        setDigitalMarketingStrategy(digitalMarketingStrategyData);
                        setCampaignEvaluation(campaignEvaluationData);
                        return;
                    }
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else {
            console.log("Please provide prompt first");
        }
    }

    useEffect(() => {
        fullReportInfo();
    }, [])

    const formatContent = useMemo(() => {
        return (text) => {
            if (!text) return '';
            text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
            text = text.replace(/`(.*?)`/g, '<code>$1</code>');
            text = text.replace(/\b(https?:\/\/\S+)\b/g, '<a href="$1">$1</a>');
            text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
            text = text.replace(/(\d+\.\s[a-zA-Z]+:)/g, '<strong>$1</strong>');
            text = text.replace(/(\d+\.\s.*?:)/g, '<strong>$1</strong>');
            text = text.replace(/# (.*)/g, '<h1 style="font-size: 1.2em;">$1</h1>');
            return text;
        };
    }, []);

    return (
        <div>
            {state?.user?.paymentStatus ? (<div>
                <h2 className="text-center">Marketing Plan</h2>
                <h5 className="mb-1 mt-3"><strong>Business and Product Overview</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(businessAndProductOverview) }} />
                <h5 className="mb-1 mt-3"><strong>Target Audience</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(targetAudience) }} />
                <h5 className="mb-1 mt-3"><strong>Market Analysis</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(marketAnalysis) }} />
                <h5 className="mb-1 mt-3"><strong>Unique Selling Proposition (USP)</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(USP) }} />
                <h5 className="mb-1 mt-3"><strong>Positioning Strategy</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(PositioningStrategy) }} />
                <h5 className="mb-1 mt-3"><strong>Digital Marketing Strategy</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(digitalMarketingStrategy) }} />
                <h5 className="mb-0 mt-3"><strong>Campaign Evaluation</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(campaignEvaluation) }} />
            </div>) :
                (
                    <div>
                        <PaymentModal />
                    </div>
                )}
        </div>
    )
}

export default MarketPlanerComponent