import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import api from '../apiConfig';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const [userEmail, setUserEmail] = useState({ Email: "" })
    const router = useNavigate();

    const handleInput = (event) => {
        setUserEmail({ ...userEmail, [event.target.name]: event.target.value })
    }

    const formSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post("/all/forgot-password", { userEmail })
            localStorage.setItem("userEmail",JSON.stringify(userEmail.Email))
            if (response.data.success) {
                toast.success(response.data.message)
                setUserEmail({ Email: "" })
                router("/reset-password")
            }
        }
        catch (err) {
            toast.error(err.response.data.message)
            console.log(err);
        }
    }

    return (
        <div>
            <Card className="text-center verify-otp-div position-absolute start-25">
                <Card.Body>
                    <form onSubmit={formSubmit}>
                        <Card.Title><h2>Buzzstartups</h2></Card.Title>
                        <Card.Text>
                            <div className='verify-input-main-div d-flex justify-content-between mb-3'>
                                <div className='verify-label-div'>
                                    <label className='my-auto'>Email ID :</label>
                                </div>
                                <div className='verify-email-input-div'>
                                    <input type="email" name='Email' className="form-control email-input" onChange={handleInput} aria-describedby="emailHelp" />
                                </div>
                            </div>
                        </Card.Text>
                        <Button type='submit' variant="dark">Submit</Button>
                    </form>
                </Card.Body>
            </Card>
        </div>
    )
}

export default ForgotPassword