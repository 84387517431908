import React, { useEffect, useMemo, useState } from 'react'
import api from '../../../apiConfig';
import ReportPayment from './ReportPayment';
import ReportLoader from '../../../loader-component/ReportLoader';
import toast from 'react-hot-toast';

const ReportCustomQueryFirst = () => {

    const [additionalFirstQuery, setAdditionalFirstQuery] = useState([]);
    const [fullReportPayment, setFullReportPayment] = useState(false);
    const [loading, setLoading] = useState(true);

    const additionalFirstQueryInfo = async () => {
        try {
            const token = JSON.parse(localStorage.getItem("token"))
            if (token) {
                const response = await api.get("/reports/get-report-detail")
                if (response.data.success) {
                    setFullReportPayment(response.data.reportData.fullReportPayment)
                    if (response.data.reportData.fullReportPayment) {
                        const additionalFirstQueryResponse = response.data.reportData.geminiAdditionalQueryResultFirst || "No Data Available";
                        if (additionalFirstQueryResponse?.length > 0) {
                            setAdditionalFirstQuery(additionalFirstQueryResponse);
                            setLoading(false);
                            return;
                        }
                    }
                }
            }
        }
        catch (err) {
            toast.error(err.response.data.message);
            console.log(err);
        }
    }

    useEffect(() => {
        let timer;
        const fetchData = async () => {
            await additionalFirstQueryInfo();
            timer = setTimeout(fetchData, 5000);
        };
        fetchData();
        setTimeout(() => {
            clearTimeout(timer);
            setLoading(false);
        }, 10000);
        return () => clearTimeout(timer);
    }, []);

    const formatContent = useMemo(() => {
        return (text) => {
            if (!text) return '';
            const lines = text.split('\n');
            for (let i = 0; i < lines.length; i++) {
                if (lines[i].startsWith('##')) {
                    lines[i] = '<strong>' + lines[i].substring(3) + '</strong>';
                }
            }
            text = lines.join('\n');
            text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            text = text.replace(/^(.*?):/gm, '<strong>$1</strong>:');
            text = text.replace(/\* (.*?)(\n|$)/g, '<li>$1</li>');
            text = text.replace(/`(.*?)`/g, '<code>$1</code>');
            text = text.replace(/\b(https?:\/\/\S+)\b/g, '<a href="$1">$1</a>');
            text = text.replace(/(?:\r\n?|\n)+/g, '<br>');
            return text;
        };
    }, []);

    return (
        <div>
            {(fullReportPayment) ? (
                loading ? (
                    <ReportLoader loading={loading} />
                ) : (
                    <div>
                        <h5 className="my-3 text-center"><strong>First Additional Query </strong></h5>
                        <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(additionalFirstQuery) }} />
                    </div>
                )
            ) : (
                <ReportPayment />
            )}
        </div>
    )
}

export default ReportCustomQueryFirst