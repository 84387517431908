import React, { useContext, useEffect, useState } from 'react'
import { KycFormDashboardContext } from '../../../../context/KycFormDashboard.context'
import toast from 'react-hot-toast';
import api from '../../../apiConfig';
import Loader from '../../../loader-component/Loader';

const PancardComponent = () => {

  const { pancard } = useContext(KycFormDashboardContext)
  const [panDoc, setPanDoc] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [panData, setPanData] = useState({})
  const [loading, setLoading] = useState(false);

  const getPanCardDetail = async () => {
    setLoading(true)
    try {
      const response = await api.get("/investors/get-pancard-detail")
      if (response.data.success) {
        setPanData(response.data.pancardDetail)
      }
    }
    catch (err) {
      console.log(err);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getPanCardDetail()
  }, [])

  const handleOpenImage = (imageUrl) => {
    window.open(imageUrl, '_blank');
  };

  const handlePanDoc = (e) => {
    const file = e.target.files[0];
    setPanDoc(file);
    setSelectedFileName(file ? file.name : '');
  };

  const formSubmit = async (event) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append('panDoc', panDoc);
    if (formData) {
      setLoading(true)
      try {
        const response = await api.put("/investors/update-kyc-pancard-investor-data", formData)
        if (response.data.success) {
          toast.success(response.data.message)
          setPanDoc(null);
          setSelectedFileName('');
          getPanCardDetail()
        }
      }
      catch (error) {
        toast.error(error.response.data.message)
      }
      finally {
        setLoading(false);
      }
    }
    else {
      toast.error("Please fill the detail first...")
    }
  }

  return pancard &&
    <div>
      {loading ? (<Loader loading={true} />)
        :
        (<form onSubmit={formSubmit}>
          <h5 className='mt-3'>Pancard</h5>
          <p>Please use an image no larger than 1200px * 600px.</p>
          <div className='file-div'>
            <input type="file" className='input-file' onChange={handlePanDoc} id="pancardFileInput" />
            {selectedFileName ? <label htmlFor="pancardFileInput" className="custom-button">Selected file: {selectedFileName}</label> :
              <label htmlFor="pancardFileInput" className="custom-button">Upload Pancard</label>}
          </div>
          {
            panData?.pancardData && (
              <button type="button" className="btn btn-dark mt-2" onClick={() => handleOpenImage(panData?.pancardData)}>Pan Card Preview</button>
            )
          }
          <input type='submit' className='startup-basic-general-save-button text-center py-1 my-3' value="Save" />
        </form>
        )}
    </div>
}

export default PancardComponent