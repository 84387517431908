import React, { useContext, useEffect, useState } from 'react'
import { DocumentDashboardContext } from '../../../../context/DocumentDashboard.context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
// import { Document, Page, pdfjs } from 'react-pdf';
// import { saveAs } from 'file-saver';
import './PitchDeck.css'
import api from '../../../apiConfig'
import toast from 'react-hot-toast'
import Loader from '../../../loader-component/Loader'

const PitchDeck = () => {

    const { pitchDeck } = useContext(DocumentDashboardContext)
    const [isSaveButton, setIsSaveButton] = useState(false);
    const [pitchData, setPitchData] = useState([{ pitchName: "", pitchDescription: "", pitchFile: null }])
    const [loading, setLoading] = useState(false);
    const [pitchDecks, setPitchDecks] = useState([]);
    // const [numPages, setNumPages] = useState(null);
    // const [pageNumber, setPageNumber] = useState(1);

    const getPitchDeckFunction = async () => {
        setLoading(true);
        try {
            const response = await api.get("/startups/get-startup-pitch-deck-document")
            if (response.data.success) {
                setPitchDecks(response.data.pitchDeckDoc)
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    // const onDocumentLoadSuccess = ({ numPages }) => {
    //     setNumPages(numPages);
    // };

    // const handleDownload = async (pitchId) => {
    //     try {
    //         await api.get(`download-pitch-deck-document/${pitchId}`, { responseType: 'blob' });
    //     } catch (error) {
    //         console.error('Error downloading pitch deck:', error);
    //     }
    // };

    useEffect(() => {
        getPitchDeckFunction()
    }, [])

    // useEffect(() => {
    //     pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    // }, []);

    const handleOpenPdf = (pdfUrl) => {
        window.open(pdfUrl, '_blank');
    };


    const addFormButton = () => {
        if (!isSaveButton) {
            setIsSaveButton(true)
        }
        setPitchData([...pitchData, { pitchName: "", pitchDescription: "", pitchFile: null }])
    }

    const handleInput = (e, i) => {
        const { name, value, type, files } = e.target
        const newFormData = [...pitchData]
        if (type === 'file') {
            newFormData[i] = { ...newFormData[i], [name]: files[0] };
        } else {
            newFormData[i] = { ...newFormData[i], [name]: value };
        }
        setPitchData(newFormData)
    }

    const handleDelete = (i) => {
        const deleteValue = [...pitchData]
        deleteValue.splice(i, 1)
        setPitchData(deleteValue)
    }

    const deletePitchDeckDetail = async (pitchId, fileDoc) => {
        setLoading(true);
        try {
            const response = await api.delete(`/startups/remove-startup-pitch-deck-document/${encodeURIComponent(pitchDecks?._id)}`, { data: { fileDoc, pitchId } });
            if (response.data.success) {
                toast.success(response.data.message)
                return getPitchDeckFunction();
            }
        } catch (error) {
            toast.error(error.response.data.message)
            console.error('Error deleting item:', error);
        }
        finally {
            setLoading(false);
        }
    };

    const formSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        let formData = new FormData();
        pitchData.forEach((data, index) => {
            formData.append('pitchName', data.pitchName);
            formData.append('pitchDescription', data.pitchDescription);
            formData.append('pitchFile', data.pitchFile);
        });
        try {
            if (formData) {
                try {
                    const response = await api.post("/startups/startup-pitch-deck-document", formData)
                    if (response.data.success) {
                        toast.success(response.data.message)
                        setPitchData([]);
                        return getPitchDeckFunction();
                    }
                }
                catch (err) {
                    toast.error(err.response.data.message)
                }
                finally {
                    setLoading(false);
                }
            }
        }
        catch (err) {
            toast.error("Please fill the detail first...")
            console.log(err);
        }
    };

    return (pitchDeck &&
        <div>
            {loading ? (
                <Loader loading={loading} />
            ) : (
                <>
                    <div>
                        {pitchDecks?.pitchDeckDetails && pitchDecks?.pitchDeckDetails.map((pitchDeck, index) => (
                            <div className='mt-3 fouder-detail-css p-3' key={pitchDeck._id}>
                                <div className='delete-founder-button-css'>
                                    <button onClick={() => deletePitchDeckDetail(pitchDeck?._id, pitchDeck?.fileDoc)}><FontAwesomeIcon icon={faXmark} /></button>
                                </div>
                                <div className='startup-general-body'>
                                    <div className='startup-general-startup-name-div py-3'>
                                        <div className='py-2'>Pitch Name :</div>
                                        <div>
                                            <p>{pitchDeck.pitchName}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='startup-general-body'>
                                    <div className='startup-general-startup-name-div py-3'>
                                        <div className='py-2'>Pitch Description :</div>
                                        <div>
                                            <p>{pitchDeck.pitchDescription}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='startup-general-body'>

                                    {/* <div className='py-2'>PDF Viewer:</div> */}
                                    {/* <div>
                                        <Document
                                            file={pitchDeck.fileDoc}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                            onLoadError={(error) => console.error('Error loading PDF:', error)}
                                        >
                                            <Page pageNumber={pageNumber} />
                                        </Document>
                                        <p>
                                            Page {pageNumber} of {numPages}
                                        </p>
                                        <button onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber <= 1}>
                                            Previous Page
                                        </button>
                                        <button onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber >= numPages}>
                                            Next Page
                                        </button>
                                    </div> */}
                                    <div className='d-md-flex py-3 justify-content-md-center'>
                                        <button className='btn btn-dark' onClick={() => handleOpenPdf(pitchDeck.fileDoc)}>Pitch Document Download</button>
                                    </div>

                                </div>
                                {/* <button onClick={() => handleDownload(pitchDeck?._id)}>Download PDF</button> */}
                            </div>
                        ))}
                    </div>
                    <form onSubmit={formSubmit}>
                        {pitchData && pitchData.map((val, i) => {
                            return (
                                <div className='pitch-deck-body-css p-3'>
                                    <div className='delete-founder-button-css'>
                                        <button type='button' onClick={handleDelete}><FontAwesomeIcon icon={faXmark} /></button>
                                    </div>
                                    <div className='startup-pitch-deck-input-div'>
                                        <div className='pitch-deck-input-div-inside py-3'>
                                            <div>
                                                <input className='p-2 pitch-deck-input' type="text" placeholder='Document Name' name="pitchName" value={val.pitchName} onChange={(e) => handleInput(e, i)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='startup-pitch-deck-input-div'>
                                        <div className='pitch-deck-input-div-inside py-3'>
                                            <div>
                                                <input className='p-2 pitch-deck-input' type="text" placeholder='Document Description' name="pitchDescription" value={val.pitchDescription} onChange={(e) => handleInput(e, i)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='startup-pitch-deck-input-div'>
                                        <div className='pitch-deck-input-div-inside py-3'>
                                            <div>
                                                <input className='p-2 pitch-deck-input' type="file" name='pitchFile' onChange={(e) => handleInput(e, i)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        )}
                        <div className='add-founder-button-css'>
                            <button type='button' className='startup-team-add-button text-center py-2 my-3' onClick={addFormButton}>+ Add Document</button>
                        </div>
                        {/* {isSaveButton && <button type='submit' className='startup-basic-general-save-button text-center py-1 my-3'>Save</button>} */}
                        <button type='submit' className='startup-basic-general-save-button text-center py-1 my-3'>Save</button>
                    </form>
                </>
            )}
        </div>
    )
}

export default PitchDeck
