import React, { useContext, useEffect, useMemo, useState } from 'react'
import api from '../apiConfig';
import { AuthContext } from '../../context/Auth.context';
import PaymentModal from './PaymentModal';

const GetFullReport = ({ promptDataId }) => {

    const [reportData, setReportData] = useState({});
    const { state } = useContext(AuthContext);

    const brandingInfo = async () => {
        if (promptDataId) {
            try {
                const token = JSON.parse(localStorage.getItem("token"))
                if (token) {
                    const response = await api.post("/buzz-startup-ai/get-buzzstartups-full-report", { token, promptDataId })
                    if (response.data.success) {
                        setReportData(response?.data?.getFullReport);
                    }
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else {
            console.log("Please provide prompt first");
        }
    }

    useEffect(() => {
        brandingInfo();
    }, [])

    const formatContent = useMemo(() => {
        return (text) => {
            if (!text) return '';
            text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
            text = text.replace(/`(.*?)`/g, '<code>$1</code>');
            text = text.replace(/\b(https?:\/\/\S+)\b/g, '<a href="$1">$1</a>');
            text = text.replace(/(?:\r\n|\r|\n)/g, '<br />');
            text = text.replace(/(\d+\.\s[a-zA-Z]+:)/g, '<strong>$1</strong>');
            text = text.replace(/(\d+\.\s.*?:)/g, '<strong>$1</strong>');
            text = text.replace(/# (.*)/g, '<h1 style="font-size: 1.2em;">$1</h1>');
            return text;
        };
    }, []);

    return (
        <div>
            {state?.user?.paymentStatus ? (<div>
                <h2 className="text-center"><strong>Branding</strong></h2>
                <h5 className="mb-3 mt-3"><strong>Business Name : {reportData?.brandingBusinessTitle}</strong></h5>
                <h5 className="mb-0 mt-3"><strong>Summary</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.brandingBusinessSummary) }} />
                <h5 className="mb-1 mt-3"><strong>Feature</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.brandingBusinessFeature) }} />
                <h5 className="mb-1 mt-3"><strong>Business Name & Domain Name</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.brandingBusinessName) }} />
                <h5 className="mb-1 mt-3"><strong>Elevator pitch</strong></h5>
                <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.brandingBusinessElevatorPitch) }} />

                {/* industry insight */}
                <h2 className="text-center"><strong>Industry Insights</strong></h2>
                <h5 className="mb-0 mt-3"><strong>Current Size of the Target Market</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.industryInsightCurrentMarketSize) }} />
                <h5 className="mb-1 mt-3"><strong>Growth Trajectory</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.industryInsightGrowthTrajectory) }} />
                <h5 className="mb-1 mt-3"><strong>Trends and Factors Influencing Market Dynamics</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.industryInsightTrendsAndFactors) }} />
                <h5 className="mb-1 mt-3"><strong>Potential Challenges</strong></h5>
                <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.industryInsightPotentialChallenges) }} />
                <h5 className="mb-0 mt-3"><strong>Customer Needs and Pain Points</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.industryInsightCustomerNeeds) }} />
                <h5 className="mb-1 mt-3"><strong>Improved Existing Solutions</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.industryInsightImprovedExistingSolutions) }} />
                <h5 className="mb-1 mt-3"><strong>Potential Barriers to Entry</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.industryInsightPotentialBarriers) }} />
                <h5 className="mb-1 mt-3"><strong> Strategies to Overcome or Navigate Barriers</strong></h5>
                <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.industryInsightNavigateBarriers) }} />
                <h5 className="mb-1 mt-3"><strong>Potential Exit Strategies</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.industryInsightExitStrategies) }} />
                <h5 className="mb-1 mt-3"><strong>Exit Patterns</strong></h5>
                <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.industryInsightExitPatterns) }} />

                {/* Market research */}
                <h2 className="text-center"><strong>Market Research</strong></h2>
                <h5 className="mb-0 mt-3"><strong>Target Audience</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.marketResearchTargetAudience) }} />
                <h5 className="mb-1 mt-3"><strong>Market Size</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.marketResearchMarketSize) }} />
                <h5 className="mb-1 mt-3"><strong>Customer Needs, Pain Points & Challenges</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.marketResearchCustomerNeeds) }} />
                <h5 className="mb-1 mt-3"><strong>Competitive Landscape</strong></h5>
                <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.marketResearchCompetitiveLandscape) }} />
                <h5 className="mb-0 mt-3"><strong>Unique Selling Proposition (USP)</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.marketResearchUSP) }} />
                <h5 className="mb-1 mt-3"><strong>Consumer Behavior</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.marketResearchConsumerBehavior) }} />
                <h5 className="mb-1 mt-3"><strong>Market Positioning</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.marketResearchMarketPositioning) }} />

                {/* Swot Analysis */}
                <h2 className="text-center"><strong>Swot Analysis</strong></h2>
                <h5 className="mb-1 mt-3"><strong> Strength</strong></h5>
                <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.SWOTAnalysisStrength) }} />
                <h5 className="mb-0 mt-3"><strong>Weakness</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.SWOTAnalysisWeakness) }} />
                <h5 className="mb-1 mt-3"><strong>Opportunities</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.SWOTAnalysisOpportunities) }} />
                <h5 className="mb-1 mt-3"><strong>Threat</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.SWOTAnalysisThreat) }} />

                {/* pestle Analysis */}
                <h2 className="text-center"><strong>Pestle Analysis</strong></h2>
                <h5 className="mb-0 mt-3"><strong>Political Factor</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.pestleAnalysisPoliticalFactor) }} />
                <h5 className="mb-1 mt-3"><strong>Economic Factor</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.pestleAnalysisEconomicFactor) }} />
                <h5 className="mb-1 mt-3"><strong>Social Factors</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.pestleAnalysisSocialFactors) }} />
                <h5 className="mb-1 mt-3"><strong>Technological Factors</strong></h5>
                <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.pestleAnalysisTechnologicalFactors) }} />
                <h5 className="mb-0 mt-3"><strong>Legal Factors</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.pestleAnalysisLegalFactors) }} />
                <h5 className="mb-1 mt-3"><strong>Environmental Factors</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.pestleAnalysisEnvironmentalFactors) }} />

                {/* Product Requirement */}
                <h2 className="text-center"><strong>Product Requirement</strong></h2>
                <h5 className="mb-1 mt-3"><strong>Target Audience </strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.productRequirementTargetAudience) }} />
                <h5 className="mb-1 mt-3"><strong>Features and Functionality</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.productRequirementFeaturesAndFunctionality) }} />
                <h5 className="mb-1 mt-3"><strong> Technical Requirements</strong></h5>
                <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.productRequirementTechnicalRequirements) }} />
                <h5 className="mb-1 mt-3"><strong>Product Security</strong></h5>
                <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.productRequirementProductSecurity) }} />

                {/* Path to MVP */}
                <h2 className="text-center"><strong>Path to MVP</strong></h2>
                <h5 className="mb-1 mt-3"><strong> Technology Stack</strong></h5>
                <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.pathToMVPTechnologyStack) }} />
                <h5 className="mb-0 mt-3"><strong> Development Timeline</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.pathToMVPDevelopmentTimeline) }} />
                <h5 className="mb-1 mt-3"><strong>User Onboarding</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.pathToMVPUserOnboarding) }} />
                <h5 className="mb-1 mt-3"><strong>Market Validation</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.pathToMVPMarketValidation) }} />
                <h5 className="mb-1 mt-3"><strong>Post-Launch Evaluation</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.pathToMVPPostLaunchEvaluation) }} />

                {/* business OnePager */}
                <h2 className="text-center"><strong>Business Onepager</strong></h2>
                <h5 className="mb-1 mt-3"><strong> Business Name</strong></h5>
                <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.brandingBusinessTitle) }} />
                <h5 className="mb-0 mt-3"><strong> Tagline or Elevator Pitch</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.businessOnePagerElevatorPitch) }} />
                <h5 className="mb-1 mt-3"><strong>Problem Statement</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.businessOnePagerProblemStatement) }} />
                <h5 className="mb-1 mt-3"><strong>Solution Overview</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.businessOnePagerSolutionOverview) }} />
                <h5 className="mb-1 mt-3"><strong>Target Audience</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.businessOnePagerTargetAudience) }} />
                <h5 className="mb-1 mt-3"><strong>Revenue Model</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.businessOnePagerRevenueModel) }} />
                <h5 className="mb-1 mt-3"><strong>Market Opportunity</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.businessOnePagerMarketOpportunity) }} />
                <h5 className="mb-1 mt-3"><strong>Marketing and Sales Strategy</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.businessOnePagerSalesStrategy) }} />

                {/* Marketing Plan */}
                <h2 className="text-center"><strong>Marketing Plan</strong></h2>
                <h5 className="mb-1 mt-3"><strong>Business and Product Overview</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.marketPlanProductOverview) }} />
                <h5 className="mb-1 mt-3"><strong>Target Audience</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.marketPlanTargetAudience) }} />
                <h5 className="mb-1 mt-3"><strong>Market Analysis</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.marketPlanMarketAnalysis) }} />
                <h5 className="mb-1 mt-3"><strong>Unique Selling Proposition (USP)</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.marketPlanUSP) }} />
                <h5 className="mb-1 mt-3"><strong>Positioning Strategy</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.marketPlanPositioningStrategy) }} />
                <h5 className="mb-1 mt-3"><strong>Digital Marketing Strategy</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.marketPlanDigitalMarketingStrategy) }} />
                <h5 className="mb-0 mt-3"><strong>Campaign Evaluation</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.marketPlanCampaignEvaluation) }} />

                {/* go-to strategy */}
                <h2 className="text-center"><strong>Go to Strategy</strong></h2>
                <h5 className="mb-1 mt-3"><strong>Target Audience</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.goToMarketStrategyTargetAudience) }} />
                <h5 className="mb-1 mt-3"><strong> Key Components of a Successful Go-to-Market Plan</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(reportData?.goToMarketStrategyKeyPoints) }} />
            </div>) :
                (
                    <div>
                        <PaymentModal />
                    </div>
                )}
        </div>
    )
}

export default GetFullReport