import React, { useContext, useEffect, useState } from 'react'
import Loader from '../../loader-component/Loader'
import './CreatePartnerAccount.css'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../context/Auth.context'
import toast from 'react-hot-toast'
import api from '../../apiConfig'

const CreatePartnerAccount = () => {
    const [userData, setUserData] = useState(
        {
            FirstName: "",
            LastName: "",
            Email: "",
            Password: "",
            Role: "Partner"
        }
    );
    const router = useNavigate();
    const [loading, setLoading] = useState(false)
    const { state } = useContext(AuthContext)

    const handleChange = (event) => {
        setUserData({ ...userData, [event.target.name]: event.target.value })
    }

    const selectHandleChange = (event) => {
        setUserData({ ...userData, "Role": event.target.value })
    }

    const formSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true)
            if (userData.FirstName && userData.LastName && userData.Email && userData.Password && userData.Role) {
                const response = await api.post("/all/register", { userData })
                if (response.data.success) {
                    setUserData({ FirstName: "", LastName: "", Email: "", Password: "", Role: "Partner" })
                    router("/admin-home-detail");
                    toast.success(response.data.message);
                }
            }
        }
        catch (err) {
            toast.error(err.response.data.message)
            console.log(err);
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (state?.user?._id) {
          router('/')
        }
      }, [state])

    return (
        <div className='partner-account-main-div'>
            {loading ? (<Loader loading={true} />)
                :
                <div className='partner-account-form-div'>
                    <h1 className='partner-account-heading text-center border-bottom'>Create Partner Account</h1>
                    <form onSubmit={formSubmit}>
                        <div>
                            <label htmlFor="" className='partner-label-div'>Enter Name :- </label>
                            <input type="text" className='partner-input-div' name='FirstName' onChange={handleChange} required="required" placeholder='Enter Name' />
                        </div>
                        <div>
                            <label htmlFor="" className='partner-label-div'>Enter LastName :-</label>
                            <input type="text" className='partner-input-div' name='LastName' onChange={handleChange} required="required" placeholder='Enter LastName' />
                        </div>
                        <div>
                            <label htmlFor="" className='partner-label-div'>Enter Email :-</label>
                            <input type="text" className='partner-input-div' name='Email' onChange={handleChange} required="required" placeholder='Enter LastName' />
                        </div>
                        <div>
                            <label htmlFor="" className='partner-label-div'>Enter Password :-</label>
                            <input type="password" className='partner-input-div' name='Password' onChange={handleChange} required="required" placeholder='Enter LastName' />
                        </div>
                        <select className="form-select partner-select-role-css" onChange={selectHandleChange} aria-label="Default select example">
                            <option value="default">Partner</option>
                        </select>
                        <div className='partner-form-submit-div'>
                            <input type="submit" className='partner-form-submit-button' value="Submit" />
                        </div>
                    </form>
                </div>
            }
        </div>
    )
}

export default CreatePartnerAccount