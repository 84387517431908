import React, { useEffect, useState } from 'react'
import './AddReportCoupon.css';
import Loader from '../../loader-component/Loader';
import api from '../../apiConfig';
import toast from 'react-hot-toast';

const AddReportCoupon = () => {

    const [reportCouponDetail, setReportCouponDetail] = useState({
        couponCode: "",
        discountPercentage: "",
    })
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allCoupon, setAllCoupon] = useState();

    const handleChange = (e) => {
        setReportCouponDetail({ ...reportCouponDetail, [e.target.name]: e.target.value })
    }

    const handleAddButtonClick = () => {
        setShowModal(true);
        setReportCouponDetail({
            couponCode: "",
            discountPercentage: ""
        });
    };

    const handleModalClose = () => {
        setShowModal(false);
        setReportCouponDetail({
            couponCode: "",
            discountPercentage: ""
        });
    };

    const handleDelete = async (id) => {
        try {
            let response = await api.delete(`/admin/delete-report-coupon/${id}`);
            if (response.data.success) {
                toast.success(response.data.message)
                getAllCoupon();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getAllCoupon = async () => {
        try {
            const response = await api.get("/admin/all-discount-coupon")
            if (response.data.success) {
                setAllCoupon(response.data.allCoupons)
            }
        }
        catch (err) {
            toast.error(err.response.data.message)
        }
    }

    useEffect(()=>{
        getAllCoupon();
    },[])

    const formSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            if (reportCouponDetail.couponCode && reportCouponDetail.discountPercentage) {
                const response = await api.post("/admin/add-report-coupon", { reportCouponDetail }) 
                if (response.data.success) {
                    toast.success(response.data.message)
                    setLoading(false);
                    setShowModal(false);
                    getAllCoupon();
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='partner-account-main-div'>
            {loading ? (<Loader loading={true} />) : (
                <div>
                    <div className='table-shadow mb-4'>
                        <div><button type='button' className='add-report-button-css margin-right-css' onClick={() => handleAddButtonClick()}>Add Coupon</button></div>
                        {allCoupon ? (<div>
                            <table className='report-coupon-table text-center'>
                                <thead>
                                    <tr>
                                        <th className='report-table-heading text-center'>Coupon Code</th>
                                        <th className='report-table-heading text-center'>Discount %</th>
                                        <th className='report-table-heading text-center'>Delete Coupon</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allCoupon &&
                                        allCoupon?.map(data => (
                                            <tr>
                                                <td className='report-table-body text-center'>{data.couponCode}</td>
                                                <td className='report-table-body text-center'>{data.discountPercentage}%</td>
                                                <td className='report-table-body d-flex justify-content-center'>
                                                    <button className='margin-right-css delete-coupon-button-css' onClick={() => handleDelete(data._id)}>Delete</button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>):(
                            <div><h1 className='text-center'>No Coupon Found</h1></div>
                        )}
                    </div>
                    {
                        showModal && (
                            <div className='report-modal'>
                                <div className='report-modal-content'>
                                    <span className="report-modal-close" onClick={handleModalClose}>&times;</span>
                                    <h2>Add Coupon Code</h2>
                                    <form onSubmit={formSubmit}>
                                        <div>
                                            <label htmlFor="" className='partner-label-div'>Coupon Code :- </label>
                                            <input type="text" className='partner-input-div' name='couponCode' onChange={handleChange} placeholder='Enter Coupon Code' />
                                        </div>
                                        <div>
                                            <label htmlFor="" className='partner-label-div'>Discount Percentage</label>
                                            <input type="number" className='partner-input-div' name='discountPercentage' onChange={handleChange} placeholder='Enter Discount Percent' />
                                        </div>
                                        <input type="submit" className='add-coupon-submit-button' value="Add" />
                                    </form>
                                </div>
                            </div>
                        )
                    }
                </div>)}
        </div>
    )
}

export default AddReportCoupon