import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './VerifyOtp.css'
import toast from 'react-hot-toast';
import api from '../apiConfig';
import { useNavigate } from 'react-router-dom';

const VerifyOtp = () => {
    const [verifyDetail, setVerifyDetail] = useState({ EnteredOTP: "", Email: "" })
    const router = useNavigate();

    useEffect(() => {
        var email = JSON.parse(localStorage.getItem("userEmail"))
        if (email) {
            setVerifyDetail({ ...verifyDetail, "Email": email })
        }
    }, []);

    const resendOtp = async () => {
        try {
            if (verifyDetail.Email) {
                const response = await api.put("/all/resend-otp", { verifyDetail })
                if (response.data.success) {
                    toast.success(response.data.message)
                }
            }
        }
        catch (err) {
            toast.error(err.response.data.message)
            console.log(err);
        }
    };

    const handleInput = (event) => {
        setVerifyDetail({ ...verifyDetail, [event.target.name]: event.target.value })
    }

    const formSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post("/all/verify-otp", { verifyDetail })
            if (response.data.success) {
                localStorage.removeItem("userEmail");
                toast.success(response.data.message)
                setVerifyDetail({ otp: "", email: "" })
                router("/login")
            }
        }
        catch (err) {
            toast.error(err.response.data.message)
            console.log(err);
        }
    }


    return (
        <div>
            <Card className="text-center verify-otp-div position-absolute start-25">
                <Card.Body>
                    <form onSubmit={formSubmit}>
                        <Card.Title><h2>Buzzstartups</h2></Card.Title>
                        <Card.Text>
                            <div className='verify-input-main-div d-flex justify-content-between mb-3'>
                                <div className='verify-label-div'>
                                    <label className='my-auto'>Email ID :</label>
                                </div>
                                <div className='verify-email-input-div'>
                                    <input type="email" value={verifyDetail.Email} className="form-control email-input" aria-describedby="emailHelp" disabled />
                                </div>
                            </div>
                            <div className='verify-input-main-div d-flex justify-content-between mb-3'>
                                <div className='verify-label-div'>
                                    <label className='my-auto'>Enter OTP :</label>
                                </div>
                                <div className='verify-email-input-div'>
                                    <input type="text" name='EnteredOTP' onChange={handleInput} className="form-control email-input" required="required" aria-describedby="emailHelp" />
                                </div>
                            </div>
                        </Card.Text>
                        <Button type='submit' variant="dark">Submit</Button>
                        <Button type='button' variant="dark" className='m-2' onClick={resendOtp}>Resend</Button>
                    </form>
                </Card.Body>
            </Card>
        </div>
    )
}

export default VerifyOtp