import React, { useContext, useEffect, useMemo, useState } from "react"
import api from "../apiConfig";
import { AuthContext } from "../../context/Auth.context";
import PaymentModal from "./PaymentModal";

const PestelAnalysisComponent = ({ promptDataId }) => {

    const [politicalFactors, setMarketPoliticalFactors] = useState('');
    const [economicFactors,setEconomicFactors] = useState('');
    const [socialFactor,setSocialFactor] = useState('');
    const [technologicalFactors,setTechnologicalFactors] = useState('');
    const [legalFactors,setLegalFactors] = useState('');
    const [environmentalFactors,setEnvironmentalFactors] = useState('');
    const { state } = useContext(AuthContext);

    const fullReportInfo = async () => {
        if (promptDataId) {
            try {
                const token = JSON.parse(localStorage.getItem("token"))
                if (token) {
                    const response = await api.post("/buzz-startup-ai/get-buzzstartups-full-report", { token, promptDataId })
                    if (response.data.success) {
                        const PoliticalFactorsData = response?.data?.getFullReport?.pestleAnalysisPoliticalFactor;
                        const EconomicFactorsData = response?.data?.getFullReport?.pestleAnalysisEconomicFactor;
                        const SocialFactorsData = response?.data?.getFullReport?.pestleAnalysisSocialFactors;
                        const TechnologicalFactorsData = response?.data?.getFullReport?.pestleAnalysisTechnologicalFactors;
                        const LegalFactorsData = response?.data?.getFullReport?.pestleAnalysisLegalFactors;
                        const EnvironmentalFactorsData = response?.data?.getFullReport?.pestleAnalysisEnvironmentalFactors;
                        setMarketPoliticalFactors(PoliticalFactorsData);
                        setEconomicFactors(EconomicFactorsData);
                        setSocialFactor(SocialFactorsData);
                        setTechnologicalFactors(TechnologicalFactorsData);
                        setLegalFactors(LegalFactorsData);
                        setEnvironmentalFactors(EnvironmentalFactorsData);
                        return;
                    }
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else {
            console.log("Please provide prompt first");
        }
    }

    useEffect(() => {
        fullReportInfo();
    }, [])

    const formatContent = useMemo(() => {
        return (text) => {
            if (!text) return '';
            text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
            text = text.replace(/`(.*?)`/g, '<code>$1</code>');
            text = text.replace(/\b(https?:\/\/\S+)\b/g, '<a href="$1">$1</a>');
            text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
            text = text.replace(/(\d+\.\s[a-zA-Z]+:)/g, '<strong>$1</strong>');
            text = text.replace(/(\d+\.\s.*?:)/g, '<strong>$1</strong>');
            text = text.replace(/# (.*)/g, '<h1 style="font-size: 1.2em;">$1</h1>');
            return text;
        };
    }, []);

    return (
        <div>
            {state?.user?.paymentStatus ? (<div>
            <h2 className="text-center">Pestle Analysis</h2>
            <h5 className="mb-0 mt-3"><strong>Political Factor</strong></h5>
            <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(politicalFactors) }} />
            <h5 className="mb-1 mt-3"><strong>Economic Factor</strong></h5>
            <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(economicFactors) }} />
            <h5 className="mb-1 mt-3"><strong>Social Factors</strong></h5>
            <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(socialFactor) }} />
            <h5 className="mb-1 mt-3"><strong>Technological Factors</strong></h5>
            <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(technologicalFactors) }} />
            <h5 className="mb-0 mt-3"><strong>Legal Factors</strong></h5>
            <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(legalFactors) }} />
            <h5 className="mb-1 mt-3"><strong>Environmental Factors</strong></h5>
            <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(environmentalFactors) }} />
            </div>) :
                (
                    <div>
                        <PaymentModal />
                    </div>
                )}
        </div>
    )
}

export default PestelAnalysisComponent