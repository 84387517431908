import React, { useState } from 'react';
import StartupBrandingSummary from '../../startup-report-summary/StartupBrandingSummary';
import { Nav } from 'react-bootstrap'
import StartupIndustryInsightSummary from '../../startup-report-summary/StartupIndustryInsightSummary';
import StartupMarketResearchSummary from '../../startup-report-summary/StartupMarketResearchSummary';
import StartupSwotAnalysisSummary from '../../startup-report-summary/StartupSwotAnalysisSummary';
import StartupPestleAnalysisSummary from '../../startup-report-summary/StartupPestleAnalysisSummary';
import StartupProductRequirementSummary from '../../startup-report-summary/StartupProductRequirementSummary';
import StartupPathToMVPSummary from '../../startup-report-summary/StartupPathToMVPSummary';
import StartupBusinessOnePagerSummary from '../../startup-report-summary/StartupBusinessOnePagerSummary';
import StartupMarketingPlanSummary from '../../startup-report-summary/StartupMarketingPlanSummary';
import StartupGoToStrategySummary from '../../startup-report-summary/StartupGoToStrategySummary';


const StartupFullReportSummary = () => {

  const [selectedSection, setSelectedSection] = useState('link-1');
  const [toggleMenu, setToggleMenu] = useState(false);
  
  const handleNavItemClick = (key) => {
    setSelectedSection(key);
    setToggleMenu(true)
  };

  const renderReportContent = () => {
    switch (selectedSection) {
      case 'link-1':
        return <StartupBrandingSummary />;
      case 'link-2':
        return <StartupIndustryInsightSummary />
      case 'link-3':
        return <StartupMarketResearchSummary />
      case 'link-4':
        return <StartupSwotAnalysisSummary />
      case 'link-5':
        return <StartupPestleAnalysisSummary />
      case 'link-6':
        return <StartupProductRequirementSummary />
      case 'link-7':
        return <StartupPathToMVPSummary />
      case 'link-8':
        return <StartupBusinessOnePagerSummary />
      case 'link-9':
        return <StartupMarketingPlanSummary />
      case 'link-10':
        return <StartupGoToStrategySummary />
      default:
        return null;
    }
  };

  return (
    <div className=''>

      <div>
        <Nav className='border-bottom-navbar-css full-report-scrollbar' defaultActiveKey="link-1">
          <Nav.Item>
            <Nav.Link className='background-color-startup-navbar' onClick={() => handleNavItemClick('link-1')} eventKey="link-1">Branding</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className='background-color-startup-navbar' onClick={() => handleNavItemClick('link-2')} eventKey="link-2">Industry Insight</Nav.Link>
          </Nav.Item>
          <Nav.Item >
            <Nav.Link className='background-color-startup-navbar' onClick={() => handleNavItemClick('link-3')} eventKey="link-3">Market Research</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className='background-color-startup-navbar' onClick={() => handleNavItemClick('link-4')} eventKey="link-4">Swot Analysis</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className='background-color-startup-navbar' onClick={() => handleNavItemClick('link-5')} eventKey="link-5">Pestle Analysis</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className='background-color-startup-navbar' onClick={() => handleNavItemClick('link-6')} eventKey="link-6">Product Requirement</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className='background-color-startup-navbar' onClick={() => handleNavItemClick('link-7')} eventKey="link-7">Path to MVP</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className='background-color-startup-navbar' onClick={() => handleNavItemClick('link-8')} eventKey="link-8">Business OnePager</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className='background-color-startup-navbar' onClick={() => handleNavItemClick('link-9')} eventKey="link-9">Marketing Plan</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className='background-color-startup-navbar' onClick={() => handleNavItemClick('link-10')} eventKey="link-10">Go-To-Strategy</Nav.Link>
          </Nav.Item>
        </Nav>
        <div>
          {/* <div className=" border-0 mt-2 text-center">
            <h3 className=''><strong>Summary Report</strong></h3>
          </div> */}
          {renderReportContent()}
        </div>
      </div>
    </div>
  )
}

export default StartupFullReportSummary