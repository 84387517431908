import React, { useContext, useEffect, useState } from 'react'
import { GeneralDetailDashboardContext } from '../../../../../context/GeneralDetailDashboard.context'
import logoImage from "./../../../../../images/startup-dashboard-profile-logo.png"
import "../GeneralDetails.css"
import api from '../../../../apiConfig'
import toast from 'react-hot-toast'
import Loader from '../../../../loader-component/Loader'

const ProfilePic = () => {

  const { profilePic } = useContext(GeneralDetailDashboardContext)
  const [profilePicture, setProfilePicture] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');

  const getProfile = async () => {
    setLoading(true);
    try {
      const response = await api.get("/all/get-investor-profile-image")
      if (response.data.success) {
        setUserProfile(response.data.userProfilePictureData)
      }
    }
    catch (err) {
      console.log(err);
    }
    finally {
      setLoading(false);
    }
  }

  const handleProfilePicture = (e) => {
    const file = e.target.files[0];
    setProfilePicture(file);
    setSelectedFileName(file ? file.name : '');
  };

  const formSubmit = async (event) => {
    var formData = new FormData();
    formData.append('profilePicture', profilePicture);
    if (formData) {
      setLoading(true);
      try {
        const response = await api.put("/all/update-profile-picture-investor-data", formData)
        if (response.data.success) {
          toast.success(response.data.message)
          getProfile()
          setProfilePicture(null);
          setSelectedFileName('');
        }
      }
      catch (error) {
        toast.error(error.response.data.message)
        console.log(error);
      }
      finally {
        setLoading(false);
      }
    }
    else {
      toast.error("Please fill the detail first...")
    }
  }

  useEffect(() => {
    getProfile()
  }, [])

  return profilePic &&
    <div>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div className='basic-logo-body-div my-3'>
          <div>
            {userProfile?.length ? <img src={userProfile} alt="" /> : <img src={logoImage} alt="" />}
          </div>

          <div className='pt-4'>{selectedFileName ? <p>Selected file: {selectedFileName}</p> :
            <p>PNG or JPG no bigger than 1000px wide and tall.</p>}
          </div>
          <div className='pt-3'>
            <input type="file" name="InvestorProfileImage" onChange={handleProfilePicture} className='input-file' id="imageUploadInput" />
            <label htmlFor="imageUploadInput" className='investorProfileLabelUploadCss'>Upload</label>
            <button className='investorProfileLabelUploadCss importantMarginCss' onClick={formSubmit}>Submit</button>
          </div>
        </div>)}
    </div>
}

export default ProfilePic