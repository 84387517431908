import React, { useEffect, useMemo, useState } from 'react'
import api from '../../../apiConfig';
import ReportPayment from './ReportPayment';
import ReportLoader from '../../../loader-component/ReportLoader';
import toast from 'react-hot-toast';

const StartupPathToMVPGenerate = () => {
  const [pathToMVPDetail, setPathToMVPDetail] = useState();
  const [pathToMVPPayment, setPathToMVPPayment] = useState(false);
  const [fullReportPayment, setFullReportPayment] = useState(false);
  const [loading, setLoading] = useState(true);

  const pathToMVPInfo = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("token"))
      if (token) {
        const response = await api.get("/reports/get-report-detail")
        if (response.data.success) {
          setPathToMVPPayment(response.data.reportData.pathToMVPPayment)
          setFullReportPayment(response.data.reportData.fullReportPayment)
          if (response.data.reportData.pathToMVPPayment || response.data.reportData.fullReportPayment) {
            const pathToMVPDetailResponse = response.data.reportData.geminiPathToMVP || [];
            if (pathToMVPDetailResponse?.length > 0) {
              setPathToMVPDetail(pathToMVPDetailResponse);
              setLoading(false);
              return;
            }
          }
        }
      }
    }
    catch (err) {
      toast.error(err.response.data.message);
      console.log(err);
    }
  }

  useEffect(() => {
    let timer;
    const fetchData = async () => {
      await pathToMVPInfo();
      timer = setTimeout(fetchData, 5000);
    };
    fetchData();
    setTimeout(() => {
      clearTimeout(timer);
      setLoading(false);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  const formatContent = useMemo(() => {
    return (text) => {
      if (!text) return '';
      const lines = text.split('\n');
      for (let i = 0; i < lines.length; i++) {
          if (lines[i].startsWith('##')) {
              lines[i] = '<strong>' + lines[i].substring(3) + '</strong>';
          }
      }
      text = lines.join('\n');
      text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      text = text.replace(/^(.*?):/gm, '<strong>$1</strong>:');
      text = text.replace(/\* (.*?)(\n|$)/g, '<li>$1</li>');
      text = text.replace(/`(.*?)`/g, '<code>$1</code>');
      text = text.replace(/\b(https?:\/\/\S+)\b/g, '<a href="$1">$1</a>');
      text = text.replace(/(?:\r\n?|\n)+/g, '<br>');
      
      return text;
    };
  }, []);

  return (
    <div>
       {(pathToMVPPayment || fullReportPayment) ? (
        loading ? (
          <ReportLoader loading={loading} />
        ) : (
          <div>
            <h5 className="my-3 text-center"><strong>Path to MVP</strong></h5>
            <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(pathToMVPDetail) }} />
          </div>
        )
      ) : (
        <ReportPayment />
      )}
    </div>
  )
}

export default StartupPathToMVPGenerate