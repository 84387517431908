import React, { useContext, useEffect, useState } from 'react'
import "./KycDoc.css"
import { KycFormDashboardContext } from '../../../../context/KycFormDashboard.context'
import api from '../../../apiConfig'
import toast from 'react-hot-toast'
import Loader from '../../../loader-component/Loader'

const AadharCardComponent = () => {

    const { aadharcard } = useContext(KycFormDashboardContext)
    const [aadharFront, setAadharFront] = useState(null);
    const [aadharBack, setAadharBack] = useState(null);
    const [selectedFileNameFront, setSelectedFileNameFront] = useState('');
    const [selectedFileNameBack, setSelectedFileNameBack] = useState('');
    const [loading, setLoading] = useState(false);
    const [aadharData, setAadharData] = useState({})

    const getAadharCardDetail = async () => {
        // setLoading(true)
        try {
            const response = await api.get("/investors/get-aadarcard-detail")
            if (response.data.success) {
                setAadharData(response.data.aadharCardDetail)
            }
        }
        catch (err) {
            console.log(err);
        }
        // finally {
        //     setLoading(false);
        // }
    }

    useEffect(() => {
        getAadharCardDetail()
    }, [])

    const handleOpenImage = (imageUrl) => {
        window.open(imageUrl, '_blank');
    };

    const handleAadharFront = (e) => {
        const file = e.target.files[0];
        setAadharFront(file);
        setSelectedFileNameFront(file ? file.name : '');
    };

    const handleAadharBack = (e) => {
        const file = e.target.files[0];
        setAadharBack(file);
        setSelectedFileNameBack(file ? file.name : '');
    };

    const formSubmit = async (event) => {
        event.preventDefault();
        var formData = new FormData();
        formData.append('aadharFront', aadharFront);
        formData.append('aadharBack', aadharBack);
        if (formData) {
            setLoading(true);
            try {
                const response = await api.post("/investors/update-kyc-aadharcard-investor-data", formData)
                if (response.data.success) {
                    toast.success(response.data.message)
                    setAadharFront(null);
                    setAadharBack(null);
                    setSelectedFileNameFront('');
                    setSelectedFileNameBack('');
                    getAadharCardDetail();
                }
            }
            catch (error) {
                toast.error(error.response.data.message)
                console.log(error);
            }
            finally {
                setLoading(false);
            }
        }
        else {
            toast.error("Please fill the detail first...")
        }
    }

    return aadharcard &&
        <div>
            {loading ? (<Loader loading={true} />)
                :
                (<form onSubmit={formSubmit}>
                    <h5 className='mt-3'>Front Aadhar</h5>
                    <p>Please use an image no larger than 1200px * 600px.</p>
                    <div className='file-div'>
                        <input type="file" className='input-file' onChange={handleAadharFront} id="fileInput" />
                        {selectedFileNameFront ? <label htmlFor="fileInput" className="custom-button">Selected file: {selectedFileNameFront}</label> :
                            <label htmlFor="fileInput" className="custom-button">Upload Aadhar Front</label>}
                    </div>
                    {
                        aadharData?.aadharCardFront && (
                            <button type="button" className="btn btn-dark mt-2" onClick={() => handleOpenImage(aadharData?.aadharCardFront)}>Aadhar Card Front Preview</button>
                        )
                    }

                    <h5 className='mt-3'>Back Aadhar</h5>
                    <p>Please use an image no larger than 1200px * 600px.</p>
                    <div className='file-div'>
                        <input type="file" className='input-file' onChange={handleAadharBack} id="backFileInput" />
                        {selectedFileNameBack ? <label htmlFor="fileInput" className="custom-button">Selected file: {selectedFileNameBack}</label> :
                            <label htmlFor="backFileInput" className="custom-button">Upload Aadhar Back</label>}
                    </div>
                    {aadharData?.aadharCardBack && (<button type="button" className="btn btn-dark mt-2" onClick={() => handleOpenImage(aadharData?.aadharCardBack)}>Aadhar Card Back Preview</button>)}
                    <input type='submit' className='startup-basic-general-save-button text-center py-1 my-3' value="Save" />
                </form>
                )}
        </div>

}

export default AadharCardComponent
