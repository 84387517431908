import React from 'react'
import './FullReport.css'
import api from '../apiConfig';
import toast from 'react-hot-toast';

const PaymentModal = () => {

    const paymentHandle = async () => {
        try {
            const token = JSON.parse(localStorage.getItem("token"))
            if (token) {
                const response = await api.post("/buzz-startup-ai/update-buzzstartup-payment", { token })
                if (response.data.success) {
                    toast.success(response.data.message)
                    window.location.reload();
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='subscription-page-main-div rounded-sm shadow-sm p-3 mb-5'>
            <div className='payment-modal-popup-div'>
                <p className='text-center'><strong>Subscribe to our Monthly Subscription Plans and enjoy ton of benefits</strong></p>
            </div>
            <div className='d-flex card-width'>
                <div className="containerSummary">
                    <div className="card-payment-div border-1 rounded-lg">
                        <h2 className='text-center mt-4'><strong>Starter</strong></h2>
                        <p className='text-center'>Ideal for Small Businesses</p>
                        <h2 className='text-center mb-0 price-color'><strong>$ 20.00</strong></h2>
                        <p className='text-center mb-3'>USD / Month</p>
                        <button type="button" class="btn btn-dark text-light rounded-pill btn-sm mb-2" onClick={paymentHandle}>Subscribe now</button>
                        <ul>
                            <li>AI-powered content generation</li>
                            <li>Text-to-Image feature</li>
                            <li>Support for up to 10 projects</li>
                        </ul>
                    </div>
                    <div className="card-payment-div border-1 rounded-lg border-dark">
                        <span className='plan-featured bg-dark text-light'>Most Popular</span>
                        <h2 className='text-center mt-4'><strong>Starter</strong></h2>
                        <p className='text-center'>Ideal for Small Businesses</p>
                        <h2 className='text-center mb-0 price-color'><strong>$ 20.00</strong></h2>
                        <p className='text-center mb-3'>USD / Month</p>
                        <button type="button" class="btn btn-dark text-light rounded-pill btn-sm mb-2" onClick={paymentHandle}>Subscribe now</button>
                        <ul>
                            <li>AI-powered content generation</li>
                            <li>Text-to-Image feature</li>
                            <li>Support for up to 10 projects</li>
                        </ul>
                    </div>
                    <div className="card-payment-div border-1 rounded-lg">
                        <h2 className='text-center mt-4'><strong>Starter</strong></h2>
                        <p className='text-center'>Ideal for Small Businesses</p>
                        <h2 className='text-center mb-0 price-color'><strong>$ 20.00</strong></h2>
                        <p className='text-center mb-3'>USD / Month</p>
                        <button type="button" class="btn btn-dark text-light rounded-pill btn-sm mb-2" onClick={paymentHandle}>Subscribe now</button>
                        <ul>
                            <li>AI-powered content generation</li>
                            <li>Text-to-Image feature</li>
                            <li>Support for up to 10 projects</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentModal