import React, { useContext, useEffect, useMemo, useState } from "react"
import api from "../apiConfig";
import PaymentModal from "./PaymentModal";
import { AuthContext } from "../../context/Auth.context";

const SwotAnalysisComponent = ({ promptDataId }) => {

    const [strengths, setStrengths] = useState('');
    const [weaknesses,setWeaknesses] = useState('');
    const [opportunities,setOpportunities] = useState('');
    const [threats,setThreats] = useState('');
    const { state } = useContext(AuthContext);

    const fullReportInfo = async () => {
        if (promptDataId) {
            try {
                const token = JSON.parse(localStorage.getItem("token"))
                if (token) {
                    const response = await api.post("/buzz-startup-ai/get-buzzstartups-full-report", { token, promptDataId })
                    if (response.data.success) {
                        const strengthsData = response?.data?.getFullReport?.SWOTAnalysisStrength;
                        const weaknessesData = response?.data?.getFullReport?.SWOTAnalysisWeakness;
                        const opportunitiesData = response?.data?.getFullReport?.SWOTAnalysisOpportunities;
                        const threatsData = response?.data?.getFullReport?.SWOTAnalysisThreat;
                        setStrengths(strengthsData);
                        setWeaknesses(weaknessesData);
                        setOpportunities(opportunitiesData);
                        setThreats(threatsData);
                        return;
                    }
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else {
            console.log("Please provide prompt first");
        }
    }

    useEffect(() => {
        fullReportInfo();
    }, [])

    const formatContent = useMemo(() => {
        return (text) => {
            if (!text) return '';
            text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
            text = text.replace(/`(.*?)`/g, '<code>$1</code>');
            text = text.replace(/\b(https?:\/\/\S+)\b/g, '<a href="$1">$1</a>');
            text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
            text = text.replace(/(\d+\.\s[a-zA-Z]+:)/g, '<strong>$1</strong>');
            text = text.replace(/(\d+\.\s.*?:)/g, '<strong>$1</strong>');
            text = text.replace(/# (.*)/g, '<h1 style="font-size: 1.2em;">$1</h1>');
            return text;
        };
    }, []);

    return (
        <div>
            {state?.user?.paymentStatus ? (<div>
            <h2 className="text-center">Swot Analysis</h2>
            <h5 className="mb-1 mt-3"><strong> Strength</strong></h5>
            <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(strengths) }} />
            <h5 className="mb-0 mt-3"><strong>Weakness</strong></h5>
            <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(weaknesses) }} />
            <h5 className="mb-1 mt-3"><strong>Opportunities</strong></h5>
            <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(opportunities) }} />
            <h5 className="mb-1 mt-3"><strong>Threat</strong></h5>
            <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(threats) }} />
            </div>) :
                (
                    <div>
                        <PaymentModal />
                    </div>
                )}
        </div>
    )
}

export default SwotAnalysisComponent