import React, { useContext, useEffect, useMemo, useState } from "react"
import api from "../apiConfig";
import { AuthContext } from "../../context/Auth.context";
import PaymentModal from "./PaymentModal";

const BusinessOnepagerComponent = ({ promptDataId }) => {

    const [businessName, setBusinessName] = useState('');
    const [elevatorPitch, setElevatorPitch] = useState('');
    const [problemStatement, setProblemStatement] = useState('');
    const [solutionOverview, setSolutionOverview] = useState('');
    const [TargetAudience, setTargetAudience] = useState('');
    const [revenueModel, setRevenueModel] = useState('');
    const [marketOpportunity, setMarketOpportunity] = useState('');
    const [marketingAndSalesStrategy, setMarketingAndSalesStrategy] = useState('');
    const { state } = useContext(AuthContext);

    const fullReportInfo = async () => {
        if (promptDataId) {
            try {
                const token = JSON.parse(localStorage.getItem("token"))
                if (token) {
                    const response = await api.post("/buzz-startup-ai/get-buzzstartups-full-report", { token, promptDataId })
                    if (response.data.success) {
                        const businessNameData = response?.data?.getFullReport?.brandingBusinessTitle;
                        const elevatorPitchData = response?.data?.getFullReport?.businessOnePagerElevatorPitch;
                        const problemStatementData = response?.data?.getFullReport?.businessOnePagerProblemStatement;
                        const solutionOverviewData = response?.data?.getFullReport?.businessOnePagerSolutionOverview;
                        const targetAudienceData = response?.data?.getFullReport?.businessOnePagerTargetAudience;
                        const revenueModelData = response?.data?.getFullReport?.businessOnePagerRevenueModel;
                        const marketOpportunityData = response?.data?.getFullReport?.businessOnePagerMarketOpportunity;
                        const marketingAndSalesStrategyData = response?.data?.getFullReport?.businessOnePagerSalesStrategy;
                        setBusinessName(businessNameData);
                        setElevatorPitch(elevatorPitchData);
                        setProblemStatement(problemStatementData);
                        setSolutionOverview(solutionOverviewData);
                        setTargetAudience(targetAudienceData);
                        setRevenueModel(revenueModelData);
                        setMarketOpportunity(marketOpportunityData);
                        setMarketingAndSalesStrategy(marketingAndSalesStrategyData);
                        return;
                    }
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else {
            console.log("Please provide prompt first");
        }
    }

    useEffect(() => {
        fullReportInfo();
    }, [])

    const formatContent = useMemo(() => {
        return (text) => {
            if (!text) return '';
            text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
            text = text.replace(/`(.*?)`/g, '<code>$1</code>');
            text = text.replace(/\b(https?:\/\/\S+)\b/g, '<a href="$1">$1</a>');
            text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
            text = text.replace(/(\d+\.\s[a-zA-Z]+:)/g, '<strong>$1</strong>');
            text = text.replace(/(\d+\.\s.*?:)/g, '<strong>$1</strong>');
            text = text.replace(/# (.*)/g, '<h1 style="font-size: 1.2em;">$1</h1>');
            return text;
        };
    }, []);

    return (
        <div>

            {state?.user?.paymentStatus ? (<div>
                <h2 className="text-center">Business Onepager</h2>

                <h5 className="mb-1 mt-3"><strong> Business Name</strong></h5>
                <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(businessName) }} />
                <h5 className="mb-0 mt-3"><strong> Tagline or Elevator Pitch</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(elevatorPitch) }} />
                <h5 className="mb-1 mt-3"><strong>Problem Statement</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(problemStatement) }} />
                <h5 className="mb-1 mt-3"><strong>Solution Overview</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(solutionOverview) }} />
                <h5 className="mb-1 mt-3"><strong>Target Audience</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(TargetAudience) }} />
                <h5 className="mb-1 mt-3"><strong>Revenue Model</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(revenueModel) }} />
                <h5 className="mb-1 mt-3"><strong>Market Opportunity</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(marketOpportunity) }} />
                <h5 className="mb-1 mt-3"><strong>Marketing and Sales Strategy</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(marketingAndSalesStrategy) }} />
            </div>) :
                (
                    <div>
                        <PaymentModal />
                    </div>
                )}
        </div>
    )
}

export default BusinessOnepagerComponent