import React, { useEffect, useState } from 'react'
import './InvestorHome.css'
import api from '../../apiConfig';

const InvestorHome = () => {

    const [checkInvestorVerified, setInvestorVerified] = useState();

    useEffect(() => {
        const getUrlParameter = (name) => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            return urlSearchParams.get(name);
        };
        const token = getUrlParameter('token');
        if (token) {
            localStorage.setItem('token', JSON.stringify(token));
        }
    }, []);

    const InvestorDetailFunction = async () => {
        const token = JSON.parse(localStorage.getItem("token"));
        if (token) {
            try {
                const response = await api.post("/investors/investor-basic-detail", { token })
                if (response.data.success) {
                    setInvestorVerified(response.data.isInvestorVerified)
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        InvestorDetailFunction();
    })

    return (
        <div className='invester-home-css'>
            <div className='invester-pending-verify-page-div'>
                <div>
                    <h4 className='p-3'>Your Profile is pending for verification</h4>
                </div>
                <p className='p-3'>
                    Your profile has been submitted for verification. Once the verification process
                    is completed successfully, you will be able to commence your investment activities.
                    To facilitate a swift verification process, please make sure to include any necessary
                    information that may be missing in your profile and fulfill the Know Your Customer
                    (KYC) requirements.
                </p>
            </div>

        </div>
    )
}

export default InvestorHome