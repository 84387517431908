import React, { useContext, useEffect, useState } from 'react';
import "./Login.css"
import { useNavigate } from 'react-router-dom';
import googleImage from "./../../images/google-image.png"
import investorImage from "./../../images/login_investor.svg"
import startupImage from "./../../images/startup-login-image.svg"
import partnerImage from "./../../images/partnerImage.jpg"
import api from '../apiConfig';
import toast from 'react-hot-toast';
import { AuthContext } from '../../context/Auth.context';
import { LoginSocialGoogle } from 'reactjs-social-login';
import Loader from '../loader-component/Loader';

const Login = () => {
  const router = useNavigate();
  const [userData, setUserData] = useState({ Email: "", Password: "", Role: "Startup" })
  const [selectedOption, setSelectedOption] = useState("Startup");
  const [imageSrc, setImageSrc] = useState(startupImage);
  const { state, dispatch } = useContext(AuthContext);
  const [startupFormDetail, setStartupFormDetail] = useState();
  const [startupStatus, setStartupStatus] = useState(false);
  const [InvestorFormDetail, setInvestorFormDetail] = useState();
  const [InvestorStatus, setInvestorStatus] = useState(false);
  const [emailData, setEmailData] = useState({ firstName: "", lastName: "", email: "", googleId: "" });
  const [loading, setLoading] = useState(false);

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    if (value === "Startup") {
      setImageSrc(startupImage);
      setUserData({ ...userData, [event.target.name]: value })
    } else if (value === "Investor") {
      setImageSrc(investorImage);
      setUserData({ ...userData, [event.target.name]: value })
    }
    else if (value === "Admin") {
      setImageSrc(partnerImage);
      setUserData({ ...userData, [event.target.name]: value })
    }
    else if (value === "Partner") {
      setImageSrc(partnerImage);
      setUserData({ ...userData, [event.target.name]: value })
    }
  };

  const handleSocialLogin = async (provider, data) => {
    try {
      const email = provider?.data?.email;
      const googleId = provider?.data?.sub;
      const firstName = provider?.data?.given_name;
      const lastName = provider?.data?.family_name;
      setEmailData({ firstName, lastName, email, googleId });
      setLoading(true);
      // checkUserPresent();
    } catch (error) {
      console.error("Error in handleSocialLogin:", error);
    }
  }

  useEffect(() => {
    checkUserPresent();
  }, [emailData]);

  const checkUserPresent = async () => {
    if (emailData.firstName && emailData.lastName && emailData.email && emailData.googleId) {
      const response = await api.post("/all/check-google-login-user-present", { emailData })
      try {
        if (response.data.success) {
          const checkRole = response?.data?.user?.Role
          localStorage.setItem("token", JSON.stringify(response.data.token))
          if (checkRole) {
            if (checkRole === "Startup") {
              dispatch({
                type: "LOGIN",
                payload: response.data.user
              })
              setLoading(false)
              return startupDetailFunction();
            }
            if (checkRole === "Investor") {
              dispatch({
                type: "LOGIN",
                payload: response.data.user
              })
              setLoading(false)
              return InvestorDetailFunction();
            }
          }
          setLoading(false)
          return router("/add-user-role")
        }
      }
      catch (err) {
        setLoading(false)
        console.log("Error", err);
      }
    }
    else {
      console.log("data not added yet");
    }

  }

  const handleInput = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }

  const formSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      if (userData.Email && userData.Password && userData.Role) {
        const response = await api.post("/all/login", { userData })
        if (response.data.success) {
          if (response.data.user?.Role == "Startup") {
            dispatch({
              type: "LOGIN",
              payload: response.data.user
            })
            localStorage.setItem("token", JSON.stringify(response.data.token))
            setUserData({ email: "", password: "" })
            toast.success(response.data.message);
            startupDetailFunction();
            setLoading(false)
          }

          if (response.data.user?.Role == "Investor") {
            dispatch({
              type: "LOGIN",
              payload: response.data.user
            })
            localStorage.setItem("token", JSON.stringify(response.data.token))
            setUserData({ email: "", password: "" })
            // router("/investor-form-account");
            toast.success(response.data.message);
            InvestorDetailFunction();
            setLoading(false)
          }

          if (response.data.user?.Role == "Admin") {
            dispatch({
              type: "LOGIN",
              payload: response.data.user
            })
            localStorage.setItem("token", JSON.stringify(response.data.token))
            setUserData({ email: "", password: "" })
            router("/admin-home-detail");
            toast.success(response.data.message);
            setLoading(false)
            // InvestorDetailFunction();
          }
        }
        else {
          toast.error(response.data.message)
          setLoading(false)
        }
      }
      else {
        setLoading(false)
        toast.error("All fields are mandatory")
      }
    }
    catch (error) {
      if (error.response.data.isVerifyAccount) {
        toast.success("otp sent successfully to your email")
        localStorage.setItem("userEmail", JSON.stringify(userData.Email))
        router("/verify-otp")
        setLoading(false)
      }
      toast.error(error.response.data.message);
      setLoading(false)
    }
  }

  const startupDetailFunction = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      try {
        const response = await api.post("/startups/startup-basic-detail", { token })
        if (response.data.success) {
          setStartupFormDetail(response.data.startupDetail)
          setStartupStatus(response.data.startupFormStatus)
          router("/overview-profile")
          setLoading(false)
        }
      } catch (error) {
        router("/start-up-account")
        console.log(error);
        setLoading(false)
      }
    }
  }

  const InvestorDetailFunction = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      try {
        const response = await api.post("/investors/investor-basic-detail", { token })
        if (response.data.success) {
          if (response.data.isInvestorVerified == 1) {
            setInvestorFormDetail(response.data.InvestorDetail)
            setInvestorStatus(response.data.InvestorFormStatus)
            setLoading(false)
            return router("/investor-opportunity")
          }
          setLoading(false)
          return router("/investor/home")
        }
      } catch (error) {
        setLoading(false)
        router("/investor-form-account")
        console.log(error);
      }
    }
  }

  useEffect(() => {
    if (state?.user?.Email) {
      router('/')
    }
  }, [state])

  return (
    <div className='login-body'>
      {loading ? (
        <Loader loading={loading} />
      ) :
        (
          <div className="login-inside-body">
            <div>
              <p className='mb-0 logo-cursor' onClick={() => router("/")}>BuzzStartups</p>
            </div>
            <div className="form-and-image-div">
              <div className="login-form-div">
                <h3 className='mb-3 login-company-name-css'>Login to BuzzStartups</h3>
                <form onSubmit={formSubmit}>
                  <div className='radio-form-css-div'>
                    <div>
                      <input type="radio" name="Role" value="Startup" onChange={handleOptionChange} checked={selectedOption === "Startup"} />
                      <p className='mb-0 px-1'>Startup</p>
                    </div>
                    <div>
                      <input type="radio" name="Role" value="Investor" onChange={handleOptionChange} checked={selectedOption === "Investor"} />
                      <p className='mb-0 px-1'>Investor</p>
                    </div>
                  </div>

                  <div className='radio-form-css-div'>
                    <div>
                      <input type="radio" name="Role" value="Admin" onChange={handleOptionChange} checked={selectedOption === "Admin"} />
                      <p className='mb-0 px-1'>Agent</p>
                    </div>
                    <div>
                      <input type="radio" name="Role" value="Partner" onChange={handleOptionChange} checked={selectedOption === "Partner"} />
                      <p className='mb-0 px-1'>Partner</p>
                    </div>
                  </div>

                  <div className='horizontal-line-css-div'></div>
                  <LoginSocialGoogle className='google-button'
                    client_id={'987812959208-k9h9rjofa1ala1qmqvo5ui4pciejq0bc.apps.googleusercontent.com'}
                    isGetOnlyToken={true}
                    onResolve={(provider, data) => {
                      handleSocialLogin(provider, data)
                    }}
                    scope='email profile'
                    onReject={(err) => {
                      console.log(err)
                    }}
                  >
                    <div>
                      <img src={googleImage} alt="" />
                    </div>
                    <p className='mb-0'>Login with Google</p>

                  </LoginSocialGoogle>
                  <div className='horizontal-line-css-second'>
                    <span className='half-horizontal-line'></span>
                    <span className='or-horizontal-text'>OR</span>
                    <span className='half-horizontal-line'></span>
                  </div>
                  <div className='login-email-div'>
                    <input type="text" required="required" name='Email' onChange={handleInput} />
                    <span>Enter Email</span>
                  </div>
                  <div className='login-password-div'>
                    <input type="password" required="required" name='Password' onChange={handleInput} />
                    <span>Enter Password</span>
                  </div>
                  <p className='forgot-password-text-css' onClick={() => router("/forgot-password")}>Forgot my password</p>
                  <input type="submit" value="Log In" className='login-button-color' />
                  <div className='horizontal-line-css-div'></div>
                  <p className='login-signup-link-css'>Don't have an account? <span className='sign-up-link-css' onClick={() => router("/register")}>Sign Up Now</span></p>
                </form>
              </div>
              <div className="login-image-div" >
                <img src={imageSrc} alt="Selected Option" />
              </div>
            </div>
          </div>
        )}
    </div>
  )
}

export default Login
