import React, { useEffect, useState } from 'react'
import api from '../../apiConfig';
import Loader from '../../loader-component/Loader';
import toast from 'react-hot-toast';

const AddPayment = () => {

    const [paymentData, setPaymentData] = useState({
        chatGptMode: '',
        chatGptIndividualPrice: '',
        chatGptFullReportPrice: '',
        zosAgiMode: '',
        zosAgiIndividualPrice: '',
        zosAgiFullReportPrice: '',
        geminiMode: '',
        geminiIndividualPrice: '',
        geminiFullReportPrice: ''
    })
    const [isUpdate, setIsUpdate] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setPaymentData({ ...paymentData, [e.target.name]: e.target.value })
    }

    const getPriceDetail = async () => {
        try {
            const response = await api.get("/reports/report-price-detail");
            if (response.data.success) {
                setPaymentData(response.data.paymentDetail[0])
                setIsUpdate(true)
                setLoading(false)
                return;
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getPriceDetail();
    }, [])

    const formSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        if (!isUpdate) {
            if (paymentData.chatGptMode && paymentData.chatGptIndividualPrice && paymentData.chatGptFullReportPrice
                && paymentData.zosAgiMode && paymentData.zosAgiIndividualPrice && paymentData.zosAgiFullReportPrice
                && paymentData.geminiMode && paymentData.geminiIndividualPrice && paymentData.geminiFullReportPrice) {
                try {
                    const response = await api.post('/admin/add-report-price', { paymentData });
                    if (response.data.success) {
                        toast.success(response.data.message);
                        setPaymentData(response.data.paymentDetail[0])
                        setLoading(false)
                        getPriceDetail()
                        return;
                    }
                }
                catch (error) {
                    toast.error(error.response.data.message)
                }
                finally {
                    setLoading(false);
                }
            }
        }
        else {
            try {
                const response = await api.patch('/admin/update-report-price', { paymentData });
                if (response.data.success) {
                    toast.success(response.data.message);
                    setPaymentData(response.data.paymentDetail)
                    setLoading(false)
                    getPriceDetail()
                    return;
                }
            }
            catch (err) {
                console.log('Error:', err);
            }
            finally {
                setLoading(false);
            }
        }
    }

    return (
        <div className='partner-account-main-div'>
            {loading ? (<Loader loading={true} />)
                :
                (<div className='partner-account-form-div'>
                    <h1 className='partner-account-heading text-center border-bottom'>Set Report Payment</h1>
                    <form onSubmit={formSubmit}>

                        <div>
                            <label htmlFor="" className='partner-label-div'>Type ChatGpt :- </label>
                            <input type="text" className='partner-input-div' name='chatGptMode' value={paymentData?.chatGptMode} onChange={handleChange} placeholder='' disabled />
                        </div>
                        <div>
                            <label htmlFor="" className='partner-label-div'>Set chatGpt Individual Section Price :-</label>
                            <input type="text" className='partner-input-div' name='chatGptIndividualPrice' value={paymentData?.chatGptIndividualPrice} onChange={handleChange} placeholder='Enter chatGpt Individual Section Price' />
                        </div>
                        <div>
                            <label htmlFor="" className='partner-label-div'>Set chatGpt Full Report Price :-</label>
                            <input type="text" className='partner-input-div' name='chatGptFullReportPrice' value={paymentData?.chatGptFullReportPrice} onChange={handleChange} placeholder='Enter chatGpt full report Price' />
                        </div>

                        <div>
                            <label htmlFor="" className='partner-label-div'>Type zosAGi :- </label>
                            <input type="text" className='partner-input-div' name='zosAgiMode' value={paymentData?.zosAgiMode} onChange={handleChange} placeholder='' disabled />
                        </div>
                        <div>
                            <label htmlFor="" className='partner-label-div'>Set zosAgi Individual Section Price :-</label>
                            <input type="text" className='partner-input-div' name='zosAgiIndividualPrice' value={paymentData?.zosAgiIndividualPrice} onChange={handleChange} placeholder='Enter zosAgi Individual Section Price' />
                        </div>
                        <div>
                            <label htmlFor="" className='partner-label-div'>Set zosAgi Full Report Price :-</label>
                            <input type="text" className='partner-input-div' name='zosAgiFullReportPrice' value={paymentData?.zosAgiFullReportPrice} onChange={handleChange} placeholder='Enter zosAgi full report Price' />
                        </div>

                        <div>
                            <label htmlFor="" className='partner-label-div'>Type Gemini :- </label>
                            <input type="text" className='partner-input-div' name='geminiMode' value={paymentData?.geminiMode} onChange={handleChange} placeholder='' disabled />
                        </div>
                        <div>
                            <label htmlFor="" className='partner-label-div'>Set gemini Individual Section Price :-</label>
                            <input type="text" className='partner-input-div' name='geminiIndividualPrice' value={paymentData?.geminiIndividualPrice} onChange={handleChange} placeholder='Enter gemini Individual Section Price' />
                        </div>
                        <div>
                            <label htmlFor="" className='partner-label-div'>Set gemini Full Report Price :-</label>
                            <input type="text" className='partner-input-div' name='geminiFullReportPrice' value={paymentData?.geminiFullReportPrice} onChange={handleChange} placeholder='Enter gemini full report Price' />
                        </div>

                        <div className='partner-form-submit-div'>
                            {
                                paymentData ? <input type="submit" className='partner-form-submit-button' value="Update" /> :
                                <input type="submit" className='partner-form-submit-button' value="Submit" />
                            } 
                        </div>
                    </form>
                </div>)
            }
        </div>
    )
}

export default AddPayment