import React, { useEffect, useMemo, useState } from 'react'
import api from '../../apiConfig';
import Loader from '../../loader-component/Loader';
import { useNavigate } from 'react-router-dom';
import { usePagination, useTable } from 'react-table';
import './InvestorVerification.css';

const RejectedInvestorVerification = () => {

    const [investorDetail, setInvestorDetail] = useState([]);
    const [loading, setLoading] = useState(false);
    const router = useNavigate();

    const handleInvestorVerification = async () => {
        setLoading(true);
        try {
            const response = await api.get("/admin/rejected-investor-verification-by-admin");
            if (response.data.success) {
                setInvestorDetail(response.data.allInvestor);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleInvestorVerification();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: 'FirstName',
                accessor: 'firstName',
            },
            {
                Header: 'LastName',
                accessor: 'lastName',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Role',
                accessor: 'role',
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        state: { pageIndex },
        pageCount,
        gotoPage,
    } = useTable(
        {
            columns,
            data: investorDetail,
            initialState: { pageSize: 10 },
        },
        usePagination
    );

    return (
        <div className='admin-investor-verification-div'>
            {loading ? (
                <Loader loading={true} />
            ) : (
                <div>
                    <div>
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} onClick={() => router(`/admin-investor-verification-detail-page/${row.original.userId}`)}>
                                            {row.cells.map(cell => (
                                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='btn-container'>
                        <button disabled={pageIndex === 0} onClick={() => gotoPage(0)}>
                            First
                        </button>
                        <button disabled={!canPreviousPage} onClick={previousPage}>
                            Prev
                        </button>
                        <span>
                            {pageIndex + 1} of {pageCount}
                        </span>
                        <button disabled={!canNextPage} onClick={nextPage}>
                            Next
                        </button>
                        <button disabled={pageIndex >= pageCount - 1} onClick={() => gotoPage(pageCount - 1)}>
                            Last
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default RejectedInvestorVerification