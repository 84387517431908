import React, { useContext, useEffect, useState } from 'react';
import "./StartupMainDashboard.css"
import profileImage from "./../../images/startup-dashboard-profile-logo.png"
import Basic from './startup-sub-component/Basic';
import Pitch from './startup-sub-component/Pitch';
import { Nav } from 'react-bootstrap';
import Funding from './startup-sub-component/Funding';
import Team from './startup-sub-component/Team';
import RelationShip from './startup-sub-component/RelationShip';
import Documents from './startup-sub-component/Documents';
import { useNavigate } from 'react-router-dom';
import StartupFullReport from './startup-sub-component/StartupFullReport';
import StartupFullReportSummary from './startup-sub-component/StartupFullReportSummary';
import ReportInputDetail from './ReportInputDetail';
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";
import { AuthContext } from '../../context/Auth.context';

const StartupMainDashboard = () => {

    const router = useNavigate()
    const [toggleMenu, setToggleMenu] = useState(false);
    const [selectedSection, setSelectedSection] = useState('link-1');
    const [toggleSubMenu, setToggleSubMenu] = useState(false);
    const {state} = useContext(AuthContext);

    const handleNavItemClick = (key) => {
        setSelectedSection(key);
        setToggleMenu(true)
    };

    const handleChildSectionSelect = (section) => {
        setSelectedSection(section);
        setToggleSubMenu(false);
    };

    const handleSubNavItemClick = (key) => {
        setSelectedSection(key);
        setToggleSubMenu(!toggleSubMenu);
    }

    const renderReportContent = () => {
        switch (selectedSection) {
            case 'link-1':
                return <Basic />;
            case 'link-2':
                return <Pitch />
            case 'link-3':
                return <Funding />
            case 'link-4':
                return <Team />
            case 'link-5':
                return <Documents />
            case 'link-6':
                return <ReportInputDetail />
            case 'link-7':
                return <StartupFullReportSummary />
            case 'link-8':
            case 'link-10':
            case 'link-11':
            case 'link-12':
            case 'link-13':
            case 'link-14':
            case 'link-15':
            case 'link-16':
            case 'link-17':
            case 'link-18':
            case 'link-19':
            case 'link-20':
            case 'link-21':
            case 'link-22':
                return <StartupFullReport selectedSection={selectedSection} onSectionSelect={handleChildSectionSelect} />
            case 'link-9':
                return <RelationShip />

            default:
                return null;
        }
    };

    return (
        <div className='startup-main-dashboard-body-div'>
            <div className='startup-main-heading-div-form'>
                <div className='back-button-css-temp'>
                    <h3 className='ps-5' onClick={() => router(-1)}>&larr;</h3>
                </div>
                <div className='back-button-css-temp-mobile'>
                    <h3 onClick={() => setToggleMenu(false)}>&larr; </h3>
                </div>
            </div>
            <div className='startup-dashboard-inside-shadow-div'>
                <div className='web-view-startup-dashboard'>
                    <div className='startup-dashboard-profile-form-div'>
                        <div>
                            <img src={profileImage} alt="" />
                        </div>
                        <div>
                            <h4 className='mb-0 pt-3'>{state?.user?.FirstName ? (<span>{state?.user.FirstName}</span>) : <span>--</span>}</h4>
                            {/* <p className='mb-0'>user since Mar 2017</p> */}
                        </div>
                    </div>
                    <div className='startup-side-nav-component-content-div '>
                        <Nav className='startup-side-nav' variant="pills" defaultActiveKey="link-1">
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link className='startup-navbar-color py-2' onClick={() => handleNavItemClick('link-1')} eventKey="link-1">Basic</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link className='startup-navbar-color py-2' onClick={() => handleNavItemClick('link-2')} eventKey="link-2">Pitch</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link className='startup-navbar-color py-2' onClick={() => handleNavItemClick('link-3')} eventKey="link-3">Funding</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link className='startup-navbar-color py-2' onClick={() => handleNavItemClick('link-4')} eventKey="link-4">Teams</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link className='startup-navbar-color py-2' onClick={() => handleNavItemClick('link-5')} eventKey="link-5">Documents</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link className='startup-navbar-color py-2' onClick={() => handleNavItemClick('link-6')} eventKey="link-6">Startup Research Data</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link className='startup-navbar-color py-2' onClick={() => handleNavItemClick('link-7')} eventKey="link-7">Summary Report </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link
                                    className={`startup-navbar-color py-2 d-flex align-items-center justify-content-between ${selectedSection === 'link-8' ? 'active' : ''}`}
                                    onClick={() => handleSubNavItemClick('link-8')}
                                    eventKey="link-8"
                                >
                                    Full Report {toggleSubMenu ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                                </Nav.Link>
                            </Nav.Item>
                            {toggleSubMenu &&
                                <>
                                    <Nav.Item className="basic-startup-form-div">
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-10' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-10')}
                                            eventKey="link-10"
                                        >
                                            Branding
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="basic-startup-form-div">
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-11' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-11')}
                                            eventKey="link-11"
                                        >
                                            Industry Insight
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="basic-startup-form-div">
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-12' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-12')}
                                            eventKey="link-12"
                                        >
                                            Market Research
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-13' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-13')}
                                            eventKey="link-13"
                                        >
                                            Swot Analysis
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-14' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-14')}
                                            eventKey="link-14"
                                        >
                                            Pestle Analysis
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-15' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-15')}
                                            eventKey="link-15"
                                        >
                                            Product Requirement
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-16' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-16')}
                                            eventKey="link-16"
                                        >
                                            Path to MVP
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-17' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-17')}
                                            eventKey="link-17"
                                        >
                                            Business OnePager
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-18' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-18')}
                                            eventKey="link-18"
                                        >
                                            Marketing Plan
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-19' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-19')}
                                            eventKey="link-19"
                                        >
                                            Go-To-Strategy
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-20' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-20')}
                                            eventKey="link-20"
                                        >
                                            First Additional Query
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-21' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-21')}
                                            eventKey="link-21"
                                        >
                                            Second Additional Query
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-22' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-22')}
                                            eventKey="link-22"
                                        >
                                            Third Additional Query
                                        </Nav.Link>
                                    </Nav.Item>
                                </>
                            }
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link className='startup-navbar-color py-2' onClick={() => handleNavItemClick('link-9')} eventKey="link-9">Relationship Manager</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <div>
                            {renderReportContent()}
                        </div>
                    </div>
                </div>

                <div className='mobile-view-startup-dashboard'>
                    <div className='startup-dashboard-profile-form-div'>
                        <div>
                            <img src={profileImage} alt="" />
                        </div>
                        <div>
                            {/* <h4 className='mb-0 pt-3 '>Nikhil</h4> */}
                            <h4 className='mb-0 pt-3'>{state?.user?.FirstName ? (<span>{state?.user.FirstName}</span>) : <span>--</span>}</h4>
                            {/* <p className='mb-0 '>user since Mar 2017</p> */}
                        </div>
                    </div>
                    <div className='startup-side-nav-component-content-div'>
                        {!toggleMenu && <Nav className='startup-side-nav' variant="pills" defaultActiveKey="link-1">
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link className='startup-navbar-color py-2' onClick={() => handleNavItemClick('link-1')} eventKey="link-1">Basic</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link className='startup-navbar-color py-2' onClick={() => handleNavItemClick('link-2')} eventKey="link-2">Pitch</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link className='startup-navbar-color py-2' onClick={() => handleNavItemClick('link-3')} eventKey="link-3">Funding</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link className='startup-navbar-color py-2' onClick={() => handleNavItemClick('link-4')} eventKey="link-4">Teams</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link className='startup-navbar-color py-2' onClick={() => handleNavItemClick('link-5')} eventKey="link-5">Documents</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link className='startup-navbar-color py-2' onClick={() => handleNavItemClick('link-6')} eventKey="link-6">Market Research Data</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link className='startup-navbar-color py-2' onClick={() => handleNavItemClick('link-7')} eventKey="link-7">Summary Input</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link
                                    className={`startup-navbar-color py-2 d-flex align-items-center justify-content-between ${selectedSection === 'link-8' ? 'active' : ''}`}
                                    onClick={() => handleSubNavItemClick('link-8')}
                                    eventKey="link-8"
                                >
                                    Full Report {toggleSubMenu ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                                </Nav.Link>
                            </Nav.Item>
                            {toggleSubMenu &&
                                <>
                                    <Nav.Item className="basic-startup-form-div">
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-10' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-10')}
                                            eventKey="link-10"
                                        >
                                            Branding
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="basic-startup-form-div">
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-11' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-11')}
                                            eventKey="link-11"
                                        >
                                            Industry Insight
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="basic-startup-form-div">
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-12' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-12')}
                                            eventKey="link-12"
                                        >
                                            Market Research
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="basic-startup-form-div">
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-13' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-13')}
                                            eventKey="link-13"
                                        >
                                            Swot Analysis
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="basic-startup-form-div">
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-14' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-14')}
                                            eventKey="link-14"
                                        >
                                            Pestle Analysis
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="basic-startup-form-div">
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-15' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-15')}
                                            eventKey="link-15"
                                        >
                                            Product Requirement
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="basic-startup-form-div">
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-16' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-16')}
                                            eventKey="link-16"
                                        >
                                            Path to MVP
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="basic-startup-form-div">
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-17' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-17')}
                                            eventKey="link-17"
                                        >
                                            Business OnePager
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="basic-startup-form-div">
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-18' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-18')}
                                            eventKey="link-18"
                                        >
                                            Marketing Plan
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="basic-startup-form-div">
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-19' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-19')}
                                            eventKey="link-19"
                                        >
                                            Go-To-Strategy
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="basic-startup-form-div">
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-20' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-20')}
                                            eventKey="link-20"
                                        >
                                            First Additional Query
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="basic-startup-form-div">
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-21' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-21')}
                                            eventKey="link-21"
                                        >
                                            Second Additional Query
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="basic-startup-form-div">
                                        <Nav.Link
                                            className={`startup-navbar-color py-2 ${selectedSection === 'link-22' ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick('link-22')}
                                            eventKey="link-22"
                                        >
                                            Third Additional Query
                                        </Nav.Link>
                                    </Nav.Item>
                                </>
                            }
                            <Nav.Item className="basic-startup-form-div">
                                <Nav.Link className='startup-navbar-color py-2' onClick={() => handleNavItemClick('link-9')} eventKey="link-9">Relationship Manager</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        }
                        {toggleMenu && <div>
                            {renderReportContent()}
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StartupMainDashboard