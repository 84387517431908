import { Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/header/Header';
import Home from './components/home/Home';
import Footer from './components/footer/Footer';
import StartupPage from './components/startupPage/StartupPage';
import InvestorPage from './components/investorPage/InvestorPage';
import Login from './components/login/Login';
import Signup from './components/signup/Signup';
import StartupMainForm from './components/forms/startupForm/StartupMainForm';
import OverviewProfile from './components/overview-profile/OverviewProfile';
import { ErrorPage } from './components/ErrorPage';
import StartupMainDashboard from './components/startup-account-dashboard/StartupMainDashboard';
import MyPitchDeck from './components/my-pitch-deck/MyPitchDeck';
import FinancialModalling from './components/financial-modalling/FinancialModalling';
import InvestorMainForm from './components/investor-form/investorMainForm/InvestorMainForm';
import InvestorHome from './components/investor-inside/investor-home/InvestorHome';
import InvestorAccount from './components/investor-inside/investor-account/InvestorAccount';
import MyInnovation from './components/my-innovation/MyInnovation';
import FullReport from './components/full-report/FullReport';
import AboutUs from './components/about-us/AboutUs';
import ContactUs from './components/contact-us/ContactUs';
import PortfolioComponent from './components/portfolio-component/PortfolioComponent';
import InvestorOpportunity from './components/investor-inside/investor-opportunity/InvestorOpportunity';
import InterestInvestor from './components/investor-inside/interest-investor/InterestInvestor';
import SingleInterestStartupDetail from './components/investor-inside/interest-startup-detail/SingleInterestStartupDetail';
import AdminHomeDetail from './components/Admin/AdminHomeDetail';
import GetStartupInvestorDetail from './components/Admin/GetStartupInvestorDetail';
import CreatePartnerAccount from './components/Admin/partnerAccount/CreatePartnerAccount';
import InvestorVerification from './components/Admin/investor-verification/InvestorVerification';
import InvestorDocumentDetail from './components/Admin/investor-verification/InvestorDocumentDetail';
import RejectedInvestorVerification from './components/Admin/investor-verification/RejectedInvestorVerification';
import ShowInterestedInvestorToAdmin from './components/Admin/ShowInterestedInvestorToAdmin';
import VerifyOtp from './components/verificationPage/VerifyOtp';
import ForgotPassword from './components/verificationPage/ForgotPassword';
import ResetPassword from './components/verificationPage/ResetPassword';
import AddRole from './components/verificationPage/AddRole';
import SummaryReport from './components/startup-report-summary/SummaryReport';
import AddPayment from './components/Admin/addPayment/AddPayment';
import AddReportCoupon from './components/Admin/addReportCoupon/AddReportCoupon';

function App() {
   
    return (
        <div>
            <Header />
            <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route exact path='/register' element={<Signup />} />
                <Route exact path='/' element={<Home />} />
                <Route exact path='/startup-page' element={<StartupPage />} />
                <Route exact path='/investor-page' element={<InvestorPage />} />
                <Route exact path='/start-up-account' element={<StartupMainForm />} />
                <Route exact path='/overview-profile' element={<OverviewProfile />} />
                <Route exact path='/report-summary' element={<SummaryReport />} />
                <Route exact path='/startup-main-form-dashboard' element={<StartupMainDashboard />} />
                <Route exact path='*' element={<ErrorPage />} />
                <Route exact path='/pitch-deck' element={<MyPitchDeck />} />
                <Route exact path='/investor-form-account' element={<InvestorMainForm />} />
                <Route exact path='/financial-modelling' element={<FinancialModalling />} />
                <Route exact path='/investor/home' element={<InvestorHome />} />
                <Route exact path='/investor/account' element={<InvestorAccount />} />
                <Route exact path='/my-innovation' element={<MyInnovation />} />
                <Route exact path='/full-report/:promptDataId' element={<FullReport />} />
                <Route exact path='/about-us' element={<AboutUs />} />
                <Route exact path='/contact-us' element={<ContactUs />} />
                <Route exact path='/portfolio' element={<PortfolioComponent />} />
                <Route exact path='/investor-opportunity' element={<InvestorOpportunity />} />
                <Route exact path='/startup-interest' element={<InterestInvestor />} />
                <Route exact path='/get-single-interest-startup-detail/:id' element={<SingleInterestStartupDetail />} />
                <Route exact path='/admin-home-detail' element={<AdminHomeDetail />} />
                <Route exact path='/startup-investor-detail/:id' element={<GetStartupInvestorDetail />} />
                <Route exact path='/create-partner-account' element={<CreatePartnerAccount />} />
                <Route exact path='/admin-investor-verification-page' element={<InvestorVerification />} />
                <Route exact path='/admin-investor-verification-detail-page/:id' element={<InvestorDocumentDetail />} />
                <Route exact path='/admin-rejected-investor-verification-page' element={<RejectedInvestorVerification />} />
                <Route exact path='/show-interested-investor-to-admin' element={<ShowInterestedInvestorToAdmin />} />
                <Route exact path='/verify-otp' element={<VerifyOtp />} />
                <Route exact path='/forgot-password' element={<ForgotPassword />} />
                <Route exact path='/reset-password' element={<ResetPassword />} />
                <Route exact path='/add-user-role' element={<AddRole />} />
                <Route exact path='/add-report-payment' element={<AddPayment />} />
                <Route exact path='/add-coupon-code' element={<AddReportCoupon /> } />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
