import React, { useContext, useEffect, useState } from 'react'
import "./FullReport.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { Nav } from 'react-bootstrap'
// import { FullReportDashboardContext } from '../../context/fullReportDashboard.context'
import BrandingReport from './BrandingReport'
import IndustryInsightsReport from './IndustryInsightsReport'
import MarketResearchComponent from './MarketResearchComponent'
import SwotAnalysisComponent from './SwotAnalysisComponent'
import PestelAnalysisComponent from './PestelAnalysisComponent'
import ProductRequirementComponent from './ProductRequirementComponent'
import PathtoMvpComponent from './PathtoMvpComponent'
import BusinessOnepagerComponent from './BusinessOnepagerComponent'
import MarketPlanerComponent from './MarketPlanerComponent'
// import HiringPlanComponent from './HiringPlanComponent'
import GotoStrategyComponent from './GotoStrategyComponent'
import { useParams } from 'react-router-dom'
import api from '../apiConfig'
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import GetFullReport from './GetFullReport'
import SummaryReport from './SummaryReport'
import StartupSummary from './StartupSummary'
import { AuthContext } from '../../context/Auth.context'

const FullReport = () => {

    const [dateTime, setDateTime] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [report, setReport] = useState({});
    // const { setBranding, setIndustryInsight, setMarketResearch, setSwotAnalysis, setPestelAnalysis, setProductRequirement, setPathToMvp, setBusinessOnepager, setMarketPlan, setHiring, setMarketStrategy } = useContext(FullReportDashboardContext)
    const [selectedSection, setSelectedSection] = useState('startupSummary');
    const { promptDataId } = useParams();
    const { state } = useContext(AuthContext);

    const dateInfo = async () => {
        if (promptDataId) {
            try {
                const token = JSON.parse(localStorage.getItem("token"))
                if (token) {
                    const response = await api.post("/buzz-startup-ai/get-buzzstartups-full-report", { token, promptDataId })
                    if (response.data.success) {
                        setDateTime(new Date(response?.data?.getFullReport?.date));
                        setInputValue(response?.data?.getFullReport?.searchedPrompt)
                        setReport(response?.data?.getFullReport)
                    }
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else {
            console.log("Please provide prompt first");
        }
    }

    const testDownload = () => {
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4',
        });
        const fontSize = 12;
        doc.setFontSize(fontSize);
        let y = 15;
        const brandingHeadingFontSize = 16;
        doc.setFontSize(brandingHeadingFontSize);
        const brandingHeadingWidth = doc.getStringUnitWidth("Branding") * brandingHeadingFontSize / doc.internal.scaleFactor;
        const centerX = (doc.internal.pageSize.width - brandingHeadingWidth - 8) / 2;
        doc.setFont("helvetica", "bold");
        doc.text(centerX, y, "Branding");
        y += 10;

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Business Name", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "bold");
        const brandingBusinessTitle = report.brandingBusinessTitle.replace(/\*\*(.*?)\*\*/g, '$1');
        const brandingBusinessTitleLines = doc.splitTextToSize(brandingBusinessTitle, doc.internal.pageSize.width - 30);
        y = addText(doc, brandingBusinessTitleLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Summary", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const brandingBusinessSummary = report.brandingBusinessSummary.replace(/\*\*(.*?)\*\*/g, '$1');
        const brandingBusinessSummaryLines = doc.splitTextToSize(brandingBusinessSummary, doc.internal.pageSize.width - 30);
        y = addText(doc, brandingBusinessSummaryLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Feature", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const brandingBusinessFeatureLines = report.brandingBusinessFeature.replace(/\*\*(.*?)\*\*/g, '$1');
        const brandingBusinessFeatureLinesLines = doc.splitTextToSize(brandingBusinessFeatureLines, doc.internal.pageSize.width - 30);
        y = addText(doc, brandingBusinessFeatureLinesLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Business Name & Domain Name", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const brandingBusinessName = report.brandingBusinessName.replace(/\*\*(.*?)\*\*/g, '$1');
        const brandingBusinessNameLines = doc.splitTextToSize(brandingBusinessName, doc.internal.pageSize.width - 30);
        y = addText(doc, brandingBusinessNameLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Elevator pitch", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const brandingBusinessElevatorPitch = report.brandingBusinessElevatorPitch.replace(/\*\*(.*?)\*\*/g, '$1');
        const brandingBusinessElevatorPitchLines = doc.splitTextToSize(brandingBusinessElevatorPitch, doc.internal.pageSize.width - 30);
        y = addText(doc, brandingBusinessElevatorPitchLines, y);

        //Industry Insights
        doc.addPage();
        y = 15;
        const industryInsightHeadingFontSize = 16;
        doc.setFontSize(industryInsightHeadingFontSize);
        const industryInsightHeadingText = "Industry Insights"; // Text to be displayed
        const industryInsightHeadingWidth = doc.getStringUnitWidth(industryInsightHeadingText) * industryInsightHeadingFontSize / doc.internal.scaleFactor;
        const industryInsightCenterX = (doc.internal.pageSize.width - industryInsightHeadingWidth - 8) / 2;
        doc.setFont("helvetica", "bold");
        doc.text(industryInsightCenterX, y, industryInsightHeadingText);
        y += 10;

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Current Size of the Target Market", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const industryInsightCurrentMarketSize = report.industryInsightCurrentMarketSize.replace(/\*\*(.*?)\*\*/g, '$1');
        const industryInsightCurrentMarketSizeLines = doc.splitTextToSize(industryInsightCurrentMarketSize, doc.internal.pageSize.width - 30);
        y = addText(doc, industryInsightCurrentMarketSizeLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Growth Trajectory", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const industryInsightGrowthTrajectory = report.industryInsightGrowthTrajectory.replace(/\*\*(.*?)\*\*/g, '$1');
        const industryInsightGrowthTrajectoryLines = doc.splitTextToSize(industryInsightGrowthTrajectory, doc.internal.pageSize.width - 30);
        y = addText(doc, industryInsightGrowthTrajectoryLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Trends and Factors Influencing Market Dynamics", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const industryInsightTrendsAndFactors = report.industryInsightTrendsAndFactors.replace(/\*\*(.*?)\*\*/g, '$1');
        const industryInsightTrendsAndFactorsLines = doc.splitTextToSize(industryInsightTrendsAndFactors, doc.internal.pageSize.width - 30);
        y = addText(doc, industryInsightTrendsAndFactorsLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Potential Challenges", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const industryInsightPotentialChallenges = report.industryInsightPotentialChallenges.replace(/\*\*(.*?)\*\*/g, '$1');
        const industryInsightPotentialChallengesLines = doc.splitTextToSize(industryInsightPotentialChallenges, doc.internal.pageSize.width - 30);
        y = addText(doc, industryInsightPotentialChallengesLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Customer Needs and Pain Points", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const industryInsightCustomerNeeds = report.industryInsightCustomerNeeds.replace(/\*\*(.*?)\*\*/g, '$1');
        const industryInsightCustomerNeedsLines = doc.splitTextToSize(industryInsightCustomerNeeds, doc.internal.pageSize.width - 30);
        y = addText(doc, industryInsightCustomerNeedsLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Improved Existing Solutions", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const industryInsightImprovedExistingSolutions = report.industryInsightImprovedExistingSolutions.replace(/\*\*(.*?)\*\*/g, '$1');
        const industryInsightImprovedExistingSolutionsLines = doc.splitTextToSize(industryInsightImprovedExistingSolutions, doc.internal.pageSize.width - 30);
        y = addText(doc, industryInsightImprovedExistingSolutionsLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Potential Barriers to Entry", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const industryInsightPotentialBarriers = report.industryInsightPotentialBarriers.replace(/\*\*(.*?)\*\*/g, '$1');
        const industryInsightPotentialBarriersLines = doc.splitTextToSize(industryInsightPotentialBarriers, doc.internal.pageSize.width - 30);
        y = addText(doc, industryInsightPotentialBarriersLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Strategies to Overcome or Navigate Barriers", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const industryInsightNavigateBarriers = report.industryInsightNavigateBarriers.replace(/\*\*(.*?)\*\*/g, '$1');
        const industryInsightNavigateBarriersLines = doc.splitTextToSize(industryInsightNavigateBarriers, doc.internal.pageSize.width - 30);
        y = addText(doc, industryInsightNavigateBarriersLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Potential Exit Strategies", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const industryInsightExitStrategies = report.industryInsightExitStrategies.replace(/\*\*(.*?)\*\*/g, '$1');
        const industryInsightExitStrategiesLines = doc.splitTextToSize(industryInsightExitStrategies, doc.internal.pageSize.width - 30);
        y = addText(doc, industryInsightExitStrategiesLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Exit Patterns", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const industryInsightExitPatterns = report.industryInsightExitPatterns.replace(/\*\*(.*?)\*\*/g, '$1');
        const industryInsightExitPatternsLines = doc.splitTextToSize(industryInsightExitPatterns, doc.internal.pageSize.width - 30);
        y = addText(doc, industryInsightExitPatternsLines, y);

        //Market Research
        doc.addPage();
        y = 15;
        const marketResearchHeadingFontSize = 16;
        doc.setFontSize(marketResearchHeadingFontSize);
        const marketResearchHeadingText = "Market Research"; // Text to be displayed
        const marketResearchHeadingWidth = doc.getStringUnitWidth(marketResearchHeadingText) * marketResearchHeadingFontSize / doc.internal.scaleFactor;
        const marketResearchCenterX = (doc.internal.pageSize.width - marketResearchHeadingWidth - 8) / 2;
        doc.setFont("helvetica", "bold");
        doc.text(marketResearchCenterX, y, marketResearchHeadingText);
        y += 10;

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Target Audience", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const marketResearchTargetAudience = report.marketResearchTargetAudience.replace(/\*\*(.*?)\*\*/g, '$1');
        const marketResearchTargetAudienceLines = doc.splitTextToSize(marketResearchTargetAudience, doc.internal.pageSize.width - 30);
        y = addText(doc, marketResearchTargetAudienceLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Market Size", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const marketResearchMarketSize = report.marketResearchMarketSize.replace(/\*\*(.*?)\*\*/g, '$1');
        const marketResearchMarketSizeLines = doc.splitTextToSize(marketResearchMarketSize, doc.internal.pageSize.width - 30);
        y = addText(doc, marketResearchMarketSizeLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Customer Needs, Pain Points & Challenges", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const marketResearchCustomerNeeds = report.marketResearchCustomerNeeds.replace(/\*\*(.*?)\*\*/g, '$1');
        const marketResearchCustomerNeedsLines = doc.splitTextToSize(marketResearchCustomerNeeds, doc.internal.pageSize.width - 30);
        y = addText(doc, marketResearchCustomerNeedsLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Competitive Landscape", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const marketResearchCompetitiveLandscape = report.marketResearchCompetitiveLandscape.replace(/\*\*(.*?)\*\*/g, '$1');
        const marketResearchCompetitiveLandscapeLines = doc.splitTextToSize(marketResearchCompetitiveLandscape, doc.internal.pageSize.width - 30);
        y = addText(doc, marketResearchCompetitiveLandscapeLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Unique Selling Proposition (USP)", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const marketResearchUSP = report.marketResearchUSP.replace(/\*\*(.*?)\*\*/g, '$1');
        const marketResearchUSPLines = doc.splitTextToSize(marketResearchUSP, doc.internal.pageSize.width - 30);
        y = addText(doc, marketResearchUSPLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Consumer Behavior", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const marketResearchConsumerBehavior = report.marketResearchConsumerBehavior.replace(/\*\*(.*?)\*\*/g, '$1');
        const marketResearchConsumerBehaviorLines = doc.splitTextToSize(marketResearchConsumerBehavior, doc.internal.pageSize.width - 30);
        y = addText(doc, marketResearchConsumerBehaviorLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Market Positioning", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const marketResearchMarketPositioning = report.marketResearchMarketPositioning.replace(/\*\*(.*?)\*\*/g, '$1');
        const marketResearchMarketPositioningLines = doc.splitTextToSize(marketResearchMarketPositioning, doc.internal.pageSize.width - 30);
        y = addText(doc, marketResearchMarketPositioningLines, y);


        //SWOT Analysis
        doc.addPage();
        y = 15;
        const SWOTAnalysisHeadingFontSize = 16;
        doc.setFontSize(SWOTAnalysisHeadingFontSize);
        const SWOTAnalysisHeadingText = "Swot Analysis"; // Text to be displayed
        const SWOTAnalysisHeadingWidth = doc.getStringUnitWidth(SWOTAnalysisHeadingText) * SWOTAnalysisHeadingFontSize / doc.internal.scaleFactor;
        const SWOTAnalysisCenterX = (doc.internal.pageSize.width - SWOTAnalysisHeadingWidth - 8) / 2;
        doc.setFont("helvetica", "bold");
        doc.text(SWOTAnalysisCenterX, y, SWOTAnalysisHeadingText);
        y += 10;

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Strength", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const SWOTAnalysisStrength = report.SWOTAnalysisStrength.replace(/\*\*(.*?)\*\*/g, '$1');
        const SWOTAnalysisStrengthLines = doc.splitTextToSize(SWOTAnalysisStrength, doc.internal.pageSize.width - 30);
        y = addText(doc, SWOTAnalysisStrengthLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Weakness", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const SWOTAnalysisWeakness = report.SWOTAnalysisWeakness.replace(/\*\*(.*?)\*\*/g, '$1');
        const SWOTAnalysisWeaknessLines = doc.splitTextToSize(SWOTAnalysisWeakness, doc.internal.pageSize.width - 30);
        y = addText(doc, SWOTAnalysisWeaknessLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Opportunities", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const SWOTAnalysisOpportunities = report.SWOTAnalysisOpportunities.replace(/\*\*(.*?)\*\*/g, '$1');
        const SWOTAnalysisOpportunitiesLines = doc.splitTextToSize(SWOTAnalysisOpportunities, doc.internal.pageSize.width - 30);
        y = addText(doc, SWOTAnalysisOpportunitiesLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Threat", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const SWOTAnalysisThreat = report.SWOTAnalysisThreat.replace(/\*\*(.*?)\*\*/g, '$1');
        const SWOTAnalysisThreatLines = doc.splitTextToSize(SWOTAnalysisThreat, doc.internal.pageSize.width - 30);
        y = addText(doc, SWOTAnalysisThreatLines, y);

        //Pestle Analysis
        doc.addPage();
        y = 15;
        const pestleAnalysisHeadingFontSize = 16;
        doc.setFontSize(pestleAnalysisHeadingFontSize);
        const pestleAnalysisHeadingText = "Pestle Analysis"; // Text to be displayed
        const pestleAnalysisHeadingWidth = doc.getStringUnitWidth(pestleAnalysisHeadingText) * pestleAnalysisHeadingFontSize / doc.internal.scaleFactor;
        const pestleAnalysisCenterX = (doc.internal.pageSize.width - pestleAnalysisHeadingWidth - 8) / 2;
        doc.setFont("helvetica", "bold");
        doc.text(pestleAnalysisCenterX, y, pestleAnalysisHeadingText);
        y += 10;

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Political Factor", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const pestleAnalysisPoliticalFactor = report.pestleAnalysisPoliticalFactor.replace(/\*\*(.*?)\*\*/g, '$1');
        const pestleAnalysisPoliticalFactorLines = doc.splitTextToSize(pestleAnalysisPoliticalFactor, doc.internal.pageSize.width - 30);
        y = addText(doc, pestleAnalysisPoliticalFactorLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Economic Factor", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const pestleAnalysisEconomicFactor = report.pestleAnalysisEconomicFactor.replace(/\*\*(.*?)\*\*/g, '$1');
        const pestleAnalysisEconomicFactorLines = doc.splitTextToSize(pestleAnalysisEconomicFactor, doc.internal.pageSize.width - 30);
        y = addText(doc, pestleAnalysisEconomicFactorLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Social Factors", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const pestleAnalysisSocialFactors = report.pestleAnalysisSocialFactors.replace(/\*\*(.*?)\*\*/g, '$1');
        const pestleAnalysisSocialFactorsLines = doc.splitTextToSize(pestleAnalysisSocialFactors, doc.internal.pageSize.width - 30);
        y = addText(doc, pestleAnalysisSocialFactorsLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Technological Factors", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const pestleAnalysisTechnologicalFactors = report.pestleAnalysisTechnologicalFactors.replace(/\*\*(.*?)\*\*/g, '$1');
        const pestleAnalysisTechnologicalFactorsLines = doc.splitTextToSize(pestleAnalysisTechnologicalFactors, doc.internal.pageSize.width - 30);
        y = addText(doc, pestleAnalysisTechnologicalFactorsLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Legal Factors", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const pestleAnalysisLegalFactors = report.pestleAnalysisLegalFactors.replace(/\*\*(.*?)\*\*/g, '$1');
        const pestleAnalysisLegalFactorsLines = doc.splitTextToSize(pestleAnalysisLegalFactors, doc.internal.pageSize.width - 30);
        y = addText(doc, pestleAnalysisLegalFactorsLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Environmental Factors", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const pestleAnalysisEnvironmentalFactors = report.pestleAnalysisEnvironmentalFactors.replace(/\*\*(.*?)\*\*/g, '$1');
        const pestleAnalysisEnvironmentalFactorsLines = doc.splitTextToSize(pestleAnalysisEnvironmentalFactors, doc.internal.pageSize.width - 30);
        y = addText(doc, pestleAnalysisEnvironmentalFactorsLines, y);

        //Product Requirement
        doc.addPage();
        y = 15;
        const productRequirementHeadingFontSize = 16;
        doc.setFontSize(productRequirementHeadingFontSize);
        const productRequirementHeadingText = "Product Requirement"; // Text to be displayed
        const productRequirementHeadingWidth = doc.getStringUnitWidth(productRequirementHeadingText) * productRequirementHeadingFontSize / doc.internal.scaleFactor;
        const productRequirementCenterX = (doc.internal.pageSize.width - productRequirementHeadingWidth - 8) / 2;
        doc.setFont("helvetica", "bold");
        doc.text(productRequirementCenterX, y, productRequirementHeadingText);
        y += 10;

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Target Audience", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const productRequirementTargetAudience = report.productRequirementTargetAudience.replace(/\*\*(.*?)\*\*/g, '$1');
        const productRequirementTargetAudienceLines = doc.splitTextToSize(productRequirementTargetAudience, doc.internal.pageSize.width - 30);
        y = addText(doc, productRequirementTargetAudienceLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Features and Functionality", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const productRequirementFeaturesAndFunctionality = report.productRequirementFeaturesAndFunctionality.replace(/\*\*(.*?)\*\*/g, '$1');
        const productRequirementFeaturesAndFunctionalityLines = doc.splitTextToSize(productRequirementFeaturesAndFunctionality, doc.internal.pageSize.width - 30);
        y = addText(doc, productRequirementFeaturesAndFunctionalityLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Technical Requirements", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const productRequirementTechnicalRequirements = report.productRequirementTechnicalRequirements.replace(/\*\*(.*?)\*\*/g, '$1');
        const productRequirementTechnicalRequirementsLines = doc.splitTextToSize(productRequirementTechnicalRequirements, doc.internal.pageSize.width - 30);
        y = addText(doc, productRequirementTechnicalRequirementsLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Product Security", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const productRequirementProductSecurity = report.productRequirementProductSecurity.replace(/\*\*(.*?)\*\*/g, '$1');
        const productRequirementProductSecurityLines = doc.splitTextToSize(productRequirementProductSecurity, doc.internal.pageSize.width - 30);
        y = addText(doc, productRequirementProductSecurityLines, y);

        //Path to MVP
        doc.addPage();
        y = 15;
        const pathToMVPHeadingFontSize = 16;
        doc.setFontSize(pathToMVPHeadingFontSize);
        const pathToMVPHeadingText = "Path to MVP"; // Text to be displayed
        const pathToMVPHeadingWidth = doc.getStringUnitWidth(pathToMVPHeadingText) * pathToMVPHeadingFontSize / doc.internal.scaleFactor;
        const pathToMVPCenterX = (doc.internal.pageSize.width - pathToMVPHeadingWidth - 8) / 2;
        doc.setFont("helvetica", "bold");
        doc.text(pathToMVPCenterX, y, pathToMVPHeadingText);
        y += 10;

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Technology Stack", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const pathToMVPTechnologyStack = report.pathToMVPTechnologyStack.replace(/\*\*(.*?)\*\*/g, '$1');
        const pathToMVPTechnologyStackLines = doc.splitTextToSize(pathToMVPTechnologyStack, doc.internal.pageSize.width - 30);
        y = addText(doc, pathToMVPTechnologyStackLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Development Timeline", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const pathToMVPDevelopmentTimeline = report.pathToMVPDevelopmentTimeline.replace(/\*\*(.*?)\*\*/g, '$1');
        const pathToMVPDevelopmentTimelineLines = doc.splitTextToSize(pathToMVPDevelopmentTimeline, doc.internal.pageSize.width - 30);
        y = addText(doc, pathToMVPDevelopmentTimelineLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "User Onboarding", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const pathToMVPUserOnboarding = report.pathToMVPUserOnboarding.replace(/\*\*(.*?)\*\*/g, '$1');
        const pathToMVPUserOnboardingLines = doc.splitTextToSize(pathToMVPUserOnboarding, doc.internal.pageSize.width - 30);
        y = addText(doc, pathToMVPUserOnboardingLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Market Validation", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const pathToMVPMarketValidation = report.pathToMVPMarketValidation.replace(/\*\*(.*?)\*\*/g, '$1');
        const pathToMVPMarketValidationLines = doc.splitTextToSize(pathToMVPMarketValidation, doc.internal.pageSize.width - 30);
        y = addText(doc, pathToMVPMarketValidationLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Post-Launch Evaluation", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const pathToMVPPostLaunchEvaluation = report.pathToMVPPostLaunchEvaluation.replace(/\*\*(.*?)\*\*/g, '$1');
        const pathToMVPPostLaunchEvaluationLines = doc.splitTextToSize(pathToMVPPostLaunchEvaluation, doc.internal.pageSize.width - 30);
        y = addText(doc, pathToMVPPostLaunchEvaluationLines, y);

        //Business OnePager
        doc.addPage();
        y = 15;
        const businessOnePagerHeadingFontSize = 16;
        doc.setFontSize(businessOnePagerHeadingFontSize);
        const businessOnePagerHeadingText = "Business Onepager"; // Text to be displayed
        const businessOnePagerHeadingWidth = doc.getStringUnitWidth(businessOnePagerHeadingText) * businessOnePagerHeadingFontSize / doc.internal.scaleFactor;
        const businessOnePagerCenterX = (doc.internal.pageSize.width - businessOnePagerHeadingWidth - 8) / 2;
        doc.setFont("helvetica", "bold");
        doc.text(businessOnePagerCenterX, y, businessOnePagerHeadingText);
        y += 10;

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Business Name", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const businessOnePagerBusinessName = report.brandingBusinessTitle.replace(/\*\*(.*?)\*\*/g, '$1');
        const businessOnePagerBusinessNameLines = doc.splitTextToSize(businessOnePagerBusinessName, doc.internal.pageSize.width - 30);
        y = addText(doc, businessOnePagerBusinessNameLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Tagline or Elevator Pitch", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const businessOnePagerElevatorPitch = report.businessOnePagerElevatorPitch.replace(/\*\*(.*?)\*\*/g, '$1');
        const businessOnePagerElevatorPitchLines = doc.splitTextToSize(businessOnePagerElevatorPitch, doc.internal.pageSize.width - 30);
        y = addText(doc, businessOnePagerElevatorPitchLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Problem Statement", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const businessOnePagerProblemStatement = report.businessOnePagerProblemStatement.replace(/\*\*(.*?)\*\*/g, '$1');
        const businessOnePagerProblemStatementLines = doc.splitTextToSize(businessOnePagerProblemStatement, doc.internal.pageSize.width - 30);
        y = addText(doc, businessOnePagerProblemStatementLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Solution Overview", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const businessOnePagerSolutionOverview = report.businessOnePagerSolutionOverview.replace(/\*\*(.*?)\*\*/g, '$1');
        const businessOnePagerSolutionOverviewLines = doc.splitTextToSize(businessOnePagerSolutionOverview, doc.internal.pageSize.width - 30);
        y = addText(doc, businessOnePagerSolutionOverviewLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Target Audience", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const businessOnePagerTargetAudience = report.businessOnePagerTargetAudience.replace(/\*\*(.*?)\*\*/g, '$1');
        const businessOnePagerTargetAudienceLines = doc.splitTextToSize(businessOnePagerTargetAudience, doc.internal.pageSize.width - 30);
        y = addText(doc, businessOnePagerTargetAudienceLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Revenue Model", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const businessOnePagerRevenueModel = report.businessOnePagerRevenueModel.replace(/\*\*(.*?)\*\*/g, '$1');
        const businessOnePagerRevenueModelLines = doc.splitTextToSize(businessOnePagerRevenueModel, doc.internal.pageSize.width - 30);
        y = addText(doc, businessOnePagerRevenueModelLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Market Opportunity", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const businessOnePagerMarketOpportunity = report.businessOnePagerMarketOpportunity.replace(/\*\*(.*?)\*\*/g, '$1');
        const businessOnePagerMarketOpportunityLines = doc.splitTextToSize(businessOnePagerMarketOpportunity, doc.internal.pageSize.width - 30);
        y = addText(doc, businessOnePagerMarketOpportunityLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Marketing and Sales Strategy", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const businessOnePagerSalesStrategy = report.businessOnePagerSalesStrategy.replace(/\*\*(.*?)\*\*/g, '$1');
        const businessOnePagerSalesStrategyLines = doc.splitTextToSize(businessOnePagerSalesStrategy, doc.internal.pageSize.width - 30);
        y = addText(doc, businessOnePagerSalesStrategyLines, y);


        //Market Plan
        doc.addPage();
        y = 15;
        const marketPlanHeadingFontSize = 16;
        doc.setFontSize(marketPlanHeadingFontSize);
        const marketPlanHeadingText = "Marketing Plan"; // Text to be displayed
        const marketPlanHeadingWidth = doc.getStringUnitWidth(marketPlanHeadingText) * marketPlanHeadingFontSize / doc.internal.scaleFactor;
        const marketPlanCenterX = (doc.internal.pageSize.width - marketPlanHeadingWidth - 8) / 2;
        doc.setFont("helvetica", "bold");
        doc.text(marketPlanCenterX, y, marketPlanHeadingText);
        y += 10;

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Business and Product Overview", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const marketPlanProductOverview = report.marketPlanProductOverview.replace(/\*\*(.*?)\*\*/g, '$1');
        const marketPlanProductOverviewLines = doc.splitTextToSize(marketPlanProductOverview, doc.internal.pageSize.width - 30);
        y = addText(doc, marketPlanProductOverviewLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Target Audience", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const marketPlanTargetAudience = report.marketPlanTargetAudience.replace(/\*\*(.*?)\*\*/g, '$1');
        const marketPlanTargetAudienceLines = doc.splitTextToSize(marketPlanTargetAudience, doc.internal.pageSize.width - 30);
        y = addText(doc, marketPlanTargetAudienceLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Market Analysis", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const marketPlanMarketAnalysis = report.marketPlanMarketAnalysis.replace(/\*\*(.*?)\*\*/g, '$1');
        const marketPlanMarketAnalysisLines = doc.splitTextToSize(marketPlanMarketAnalysis, doc.internal.pageSize.width - 30);
        y = addText(doc, marketPlanMarketAnalysisLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Unique Selling Proposition (USP)", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const marketPlanUSP = report.marketPlanUSP.replace(/\*\*(.*?)\*\*/g, '$1');
        const marketPlanUSPLines = doc.splitTextToSize(marketPlanUSP, doc.internal.pageSize.width - 30);
        y = addText(doc, marketPlanUSPLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Positioning Strategy", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const marketPlanPositioningStrategy = report.marketPlanPositioningStrategy.replace(/\*\*(.*?)\*\*/g, '$1');
        const marketPlanPositioningStrategyLines = doc.splitTextToSize(marketPlanPositioningStrategy, doc.internal.pageSize.width - 30);
        y = addText(doc, marketPlanPositioningStrategyLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Digital Marketing Strategy", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const marketPlanDigitalMarketingStrategy = report.marketPlanDigitalMarketingStrategy.replace(/\*\*(.*?)\*\*/g, '$1');
        const marketPlanDigitalMarketingStrategyLines = doc.splitTextToSize(marketPlanDigitalMarketingStrategy, doc.internal.pageSize.width - 30);
        y = addText(doc, marketPlanDigitalMarketingStrategyLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Campaign Evaluation", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const marketPlanCampaignEvaluation = report.marketPlanCampaignEvaluation.replace(/\*\*(.*?)\*\*/g, '$1');
        const marketPlanCampaignEvaluationLines = doc.splitTextToSize(marketPlanCampaignEvaluation, doc.internal.pageSize.width - 30);
        y = addText(doc, marketPlanCampaignEvaluationLines, y);

        //Go to strategy
        doc.addPage();
        y = 15;
        const mgoToMarketStrategyHeadingFontSize = 16;
        doc.setFontSize(mgoToMarketStrategyHeadingFontSize);
        const mgoToMarketStrategyHeadingText = "Go to Strategy";
        const mgoToMarketStrategyHeadingWidth = doc.getStringUnitWidth(mgoToMarketStrategyHeadingText) * mgoToMarketStrategyHeadingFontSize / doc.internal.scaleFactor;
        const mgoToMarketStrategyCenterX = (doc.internal.pageSize.width - mgoToMarketStrategyHeadingWidth - 8) / 2;
        doc.setFont("helvetica", "bold");
        doc.text(mgoToMarketStrategyCenterX, y, mgoToMarketStrategyHeadingText);
        y += 10;

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Target Audience", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const goToMarketStrategyTargetAudience = report.goToMarketStrategyTargetAudience.replace(/\*\*(.*?)\*\*/g, '$1');
        const goToMarketStrategyTargetAudienceLines = doc.splitTextToSize(goToMarketStrategyTargetAudience, doc.internal.pageSize.width - 30);
        y = addText(doc, goToMarketStrategyTargetAudienceLines, y);

        doc.setFontSize(fontSize);
        doc.setFont("helvetica", "bold");
        doc.text(15, y, "Key Components of a Successful Go-to-Market Plan", { align: "left" });
        y += 7;
        doc.setFont("helvetica", "normal");
        const goToMarketStrategyKeyPoints = report.goToMarketStrategyKeyPoints.replace(/\*\*(.*?)\*\*/g, '$1');
        const goToMarketStrategyKeyPointsLines = doc.splitTextToSize(goToMarketStrategyKeyPoints, doc.internal.pageSize.width - 30);
        y = addText(doc, goToMarketStrategyKeyPointsLines, y);

        doc.save('Full_Report.pdf');
    };

    function addText(doc, lines, y) {
        let remainingLines = lines;
        let yPosition = y;
        const lineHeight = 7; // Decreased line height
        const marginTop = 10; // Adjust as needed
        const marginBottom = 10; // Adjust as needed
        const pageHeight = doc.internal.pageSize.height - marginTop - marginBottom;
        const headingSpacing = 5; // Decreased heading spacing
    
        // Loop until all lines are added to the document
        while (remainingLines.length > 0) {
            // Check if there's enough space for heading and content
            if (yPosition + lineHeight > pageHeight) {
                // Add a new page if there's not enough space
                doc.addPage();
                yPosition = marginTop; // Reset yPosition to the top margin
            }
    
            // Print the heading
            const heading = remainingLines.shift();
            doc.text(15, yPosition, heading, { align: "left" });
            yPosition += lineHeight + headingSpacing; // Increase yPosition for heading and spacing
    
            // Print the subheading and content until the page is full or no more lines
            while (remainingLines.length > 0 && yPosition + lineHeight <= pageHeight) {
                const line = remainingLines.shift();
                doc.text(15, yPosition, line, { align: "left" });
                yPosition += lineHeight;
            }

            // Check if there are remaining lines, add a new page and continue
            if (remainingLines.length > 0) {
                doc.addPage();
                yPosition = marginTop; // Reset yPosition to the top margin
            }
        }
    
        return yPosition; // Return the final y position
    }

    useEffect(() => {
        dateInfo();
    }, [])

    const handleNavItemClick = (key) => {
        setSelectedSection(key);
    };

    const renderReportContent = () => {
        switch (selectedSection) {
            case 'startupSummary':
                return <StartupSummary promptDataId={promptDataId} />;
            case 'summary':
                return <SummaryReport promptDataId={promptDataId} />
            case 'link-1':
                return <GetFullReport promptDataId={promptDataId} />
            case 'link-2':
                return <BrandingReport promptDataId={promptDataId} />;
            case 'link-3':
                return <IndustryInsightsReport promptDataId={promptDataId} />;
            case 'link-4':
                return <MarketResearchComponent promptDataId={promptDataId} />;
            case 'link-5':
                return <SwotAnalysisComponent promptDataId={promptDataId} />;
            case 'link-6':
                return <PestelAnalysisComponent promptDataId={promptDataId} />;
            case 'link-7':
                return <ProductRequirementComponent promptDataId={promptDataId} />;
            case 'link-8':
                return <PathtoMvpComponent promptDataId={promptDataId} />;
            case 'link-9':
                return <BusinessOnepagerComponent promptDataId={promptDataId} />;
            case 'link-10':
                return <MarketPlanerComponent promptDataId={promptDataId} />;
            // case 'link-10':
            //     return <HiringPlanComponent promptDataId={promptDataId} />;
            case 'link-11':
                return <GotoStrategyComponent promptDataId={promptDataId} />
            default:
                return null;
        }
    };

    return (
        <div className='full-report-body'>
            <div className='startup-report-heading-div'>
                <h2>Startup report {state?.user?.paymentStatus && <span onClick={testDownload}><FontAwesomeIcon className='mx-3' icon={faFilePdf} /></span>}</h2>
                {dateTime !== null && (
                    <p>
                        Generated: {dateTime.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}
                    </p>
                )}

                <input type="text" className='search-report-input px-4 py-1' value={inputValue} disabled placeholder='Ideas Searched Input' />
            </div>

            <div className='report-menu-main-div mt-4'>
                <Nav className='report-menu-div' variant="pills" defaultActiveKey="startupSummary">
                    <Nav.Item className="report-menu-sub-div mb-4">
                        <Nav.Link className='report-sub-menu-color common-font-weight py-2' onClick={() => handleNavItemClick('startupSummary')} eventKey="startupSummary">My Startup</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="report-menu-sub-div mb-4">
                        <Nav.Link className='report-sub-menu-color common-font-weight py-2' onClick={() => handleNavItemClick('summary')} eventKey="summary">Summary Report</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="report-menu-sub-div mb-4">
                        <Nav.Link className='report-sub-menu-color common-font-weight py-2' onClick={() => handleNavItemClick('link-1')} eventKey="link-1">Full Report</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="report-menu-sub-div mb-4">
                        <Nav.Link className='report-sub-menu-color common-font-weight py-2' onClick={() => handleNavItemClick('link-2')} eventKey="link-2">Branding</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="report-menu-sub-div mb-4">
                        <Nav.Link className='report-sub-menu-color common-font-weight py-2' onClick={() => handleNavItemClick('link-3')} eventKey="link-3">Industry Insight</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="report-menu-sub-div mb-4">
                        <Nav.Link className='report-sub-menu-color common-font-weight py-2' onClick={() => handleNavItemClick('link-4')} eventKey="link-4">Market Research</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="report-menu-sub-div mb-4">
                        <Nav.Link className='report-sub-menu-color common-font-weight py-2' onClick={() => handleNavItemClick('link-5')} eventKey="link-5">SWOT Analysis</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="report-menu-sub-div mb-4">
                        <Nav.Link className='report-sub-menu-color common-font-weight py-2' onClick={() => handleNavItemClick('link-6')} eventKey="link-6">PESTLE Analysis</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="report-menu-sub-div mb-4">
                        <Nav.Link className='report-sub-menu-color common-font-weight py-2' onClick={() => handleNavItemClick('link-7')} eventKey="link-7">Product Requirement</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="report-menu-sub-div mb-4">
                        <Nav.Link className='report-sub-menu-color common-font-weight py-2' onClick={() => handleNavItemClick('link-8')} eventKey="link-8">Path to MVP</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="report-menu-sub-div mb-4">
                        <Nav.Link className='report-sub-menu-color common-font-weight py-2' onClick={() => handleNavItemClick('link-9')} eventKey="link-9">Business One Pager</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="report-menu-sub-div mb-4">
                        <Nav.Link className='report-sub-menu-color common-font-weight py-2' onClick={() => handleNavItemClick('link-10')} eventKey="link-10">Marketing Plan</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item className="report-menu-sub-div mb-4">
                        <Nav.Link className='report-sub-menu-color common-font-weight py-2' onClick={() => handleNavItemClick('link-10')} eventKey="link-10">Hiring Plan</Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item className="report-menu-sub-div mb-4">
                        <Nav.Link className='report-sub-menu-color common-font-weight py-2' onClick={() => handleNavItemClick('link-11')} eventKey="link-11">Go-To-Market Strategy</Nav.Link>
                    </Nav.Item>
                </Nav>
                <div className='report-content-div'>
                    {renderReportContent()}
                </div>
            </div>
        </div>
    )

}

export default FullReport