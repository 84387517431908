import React, { useContext, useEffect, useState } from 'react'
import { KycFormDashboardContext } from '../../../../context/KycFormDashboard.context'
import toast from 'react-hot-toast';
import api from '../../../apiConfig';
import Loader from '../../../loader-component/Loader';

const PassportComponent = () => {

  const { passport } = useContext(KycFormDashboardContext)
  const [passportFront, setPassportFront] = useState(null);
  const [passportBack, setPassportBack] = useState(null);
  const [selectedFileNameFront, setSelectedFileNameFront] = useState('');
  const [selectedFileNameBack, setSelectedFileNameBack] = useState('');
  const [passportData, setPassportData] = useState({})
  const [loading, setLoading] = useState(false);

  const getPassportDetail = async () => {
    setLoading(true)
    try {
      const response = await api.get("/investors/get-passport-detail")
      if (response.data.success) {
        setPassportData(response.data.passportDetail)
      }
    }
    catch (err) {
      console.log(err);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getPassportDetail()
  }, [])

  const handleOpenImage = (imageUrl) => {
    window.open(imageUrl, '_blank');
  };

  const handlePassportFront = (e) => {
    const file = e.target.files[0];
    setPassportFront(file);
    setSelectedFileNameFront(file ? file.name : '');
  };

  const handlePassportBack = (e) => {
    const file = e.target.files[0];
    setPassportBack(file);
    setSelectedFileNameBack(file ? file.name : '');
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append('passportFront', passportFront);
    formData.append('passportBack', passportBack);
    if (formData) {
      setLoading(true);
      try {
        const response = await api.put("/investors/update-kyc-passport-investor-data", formData)
        if (response.data.success) {
          toast.success(response.data.message)
          setSelectedFileNameFront(null);
          setSelectedFileNameBack(null);
          setSelectedFileNameFront('');
          setSelectedFileNameBack('');
          getPassportDetail()
        }
      }
      catch (error) {
        toast.error(error.response.data.message)
      }
      finally {
        setLoading(false);
      }
    }
    else {
      toast.error("Please fill the detail first...")
    }
  }

  return passport &&
    <div>
      {loading ? (<Loader loading={true} />)
        :
        (
          <form onSubmit={formSubmit}>
            <h5 className='mt-3'>Front Passport</h5>
            <p>Please use an image no larger than 1200px * 600px.</p>
            <div className='file-div'>
              <input type="file" className='input-file' onChange={handlePassportFront} id="passportFrontInput" />
              {selectedFileNameFront ? <label htmlFor="passportFrontInput" className="custom-button">Selected file: {selectedFileNameFront}</label> :
                <label htmlFor="passportFrontInput" className="custom-button">Upload Passport Front</label>}
            </div>
            {
              passportData?.passportFront && (
                <button type="button" className="btn btn-dark mt-2" onClick={() => handleOpenImage(passportData?.passportFront)}>Passport Front Preview</button>
              )
            }

            <h5 className='mt-3'>Back Passport</h5>
            <p>Please use an image no larger than 1200px * 600px.</p>
            <div className='file-div'>
              <input type="file" className='input-file' onChange={handlePassportBack} id="passportBackInput" />
              {selectedFileNameBack ? <label htmlFor="passportBackInput" className="custom-button">Selected file: {selectedFileNameBack}</label> :
                <label htmlFor="passportBackInput" className="custom-button">Upload Passport Back</label>}
            </div>
            {passportData?.passportBack && (<button type="button" className="btn btn-dark mt-2" onClick={() => handleOpenImage(passportData?.passportBack)}>Passport Back Preview</button>)}
            <input type='submit' className='startup-basic-general-save-button text-center py-1 my-3' value="Save" />
          </form>
        )}
    </div>
}

export default PassportComponent
