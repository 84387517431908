import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import api from '../apiConfig';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import './AdminHomeDetail.css'
import Loader from '../loader-component/Loader';

const GetStartupInvestorDetail = () => {
    const { id } = useParams();
    const [userDetail, setUserDetail] = useState(null);
    const [roles, setRoles] = useState()
    const [loading, setLoading] = useState(false);

    const getUserDetails = async () => {
        try {
            const response = await api.post("/admin/get-startup-investor-detail", { userId: id })
            if (response.data.success) {
                setUserDetail(response.data.userData)
                setRoles(response.data.userRole)
            }
        }
        catch (err) {
            toast.error(err.response.data.message)
        }
    }

    const handleOpenPdf = (pdfUrl) => {
        window.open(pdfUrl, '_blank');
    };

    useEffect(() => {
        getUserDetails()
    }, [])

    return (
        <div>
            {loading ? (<Loader loading={true} />)
                :
                <div className='interest-single-startup-main-div d-flex justify-content-center'>
                    {
                        roles === 'Startup' ? <div className='mt-3 fouder-detail-css single-startup-detail-width test-width p-3'>
                            <div>
                                <h2 className='text-center'>Startup Detail</h2>
                            </div>
                            <div className='border-top pt-3'>
                                <h4 className='text-start'>Basic Detail</h4>
                            </div>
                            {
                                userDetail?.startup ? <div>
                                    <div className='startup-general-body'>
                                        <div className='startup-general-startup-name-div'>
                                            <div className='py-2'>Startup Name :</div>
                                            <div className='py-2'>
                                                {userDetail?.startup?.StartupName ? <p>{userDetail?.startup?.StartupName}</p> : <p>--</p>}
                                            </div>
                                        </div>
                                        <div className='startup-general-startup-name-div'>
                                            <div className='py-2'>Tagline/small Description :</div>
                                            <div className='py-2'>
                                                {userDetail?.startup?.startupTagline ? <p>{userDetail?.startup?.startupTagline}</p> : <p>--</p>}
                                            </div>
                                        </div>
                                        <div className='startup-general-startup-name-div'>
                                            <div className='py-2'>Location :</div>
                                            <div className='py-2'>
                                                {userDetail?.startup?.StartupCity ? <p>{userDetail?.startup?.StartupCity}</p> : <p>--</p>}
                                            </div>
                                        </div>
                                        <div className='startup-general-startup-name-div'>
                                            <div className='py-2'>Website :</div>
                                            <div className='py-2'>
                                                {userDetail?.startup?.StartupWebsiteUrl ? <p>{userDetail?.startup?.StartupWebsiteUrl}</p> : <p>--</p>}
                                            </div>
                                        </div>
                                        <div className='startup-general-startup-name-div'>
                                            <div className='py-2'>Sector/Industory :</div>
                                            <div className='py-2'>
                                                {userDetail?.startup?.startupSector ? <p>{userDetail?.startup?.startupSector}</p> : <p>--</p>}
                                            </div>
                                        </div>
                                        <div className='startup-general-startup-name-div'>
                                            <div className='py-2'>Banner Url :</div>
                                            <div className='py-2'>
                                                {userDetail?.startup?.startupBannerUrl ? <p>{userDetail?.startup?.startupBannerUrl}</p> : <p>--</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                    <div className='startup-general-body'>
                                        No data available
                                    </div>
                            }

                            {userDetail?.startup ? <div>
                                <div className='pt-3'>
                                    <h4 className='text-start'>Pitch</h4>
                                </div>
                                <div className='startup-general-body'>
                                    <div className='startup-general-startup-name-div'>
                                        <div className='py-2'>Highlights :</div>
                                        <div className='py-2'>
                                            {userDetail?.startup?.startupHighlights ? <p>{userDetail?.startup?.startupHighlights}</p> : <p>--</p>}
                                        </div>
                                    </div>
                                    <div className='startup-general-startup-name-div'>
                                        <div className='py-2'>Startup Story :</div>
                                        <div className='py-2'>
                                            {userDetail?.startup?.startupStory ? <p>{userDetail?.startup?.startupStory}</p> : <p>--</p>}
                                        </div>
                                    </div>
                                    <div className='startup-general-startup-name-div'>
                                        <div className='py-2'>Problem Statement :</div>
                                        <div className='py-2'>
                                            {userDetail?.startup?.startupProblemStatement ? <p>{userDetail?.startup?.startupProblemStatement}</p> : <p>--</p>}
                                        </div>
                                    </div>
                                    <div className='startup-general-startup-name-div'>
                                        <div className='py-2'>Solution :</div>
                                        <div className='py-2'>
                                            {userDetail?.startup?.startupSolution ? <p>{userDetail?.startup?.startupSolution}</p> : <p>--</p>}
                                        </div>
                                    </div>
                                    <div className='startup-general-startup-name-div'>
                                        <div className='py-2'>Products :</div>
                                        <div className='py-2'>
                                            {userDetail?.startup?.startupProducts ? <p>{userDetail?.startup?.startupProducts}</p> : <p>--</p>}
                                        </div>
                                    </div>
                                    <div className='startup-general-startup-name-div'>
                                        <div className='py-2'>Traction :</div>
                                        <div className='py-2'>
                                            {userDetail?.startup?.startupTract ? <p>{userDetail?.startup?.startupTract}</p> : <p>--</p>}
                                        </div>
                                    </div>
                                </div>
                            </div> :
                                <div className='startup-general-body'>
                                    No data available
                                </div>
                            }
                            {userDetail?.startupFundingDetail ? <div>
                                <div className='pt-3'>
                                    <h4 className='text-start'>Funding</h4>
                                </div>
                                <div className='startup-general-body'>
                                    <div className='startup-general-startup-name-div'>
                                        <div className='py-2'>Currency Type :</div>
                                        <div className='py-2'>
                                            {userDetail?.startupFundingDetail?.currencyType ? <p>{userDetail?.startupFundingDetail?.currencyType}</p> : <p>--</p>}
                                        </div>
                                    </div>
                                    <div className='startup-general-startup-name-div'>
                                        <div className='py-2'>Funding Ask :</div>
                                        <div className='py-2'>
                                            {userDetail?.startupFundingDetail?.fundingAsk ? <p>{userDetail?.startupFundingDetail?.fundingAsk}</p> : <p>--</p>}
                                        </div>
                                    </div>
                                    <div className='startup-general-startup-name-div'>
                                        <div className='py-2'>Valuation :</div>
                                        <div className='py-2'>
                                            {userDetail?.startupFundingDetail?.valuation ? <p>{userDetail?.startupFundingDetail?.valuation}</p> : <p>--</p>}
                                        </div>
                                    </div>
                                    <div className='startup-general-startup-name-div'>
                                        <div className='py-2'>Minimum Ticket Size :</div>
                                        <div className='py-2'>
                                            {userDetail?.startupFundingDetail?.minimumTicketSize ? <p>{userDetail?.startupFundingDetail?.minimumTicketSize}</p> : <p>--</p>}
                                        </div>
                                    </div>
                                    {/* <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Cap Table Entry :</div>
                                                <div className='py-2'>
                                                    {userDetail?.startupFundingDetail?.capTableEntryFounder ? <p>{userDetail?.startupFundingDetail?.capTableEntryFounder}</p> : <p>--</p>}
                                                </div>
                                            </div> */}
                                    <div className='startup-general-startup-name-div'>
                                        <div className='py-2'>Commitments So Far :</div>
                                        <div className='py-2'>
                                            {userDetail?.startupFundingDetail?.commitmentSoFar ? <p>{userDetail?.startupFundingDetail?.commitmentSoFar}</p> : <p>--</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                                : <div className='startup-general-body'>
                                    <h4 className='text-start'>No data available</h4>
                                </div>
                            }
                            <div className='pt-3'>
                                <h4 className='text-start'>Founder</h4>
                            </div>
                            {userDetail?.startupTeamFounderDetail?.founderArray && userDetail.startupTeamFounderDetail.founderArray.length > 0 ? (
                                <div>

                                    {userDetail.startupTeamFounderDetail.founderArray.map(founder => (
                                        <div className='startup-general-body'>
                                            <div className='startup-general-startup-name-div' key={founder.id}>
                                                <div className='py-2'>Founder Name :</div>
                                                <div className='py-2'>
                                                    {founder.name ? <p>{founder.name}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Date of Birth :</div>
                                                <div className='py-2'>
                                                    {founder.dateOfBirth ? <p>{founder.dateOfBirth}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Email Id :</div>
                                                <div className='py-2'>
                                                    {founder.emailId ? <p>{founder.emailId}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Linkdin Url :</div>
                                                <div className='py-2'>
                                                    {founder.linkedinUrl ? <p>{founder.linkedinUrl}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Current City :</div>
                                                <div className='py-2'>
                                                    {founder.currentCity ? <p>{founder.currentCity}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Experience :</div>
                                                <div className='py-2'>
                                                    {founder.experience ? <p>{founder.experience}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Education :</div>
                                                <div className='py-2'>
                                                    {founder.education ? <p>{founder.education}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Phone No :</div>
                                                <div className='py-2'>
                                                    {founder.phoneNo ? <p>{founder.phoneNo}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className='startup-general-body'>
                                    <h6 className='text-center'>No founder data was added.</h6>
                                </div>
                            )}
                            <div className='pt-3'>
                                <h4 className='text-start'>Team Size</h4>
                            </div>
                            {userDetail?.startupTeamSizeDetail?.teamSizeDetails && userDetail.startupTeamSizeDetail.teamSizeDetails.length > 0 ? (
                                <div>
                                    {userDetail.startupTeamSizeDetail.teamSizeDetails.map(team => (
                                        <div className='startup-general-body'>
                                            <div className='startup-general-startup-name-div' key={team.id}>
                                                <div className='py-2'>Team Name :</div>
                                                <div className='py-2'>
                                                    {team.teamName ? <p>{team.teamName}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Team Size :</div>
                                                <div className='py-2'>
                                                    {team.teamSize ? <p>{team.teamSize}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className='startup-general-body'>
                                    <h6 className='text-center'>Team size was not added yet.</h6>
                                </div>
                            )}

                            <div className='pt-3'>
                                <h4 className='text-start'>Team Advisor/Mentor</h4>
                            </div>
                            {userDetail?.startupTeamAdvisorDetail?.advisorDetails && userDetail.startupTeamAdvisorDetail.advisorDetails.length > 0 ? (
                                <div>
                                    {userDetail.startupTeamAdvisorDetail.advisorDetails.map(advisor => (
                                        <div className='startup-general-body'>
                                            <div className='startup-general-startup-name-div' key={advisor.id}>
                                                <div className='py-2'>Advisor Name :</div>
                                                <div className='py-2'>
                                                    {advisor.name ? <p>{advisor.name}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>advisor Size :</div>
                                                <div className='py-2'>
                                                    {advisor.linkedinUrl ? <p>{advisor.linkedinUrl}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className='startup-general-body'>
                                    <h6 className='text-center'>No Advisor was added.</h6>
                                </div>
                            )}

                            <div className='pt-3'>
                                <h4 className='text-start'>Pitch Deck Detail</h4>
                            </div>
                            {userDetail?.startupPitchDeck?.pitchDeckDetails && userDetail.startupPitchDeck?.pitchDeckDetails.length > 0 ? (
                                <div>
                                    {userDetail.startupPitchDeck?.pitchDeckDetails.map(pitchDeck => (
                                        <div className='startup-general-body'>
                                            <div className='startup-general-startup-name-div' key={pitchDeck._id}>
                                                <div className='py-2'>Pitch Name :</div>
                                                <div className='py-2'>
                                                    {pitchDeck.pitchName ? <p>{pitchDeck.pitchName}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Pitch Description :</div>
                                                <div className='py-2'>
                                                    {pitchDeck.pitchDescription ? <p>{pitchDeck.pitchDescription}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div my-2 col-6 mx-auto'>
                                                <button className='btn btn-dark' onClick={() => handleOpenPdf(pitchDeck.fileDoc)}>Pitch Document Download</button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className='startup-general-body'>
                                    <h6 className='text-center'>Pitch Deck Not Added.</h6>
                                </div>
                            )}

                            <div className='pt-3'>
                                <h4 className='text-start'>Projection Detail</h4>
                            </div>
                            {userDetail?.startupProjection?.finalProjectionDetails && userDetail.startupProjection?.finalProjectionDetails.length > 0 ? (
                                <div>
                                    {userDetail.startupProjection?.finalProjectionDetails.map(projection => (
                                        <div className='startup-general-body'>
                                            <div className='startup-general-startup-name-div' key={projection._id}>
                                                <div className='py-2'>Projection Name :</div>
                                                <div className='py-2'>
                                                    {projection.docName ? <p>{projection.docName}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Projection Description :</div>
                                                <div className='py-2'>
                                                    {projection.docDescription ? <p>{projection.docDescription}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div my-2 col-6 mx-auto'>
                                                <button className='btn btn-dark' onClick={() => handleOpenPdf(projection.fileUrl)}>Projection Document Download</button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className='startup-general-body'>
                                    <h6 className='text-center'>Pitch Deck Not Added.</h6>
                                </div>
                            )}

                        </div> :
                            roles === 'Investor' ?
                                <div className='mt-3 fouder-detail-css single-startup-detail-width test-width p-3'>
                                    <div>
                                        <h2 className='text-center'>Investor Detail</h2>
                                    </div>
                                    <div className='border-top pt-3'>
                                        <h4 className='text-start'>General Detail</h4>
                                    </div>
                                    {
                                        userDetail?.investor ? <div>
                                            <div className='startup-general-body'>
                                                <div className='startup-general-startup-name-div'>
                                                    <div className='py-2'>Investor Name :</div>
                                                    <div className='py-2'>
                                                        {userDetail?.investor?.InvestorName ? <p>{userDetail?.investor?.InvestorName}</p> : <p>--</p>}
                                                    </div>
                                                </div>
                                                <div className='startup-general-startup-name-div'>
                                                    <div className='py-2'>Investor Email :</div>
                                                    <div className='py-2'>
                                                        {userDetail?.investor?.InvestorEmail ? <p>{userDetail?.investor?.InvestorEmail}</p> : <p>--</p>}
                                                    </div>
                                                </div>
                                                <div className='startup-general-startup-name-div'>
                                                    <div className='py-2'>Investor Phone No :</div>
                                                    <div className='py-2'>
                                                        {userDetail?.investor?.InvestorNumber ? <p>{userDetail?.investor?.InvestorNumber}</p> : <p>--</p>}
                                                    </div>
                                                </div>
                                                <div className='startup-general-startup-name-div'>
                                                    <div className='py-2'>Investor Country :</div>
                                                    <div className='py-2'>
                                                        {userDetail?.investor?.InvestorCountry ? <p>{userDetail?.investor?.InvestorCountry}</p> : <p>--</p>}
                                                    </div>
                                                </div>
                                                <div className='startup-general-startup-name-div'>
                                                    <div className='py-2'>Investor State :</div>
                                                    <div className='py-2'>
                                                        {userDetail?.investor?.InvestorState ? <p>{userDetail?.investor?.InvestorState}</p> : <p>--</p>}
                                                    </div>
                                                </div>
                                                <div className='startup-general-startup-name-div'>
                                                    <div className='py-2'>Investor City :</div>
                                                    <div className='py-2'>
                                                        {userDetail?.investor?.InvestorCity ? <p>{userDetail?.investor?.InvestorCity}</p> : <p>--</p>}
                                                    </div>
                                                </div>
                                                <div className='startup-general-startup-name-div'>
                                                    <div className='py-2'>Investor Pincode :</div>
                                                    <div className='py-2'>
                                                        {userDetail?.investor?.InvestorPincode ? <p>{userDetail?.investor?.InvestorPincode}</p> : <p>--</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                            <div className='startup-general-body'>
                                                No data available
                                            </div>
                                    }
                                    <div className='pt-3'>
                                        <h4 className='text-start'>Other Detail</h4>
                                    </div>
                                    {
                                        userDetail?.investor ?
                                            <div>
                                                <div className='startup-general-body'>
                                                    <div className='startup-general-startup-name-div'>
                                                        <div className='py-2'>Investor Linkdin :</div>
                                                        <div className='py-2'>
                                                            {userDetail?.investor?.InvestorLinkdinUrl ? <p>{userDetail?.investor?.InvestorLinkdinUrl}</p> : <p>--</p>}
                                                        </div>
                                                    </div>
                                                    <div className='startup-general-startup-name-div'>
                                                        <div className='py-2'>Investor Facebook :</div>
                                                        <div className='py-2'>
                                                            {userDetail?.investor?.InvestorFacebookUrl ? <p>{userDetail?.investor?.InvestorFacebookUrl}</p> : <p>--</p>}
                                                        </div>
                                                    </div>
                                                    <div className='startup-general-startup-name-div'>
                                                        <div className='py-2'>Investor Twitter :</div>
                                                        <div className='py-2'>
                                                            {userDetail?.investor?.InvestorTwitterUrl ? <p>{userDetail?.investor?.InvestorTwitterUrl}</p> : <p>--</p>}
                                                        </div>
                                                    </div>
                                                    <div className='startup-general-startup-name-div'>
                                                        <div className='py-2'>Investor Activities :</div>
                                                        <div className='py-2'>
                                                            {userDetail?.investor?.InvestorAddActvities ? <p>{userDetail?.investor?.InvestorAddActvities}</p> : <p>--</p>}
                                                        </div>
                                                    </div>
                                                    <div className='startup-general-startup-name-div'>
                                                        <div className='py-2'>Investor Education Background :</div>
                                                        <div className='py-2'>
                                                            {userDetail?.investor?.InvestorEducationBackground ? <p>{userDetail?.investor?.InvestorEducationBackground}</p> : <p>--</p>}
                                                        </div>
                                                    </div>
                                                    <div className='startup-general-startup-name-div'>
                                                        <div className='py-2'>Investor Work Experience :</div>
                                                        <div className='py-2'>
                                                            {userDetail?.investor?.InvestorWorkExperience ? <p>{userDetail?.investor?.InvestorWorkExperience}</p> : <p>--</p>}
                                                        </div>
                                                    </div>
                                                    <div className='startup-general-startup-name-div'>
                                                        <div className='py-2'>Investor Association :</div>
                                                        <div className='py-2'>
                                                            {userDetail?.investor?.InvestorAssociation ? <p>{userDetail?.investor?.InvestorAssociation}</p> : <p>--</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className='startup-general-body'>
                                                No data available
                                            </div>
                                    }
                                    <div className='pt-3'>
                                        <h4 className='text-start'>Investor Aadharcard</h4>
                                    </div>
                                    {
                                        userDetail?.investorProfile?.length ?
                                            userDetail?.investorProfile.map((document, index) =>
                                                document.aadharFront || document.aadharBack ? (
                                                    <div className='startup-general-body' key={index}>
                                                        <div className='startup-general-startup-name-div'>
                                                            <div className='py-2'>Investor Aadharcard Front image :</div>
                                                            <div className='py-2'>
                                                                {document.aadharFront ? <img src={document.aadharFront} className='doc-image-width' alt="Aadhar Front" /> : <p>--</p>}
                                                            </div>
                                                        </div>
                                                        <div className='startup-general-startup-name-div '>
                                                            <div className='py-2'>Investor Aadharcard Back image :</div>
                                                            <div className='py-2'>
                                                                {document.aadharBack ? <img src={document.aadharBack} className='doc-image-width' alt="Aadhar Back" /> : <p>--</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null) :
                                            <div className='startup-general-body'>
                                                No data available
                                            </div>
                                    }
                                    <div className='pt-3'>
                                        <h4 className='text-start'>Investor Pancard</h4>
                                    </div>
                                    {
                                        userDetail?.investorProfile?.length ?
                                            userDetail?.investorProfile.map((document, index) => document.panCard ? (
                                                <div className='startup-general-body' key={index}>
                                                    <div className='startup-general-startup-name-div'>
                                                        <div className='py-2'>Investor Pancard image :</div>
                                                        <div className='py-2'>
                                                            {document.panCard ? <img src={document.panCard} className='doc-image-width' alt="" /> : <p>--</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null)
                                            :
                                            <div className='startup-general-body'>
                                                No data available
                                            </div>
                                    }
                                    <div className='pt-3'>
                                        <h4 className='text-start'>Investor Passport</h4>
                                    </div>
                                    {
                                        userDetail?.investorProfile?.length ? userDetail?.investorProfile.map((document, index) => document.passportFront || document.passportBack ?
                                            (
                                                <div className='startup-general-body' key={index}>
                                                    <div className='startup-general-startup-name-div'>
                                                        <div className='py-2'>Investor Passport Front image :</div>
                                                        <div className='py-2'>
                                                            {document.passportFront ? <img src={document.passportFront} className='doc-image-width' alt="" /> : <p>--</p>}
                                                        </div>
                                                    </div>
                                                    <div className='startup-general-startup-name-div '>
                                                        <div className='py-2'>Investor Passport Back image :</div>
                                                        <div className='py-2'>
                                                            {document.passportBack ? <img src={document.passportBack} className='doc-image-width' alt="" /> : <p>--</p>}
                                                        </div>
                                                    </div>
                                                </div>) : null)
                                            :
                                            <div className='startup-general-body'>
                                                No data available
                                            </div>
                                    }
                                </div> :
                                roles === 'Partner' ? <div className='mt-3 fouder-detail-css single-startup-detail-width test-width p-3'>
                                    <div className='border-bottom'>
                                        <h2 className='text-center'>Partner Detail</h2>
                                    </div>
                                    {/* <div className='border-top pt-3'>
                                        <h4 className='text-start'> Detail</h4>
                                    </div> */}
                                    {
                                        userDetail && <div>
                                            <div className='startup-general-body'>
                                                <div className='startup-general-startup-name-div'>
                                                    <div className='py-2'>First Name :</div>
                                                    <div className='py-2'>
                                                        {userDetail?.firstName ? <p>{userDetail?.firstName}</p> : <p>--</p>}
                                                    </div>
                                                </div>
                                                <div className='startup-general-startup-name-div'>
                                                    <div className='py-2'>Last Name :</div>
                                                    <div className='py-2'>
                                                        {userDetail?.lastName ? <p>{userDetail?.lastName}</p> : <p>--</p>}
                                                    </div>
                                                </div>
                                                <div className='startup-general-startup-name-div'>
                                                    <div className='py-2'>Email :</div>
                                                    <div className='py-2'>
                                                        {userDetail?.email ? <p>{userDetail?.email}</p> : <p>--</p>}
                                                    </div>
                                                </div>
                                                <div className='startup-general-startup-name-div'>
                                                    <div className='py-2'>Role :</div>
                                                    <div className='py-2'>
                                                        {userDetail?.role ? <p>{userDetail?.role}</p> : <p>--</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div> :
                                    <div className='mt-3 fouder-detail-css single-startup-detail-width test-width p-3'> No Data Available</div>
                    }
                </div>
            }
        </div >
    )
}

export default GetStartupInvestorDetail