import React, { useContext, useEffect, useState } from 'react'
import { Nav } from 'react-bootstrap'
import './StartupReport.css'
import api from '../apiConfig';
import toast from 'react-hot-toast';
import Loader from '../loader-component/Loader';
import { AuthContext } from '../../context/Auth.context';

const ReportInputDetail = () => {

    const [loading, setLoading] = useState(false);
    const { state, dispatch } = useContext(AuthContext);
    const [reportData, setReportData] = useState(
        {
            userStartupName: '',
            userStartupSector: '',
            userStartupTargetAudience: '',
            userStartupProblem: '',
            userStartupSolution: '',
            userStartupCompetitor: '',
            userStartupBusinessModal: '',
            userStartupQueryOne:'',
            userStartupQueryTwo:'',
            userStartupQueryThree:''
        }
    )
    const [isUpdate, setIsUpdate] = useState(false);
    
    const updatedReportData = {
        ...reportData,
        brandingPayment: false,
        industryInsightPayment: false,
        marketResearchPayment: false,
        swotAnalysisPayment: false,
        pestleAnalysisPayment: false,
        productRequirementPayment: false,
        pathToMVPPayment: false,
        businessOnePagerPayment: false,
        marketingPlanPayment: false,
        goToStrategyPayment: false,
        fullReportPayment: false,
    };

    const placeholderText = `1) Open-Source Social Media
2) Security
3) Open Policies
4) Real News with Fake News Detection Algorithm
5) Freedom of Speech
6) Earning Opportunities
7) Diversifying Content using Single Platform with Widget Feature
8) Blockchain Based Decentralized Social Media`;
    const placeholderCompetitorText = `1) Facebook
2) Twitter or X
3) Youtube
4) Twitch
5) Reddit
`;
    const placeholderBusinessModalText = `1) Advertisement(Post, Video, Banners)
2) Commission of Cryptocurrency Transactions
3) Gamification
4) Closed Groups
5) Trollbox
6) Presale of Cryptocurrency`;

    const handleInput = (e) => {
        setReportData({ ...reportData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        getAllReportPromptDetail()
    }, [])

    const getAllReportPromptDetail = async () => {
        if (state?.user?.Email) {
            if (state?.user?.Role == "Startup") {
                setLoading(true);
                try {
                    const response = await api.get("/reports/get-all-report-prompt")
                    if (response.data.success) {
                        setReportData(response.data.reportPromptData)
                        setIsUpdate(true)
                    }
                }
                catch (error) {
                    console.log(error);
                }
                finally {
                    setLoading(false);
                }
            }
        }
    }

    const formSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        if (!isUpdate) {
            if (reportData.userStartupName && reportData.userStartupSector && reportData.userStartupTargetAudience
                && reportData.userStartupProblem && reportData.userStartupSolution && reportData.userStartupCompetitor
                && reportData.userStartupBusinessModal
            ) {
                try {
                    const response = await api.post("/reports/add-report-prompt", { reportData })
                    if (response.data.success) {
                        toast.success(response.data.message)
                        setReportData(response.data.reportPromptData)
                    }
                }
                catch (err) {
                    console.log('Error:', err);
                    toast.error(err.response.data.message)
                }
                finally {
                    setLoading(false);
                }
            }
            else{
                setLoading(false);
                toast.error("All Fields are Mandatory")
            }
        }
        else {
            try {
                const response = await api.put("/reports/update-report-prompt", { reportData: updatedReportData })
                if (response.data.success) {
                    toast.success(response.data.message)
                    setReportData(response.data.reportPromptData)
                }
                else {
                    toast.error(response.data.message)
                }
            }
            catch (err) {
                console.log('Error:', err);
            }
            finally {
                setLoading(false);
            }
        }
    }

    return (
        <div>
            {loading ? (
                <Loader loading={loading} />
            ) :
                (
                    <div>
                        <h3 className='pb-3 border-bottom'>Startup Report Detail</h3>
                        <form className='report-text-area-div' onSubmit={formSubmit}>
                            <div className='report-input-div'>
                                <p className='pt-3 mb-1'>Name of the startup ?<span className='text-danger'> *</span></p>
                                <textarea className='p-1 report-textArea-div' type="text" name="userStartupName" value={reportData?.userStartupName} placeholder='e.g BuzzStartup' onChange={handleInput} />
                            </div>
                            <div className='report-input-div'>
                                <p className='pt-1 mb-1'>Sector/Industry of Startup?<span className='text-danger'> *</span></p>
                                <textarea className='p-1 report-textArea-div' type="text" name="userStartupSector" value={reportData?.userStartupSector} placeholder='Social Media & Cryptocurrency with Blockchain as the base.' onChange={handleInput} />
                            </div>

                            <div className='report-input-div'>
                                <p className='pt-1 mb-1'>Target Audience?<span className='text-danger'> *</span> </p>
                                <textarea className='p-1 report-textArea-div' type="text" name="userStartupTargetAudience" value={reportData?.userStartupTargetAudience} placeholder='All the Internet users across world.' onChange={handleInput} />
                            </div>

                            <div className='report-input-div'>
                                <p className='pt-1 mb-1'>What are the problems the Startup is addressing ?<span className='text-danger'> *</span> </p>
                                <textarea className='p-1 report-textArea-div horizontal-scrollable-web-visible' type="text" name="userStartupProblem" value={reportData?.userStartupProblem} placeholder={`1) Cyber Security\n2) Limited Opportunities for Creators\n3) Wrong Policies for creating Monopoly\n4) Fake News\n5) Freedom of Speech\n6) Privacy\n7) Centralized Storage\n8) Less Earning Opportunities`} onChange={handleInput} />
                            </div>

                            <div className='report-input-div'>
                                <p className='pt-3 mb-1'>What is the Solution the Startup is providing?<span className='text-danger'> *</span> </p>
                                <textarea className='p-1 report-textArea-div horizontal-scrollable-web-visible' type="text" name="userStartupSolution" value={reportData?.userStartupSolution} placeholder={placeholderText} onChange={handleInput} />
                            </div>

                            <div className='report-input-div'>
                                <p className='pt-3 mb-1'>Who are the Competitors?<span className='text-danger'> *</span> </p>
                                <textarea className='p-1 report-textArea-div horizontal-scrollable-web-visible' type="text" name="userStartupCompetitor" value={reportData?.userStartupCompetitor} placeholder={placeholderCompetitorText} onChange={handleInput} />
                            </div>

                            <div className='report-input-div'>
                                <p className='pt-3 mb-1'>What is the Business Model?<span className='text-danger'> *</span> </p>
                                <textarea className='p-1 report-textArea-div horizontal-scrollable-web-visible' type="text" name="userStartupBusinessModal" value={reportData?.userStartupBusinessModal} placeholder={placeholderBusinessModalText} onChange={handleInput} />
                            </div>

                            <div className='report-input-div'>
                                <p className='pt-3 mb-1'>Additional Query 1? </p>
                                <textarea className='p-1 report-textArea-div horizontal-scrollable-web-visible' type="text" name="userStartupQueryOne" value={reportData?.userStartupQueryOne} placeholder="Custom Query 1" onChange={handleInput} />
                            </div>

                            <div className='report-input-div'>
                                <p className='pt-3 mb-1'>Additional Query 2? </p>
                                <textarea className='p-1 report-textArea-div horizontal-scrollable-web-visible' type="text" name="userStartupQueryTwo" value={reportData?.userStartupQueryTwo} placeholder="Custom Query 2" onChange={handleInput} />
                            </div>

                            <div className='report-input-div'>
                                <p className='pt-3 mb-1'>Additional Query 3? </p>
                                <textarea className='p-1 report-textArea-div horizontal-scrollable-web-visible' type="text" name="userStartupQueryThree" value={reportData?.userStartupQueryThree} placeholder="Custom Query 3" onChange={handleInput} />
                            </div>

                            <div>
                                <button type='submit' className='startup-report-generate-button text-center py-1 my-3'>Save</button>
                            </div>
                        </form>
                    </div>
                )
            }
        </div>
    )
}

export default ReportInputDetail