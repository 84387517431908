import React, { useEffect, useMemo, useState } from 'react'
import api from '../apiConfig';
import Loader from '../loader-component/Loader';

const StartupProductRequirementSummary = () => {

    const [productRequirement, setProductRequirement] = useState("")

    const [loading, setLoading] = useState(true);

    const getSummary = async () => {
        try {
            const response = await api.get("/reports/get-report-summary")
            if (response.data.success) {
                const productRequirementSummary = response.data.reportPromptData?.productRequirementSummary;
                if(productRequirementSummary){
                    setLoading(false)
                    setProductRequirement(productRequirementSummary)
                }
                else{
                    setTimeout(getSummary, 1000);
                }  
            }
        }
        catch (err) {
            console.log(err.response.data.message);
        }
    }

    useEffect(() => {
        getSummary();
    }, [])

    const formatContent = useMemo(() => {
        return (text) => {
            if (!text) return '';
            const lines = text.split('\n');
            for (let i = 0; i < lines.length; i++) {
                if (lines[i].startsWith('##')) {
                    lines[i] = '<strong>' + lines[i].substring(3) + '</strong>';
                }
            }
            text = lines.join('\n');
            text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            text = text.replace(/^(.*?):/gm, '<strong>$1</strong>:');
            text = text.replace(/\* (.*?)(\n|$)/g, '<li>$1</li>');
            text = text.replace(/`(.*?)`/g, '<code>$1</code>');
            text = text.replace(/\b(https?:\/\/\S+)\b/g, '<a href="$1">$1</a>');
            text = text.replace(/(?:\r\n?|\n)+/g, '<br>');
            return text;
        };
    }, []);

    return (
        <div>
            {loading ? (
                <Loader loading={loading} />
            ) :
                (
                    <div>
                        <h5 className="my-2 text-center"><strong>Product Requirement</strong></h5>
                        <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(productRequirement) }} />
                    </div>
                )}
        </div>
    )
}

export default StartupProductRequirementSummary