import React, { useEffect, useState } from 'react'
import { Nav } from 'react-bootstrap'
import StartupFullReportGenerate from './full-report-content/StartupFullReportGenerate';
import StartupBrandingGenerate from './full-report-content/StartupBrandingGenerate';
import StartupIndustryInsightGenerate from './full-report-content/StartupIndustryInsightGenerate';
import StartupMarketResearchGenerate from './full-report-content/StartupMarketResearchGenerate';
import StartupSwotAnalysisGenerate from './full-report-content/StartupSwotAnalysisGenerate';
import StartupProductRequirementGenerate from './full-report-content/StartupProductRequirementGenerate';
import StartupPathToMVPGenerate from './full-report-content/StartupPathToMVPGenerate';
import StartupBusinessOnePagerGenerate from './full-report-content/StartupBusinessOnePagerGenerate';
import StartupMarketingPlanGenerate from './full-report-content/StartupMarketingPlanGenerate';
import StartupGoToStrategyGenerate from './full-report-content/StartupGoToStrategyGenerate';
import './StartupFullReport.css'
import StartupPestleAnalysisGenerate from './full-report-content/StartupPestleAnalysisGenerate';
import ReportCustomQueryFirst from './full-report-content/ReportCustomQueryFirst';
import ReportCustomQuerySecond from './full-report-content/ReportCustomQuerySecond';
import ReportCustomQueryThird from './full-report-content/ReportCustomQueryThird';

const StartupFullReport = ({ selectedSection, onSectionSelect }) => {

    const [fullReportSelectedSection, setFullReportSelectedSection] = useState(selectedSection);

    useEffect(() => {
        if (selectedSection) {
            setFullReportSelectedSection(selectedSection);
            renderReportContent();
        }
    }, [selectedSection]);

    const handleNavItemClick = (key) => {
        setFullReportSelectedSection(key);
        onSectionSelect(key)
    };

    const renderReportContent = () => {
        switch (fullReportSelectedSection) {
            case 'link-8':
                return <StartupFullReportGenerate />;
            case 'link-10':
                return <StartupBrandingGenerate />
            case 'link-11':
                return <StartupIndustryInsightGenerate />
            case 'link-12':
                return <StartupMarketResearchGenerate />
            case 'link-13':
                return <StartupSwotAnalysisGenerate />
            case 'link-14':
                return <StartupPestleAnalysisGenerate />
            case 'link-15':
                return <StartupProductRequirementGenerate />
            case 'link-16':
                return <StartupPathToMVPGenerate />
            case 'link-17':
                return <StartupBusinessOnePagerGenerate />
            case 'link-18':
                return <StartupMarketingPlanGenerate />
            case 'link-19':
                return <StartupGoToStrategyGenerate />
            case 'link-20':
                return <ReportCustomQueryFirst />
            case 'link-21':
                return <ReportCustomQuerySecond />
            case 'link-22':
                return <ReportCustomQueryThird />
            default:
                return null;
        }
    };

    return (
        <div>
            <Nav className='border-bottom-navbar-css full-report-scrollbar'>
                <Nav.Item>
                    <Nav.Link
                        className={`background-color-startup-navbar ${fullReportSelectedSection === 'link-8' ? 'active' : ''}`}
                        onClick={() => handleNavItemClick('link-8')}
                        eventKey="link-8"
                    >
                        Full Report
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        className={`background-color-startup-navbar ${fullReportSelectedSection === 'link-10' ? 'active' : ''}`}
                        onClick={() => handleNavItemClick('link-10')}
                        eventKey="link-10"
                    >
                        Branding
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        className={`background-color-startup-navbar ${fullReportSelectedSection === 'link-11' ? 'active' : ''}`}
                        onClick={() => handleNavItemClick('link-11')}
                        eventKey="link-11"
                    >
                        Industry Insight
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item >
                    <Nav.Link
                        className={`background-color-startup-navbar ${fullReportSelectedSection === 'link-12' ? 'active' : ''}`}
                        onClick={() => handleNavItemClick('link-12')}
                        eventKey="link-12"
                    >
                        Market Research
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        className={`background-color-startup-navbar ${fullReportSelectedSection === 'link-13' ? 'active' : ''}`}
                        onClick={() => handleNavItemClick('link-13')}
                        eventKey="link-13"
                    >
                        Swot Analysis
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        className={`background-color-startup-navbar ${fullReportSelectedSection === 'link-14' ? 'active' : ''}`}
                        onClick={() => handleNavItemClick('link-14')}
                        eventKey="link-14"
                    >
                        Pestle Analysis
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        className={`background-color-startup-navbar ${fullReportSelectedSection === 'link-15' ? 'active' : ''}`}
                        onClick={() => handleNavItemClick('link-15')}
                        eventKey="link-15"
                    >
                        Product Requirement
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        className={`background-color-startup-navbar ${fullReportSelectedSection === 'link-16' ? 'active' : ''}`}
                        onClick={() => handleNavItemClick('link-16')}
                        eventKey="link-16"
                    >
                        Path to MVP
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        className={`background-color-startup-navbar ${fullReportSelectedSection === 'link-17' ? 'active' : ''}`}
                        onClick={() => handleNavItemClick('link-17')}
                        eventKey="link-17"
                    >
                        Business OnePager
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        className={`background-color-startup-navbar ${fullReportSelectedSection === 'link-18' ? 'active' : ''}`}
                        onClick={() => handleNavItemClick('link-18')}
                        eventKey="link-18"
                    >
                        Marketing Plan
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        className={`background-color-startup-navbar ${fullReportSelectedSection === 'link-19' ? 'active' : ''}`}
                        onClick={() => handleNavItemClick('link-19')}
                        eventKey="link-19"
                    >
                        Go-To-Strategy
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        className={`background-color-startup-navbar ${fullReportSelectedSection === 'link-20' ? 'active' : ''}`}
                        onClick={() => handleNavItemClick('link-20')}
                        eventKey="link-20"
                    >
                        First Additional Query
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        className={`background-color-startup-navbar ${fullReportSelectedSection === 'link-21' ? 'active' : ''}`}
                        onClick={() => handleNavItemClick('link-21')}
                        eventKey="link-21"
                    >
                        Second Additional Query
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        className={`background-color-startup-navbar ${fullReportSelectedSection === 'link-22' ? 'active' : ''}`}
                        onClick={() => handleNavItemClick('link-22')}
                        eventKey="link-22"
                    >
                        Third Additional Query
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <div>
                {renderReportContent()}
            </div>
        </div>
    )
}

export default StartupFullReport