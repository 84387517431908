import React, { useEffect, useState } from 'react'
import './StartupReportPlanCss.css'
import toast from 'react-hot-toast';
import api from '../../../apiConfig';
import { useNavigate } from 'react-router-dom'
import './ReportPayment.css'


const ReportPayment = () => {

    const checkboxInput = [
        { value: 'branding', label: 'Branding' },
        { value: 'industryInsight', label: 'Industry Insight' },
        { value: 'marketResearch', label: 'Market Research' },
        { value: 'swotAnalysis', label: 'SWOT Analysis' },
        { value: 'pestleAnalysis', label: 'Pestle Analysis' },
        { value: 'productRequirement', label: 'Product Requirement' },
        { value: 'pathToMVP', label: 'Path to MVP' },
        { value: 'businessOnePager', label: 'Business OnePager' },
        { value: 'marketingPlan', label: 'Marketing Plan' },
        { value: 'goToStrategy', label: 'Go-To-Market Strategy' }
    ]

    const fullReportCheckboxInput = [
        { value: 'fullReport', label: 'Report length upto 15k words' },
    ]

    const [paymentData, setPaymentData] = useState(null)
    const [selectedOption, setSelectedOption] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalFullReportAmount, setTotalFullReportAmount] = useState(0);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [selectedFullReportCheckboxes, setSelectedFullReportCheckboxes] = useState([]);
    const [couponCode, setCouponCode] = useState("")
    const [fullReportCouponCode, setFullReportCouponCode] = useState("")
    const router = useNavigate();


    const getPriceDetail = async () => {
        try {
            const response = await api.get("/reports/report-price-detail");
            if (response.data.success) {
                setPaymentData(response.data.paymentDetail[0])
                setSelectedOption(response.data.paymentDetail[0].zosAgiMode);
                return;
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getPriceDetail();
    }, [])

    const handleCheckboxChange = (event, reportSection = false) => {
        const { value, checked } = event.target;
        if (reportSection) {
            if (checked) {
                setSelectedFullReportCheckboxes(prevState => [...prevState, value]);
            } else {
                setSelectedFullReportCheckboxes(prevState => prevState.filter(item => item !== value));
            }
        } else {
            if (checked) {
                setSelectedCheckboxes(prevState => [...prevState, value]);
            } else {
                setSelectedCheckboxes(prevState => prevState.filter(item => item !== value));
            }
        }
    };

    useEffect(() => {
        let amount = 0;
        selectedCheckboxes.forEach(() => {
            amount += individualPrice;
        });
        setTotalAmount(amount);
    }, [selectedCheckboxes, paymentData, selectedOption]);

    useEffect(() => {
        let amount = 0;
        selectedFullReportCheckboxes.forEach(() => {
            amount += fullReportPrice;
        });
        setTotalFullReportAmount(amount);
    }, [selectedFullReportCheckboxes, paymentData, selectedOption]);


    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    }

    const getPrice = () => {
        if (!paymentData) return null;
        switch (selectedOption) {
            case paymentData.chatGptMode:
                return paymentData.chatGptIndividualPrice;
            case paymentData.zosAgiMode:
                return paymentData.zosAgiIndividualPrice;
            default:
                return paymentData.geminiIndividualPrice;
        }
    };

    const getFullReportPrice = () => {
        if (!paymentData) return null;
        switch (selectedOption) {
            case paymentData.chatGptMode:
                return paymentData.chatGptFullReportPrice;
            case paymentData.zosAgiMode:
                return paymentData.zosAgiFullReportPrice;
            default:
                return paymentData.geminiFullReportPrice;
        }
    };

    const fullReportPrice = getFullReportPrice();
    const individualPrice = getPrice();

    const applyCoupon = async () => {
        if ((couponCode || fullReportCouponCode) && ((totalFullReportAmount > 0) || (totalAmount > 0))) {
            try {
                let response = await api.post("/admin/validate-coupon", { couponCode, fullReportCouponCode, totalAmount, totalFullReportAmount });
                if (response.data.success) {
                    setTotalAmount(response.data.totalAmount)
                    setTotalFullReportAmount(response.data.totalFullReportAmount)
                    toast.success(response.data.message)
                }
            }
            catch (err) {
                toast.error(err.response.data.message)
            }
        }
        else {
            toast.error("Your Coupon Code is empty or you have not provided a amount")
        }
    }

    const handleFullReportPayment = async () => {
        const isFullReportChecked = selectedFullReportCheckboxes.includes('fullReport');
        const isIndividualChecked = selectedCheckboxes.length > 0;
        if (isFullReportChecked && isIndividualChecked) {
            toast.error("Please select items from only one section before proceeding with payment.");
        } else if (isFullReportChecked && selectedFullReportCheckboxes.length > 0) {
            const selectedCheckboxes = selectedFullReportCheckboxes
            try {
                const response = await api.post("/reports/add-payment-detail", { totalFullReportAmount, selectedCheckboxes, selectedOption });
                if (response.data.success) {
                    toast.success(response.data.message)
                    router("/overview-profile");
                }
            }
            catch (err) {
                toast.error(err.response.data.message)
            }
        }
        else if (!isFullReportChecked && selectedCheckboxes.length > 0) {
            try {
                const response = await api.post("/reports/add-payment-detail", { totalAmount, selectedCheckboxes, selectedOption });
                if (response.data.success) {
                    toast.success(response.data.message)
                    router("/overview-profile");
                }
            }
            catch (err) {
                toast.error(err.response.data.message)
            }
        }
        else {
            toast.error("Please select at least one item before proceeding with payment.");
        }
    }

    return (
        <div>
            <div className='subscription-page-main-div mt-2 rounded-sm shadow-sm p-3 mb-5'>
                <div className='payment-div-border'>
                    <h3 className='text-center'>Choose Plan</h3>
                    <form>
                        <div>
                            <div className="centered-container">
                                <div>
                                    {paymentData && (
                                        <div key={paymentData._id} className="radio-buttons">
                                            <div className="mx-2">
                                                <input
                                                    type="radio"
                                                    value={paymentData.chatGptMode}
                                                    onChange={handleOptionChange}
                                                    checked={selectedOption === paymentData.chatGptMode}
                                                />
                                                <label className='plan-margin-left-gap'><strong>{paymentData.chatGptMode}</strong></label>
                                            </div>
                                            <div className="mx-2">
                                                <input
                                                    type="radio"
                                                    value={paymentData.zosAgiMode}
                                                    onChange={handleOptionChange}
                                                    checked={selectedOption === paymentData.zosAgiMode}
                                                />
                                                <label className='plan-margin-left-gap'><strong>{paymentData.zosAgiMode}</strong></label>
                                            </div>
                                            <div className="mx-2">
                                                <input
                                                    type="radio"
                                                    value={paymentData.geminiMode}
                                                    onChange={handleOptionChange}
                                                    checked={selectedOption === paymentData.geminiMode}
                                                />
                                                <label className='plan-margin-left-gap'><strong>{paymentData.geminiMode}</strong></label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="containerSummary">
                                <div className="card-payment-div border-1 rounded-lg">
                                    <h2 className='text-center mt-4'><strong>Individual</strong></h2>
                                    <h2 className='text-center mb-2 price-color border-bottom border-dark' ><strong>$  {individualPrice}/section</strong></h2>
                                    {checkboxInput.map(checkbox => (
                                        <div key={checkbox.value} className="mb-2">
                                            <input
                                                type="checkbox"
                                                value={checkbox.value}
                                                checked={selectedCheckboxes.includes(checkbox.value)}
                                                onChange={(e) => handleCheckboxChange(e)}
                                            />
                                            <label className="plan-margin-left-gap"><strong>{checkbox.label}</strong></label>
                                        </div>
                                    ))}
                                    <div className='border-bottom border-dark'></div>
                                    <div className="apply-discount-div">
                                        <label htmlFor="discountCode" className="discount-label"><strong>Apply coupon code here:</strong></label>
                                        <div className="input-apply-wrapper">
                                            <input
                                                type="text"
                                                id="discountCode"
                                                className="discount-input"
                                                placeholder="Enter Coupon code"
                                                onChange={(e) => setCouponCode(e.target.value)}
                                            />
                                            <button type='button' className="apply-btn" onClick={applyCoupon}>Apply</button>
                                        </div>
                                    </div>
                                    <div className='border-bottom border-dark my-2'></div>
                                    <h2 className=' mb-2 price-color' ><strong>Total Amount : {totalAmount}$</strong></h2>
                                    <button type="button" className="btn btn-dark text-light rounded-pill btn-sm mb-2 payment-button-center" onClick={handleFullReportPayment}>Pay Now</button>
                                </div>

                                <div className="card-payment-div border-1 rounded-lg">
                                    <h2 className='text-center mt-4'><strong>Full Report</strong></h2>
                                    <h2 className='text-center mb-2 price-color border-bottom border-dark'><strong>$ {fullReportPrice}/Report</strong></h2>
                                    {
                                        fullReportCheckboxInput.map(checkbox => (
                                            <div key={checkbox.value} className="mb-2">
                                                <input
                                                    type="checkbox"
                                                    value={checkbox.value}
                                                    checked={selectedFullReportCheckboxes.includes(checkbox.value)}
                                                    onChange={e => handleCheckboxChange(e, true)}
                                                />
                                                <label className="plan-margin-left-gap"><strong>{checkbox.label}</strong></label>
                                            </div>
                                        ))
                                    }
                                    <div className='border-bottom border-dark'></div>
                                    <div className="apply-discount-div">
                                        <label htmlFor="discountCode" className="discount-label"><strong>Apply coupon code here:</strong></label>
                                        <div className="input-apply-wrapper">
                                            <input
                                                type="text"
                                                id="discountCode"
                                                className="discount-input"
                                                value={fullReportCouponCode}
                                                onChange={(e) => setFullReportCouponCode(e.target.value)}
                                                placeholder="Enter Coupon code"
                                            />
                                            <button type='button' className="apply-btn" onClick={applyCoupon}>Apply</button>
                                        </div>
                                    </div>
                                    <div className='border-bottom border-dark my-2'></div>
                                    <h2 className=' mb-2 price-color' ><strong>Total Amount : {totalFullReportAmount}$</strong></h2>
                                    <button type="button" className="btn btn-dark text-light rounded-pill btn-sm mb-2 payment-button-center" onClick={handleFullReportPayment}>Pay Now</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ReportPayment