import React, { useEffect, useMemo, useState } from 'react'
import api from '../apiConfig';
import './SummaryReport.css';
import toast from 'react-hot-toast';

const SummaryReport = () => {

    const [branding, setBranding] = useState("");
    const [industryInsight, setIndustryInsight] = useState("")
    const [marketResearch, setMarketResearch] = useState("")
    const [swotAnalysis, setSwotAnalysis] = useState("")
    const [pestleAnalysis, setPestleAnalysis] = useState("")
    const [productRequirement, setProductRequirement] = useState("")
    const [pathToMVP, setPathToMVP] = useState("")
    const [businessOnePager, setBusinessOnePager] = useState("")
    const [marketPlan, setMarketPlan] = useState("")
    const [goToStrategy, setGotoStrategy] = useState("")
    const [subscribePage, setSubscribePage] = useState(false);

    const getSummary = async () => {

        try {
            const response = await api.get("/reports/get-report-summary")
            if (response.data.success) {
                console.log(response.data.reportPromptData);
                setBranding(response.data.reportPromptData?.brandingSummary);
                setIndustryInsight(response.data.reportPromptData?.industryInsightSummary)
                setMarketResearch(response.data.reportPromptData?.marketResearchSummary);
                setSwotAnalysis(response.data.reportPromptData?.SWOTAnalysisSummary);
                setPestleAnalysis(response.data.reportPromptData?.pestleAnalysisSummary);
                setProductRequirement(response.data.reportPromptData?.productRequirementSummary)
                setPathToMVP(response.data.reportPromptData?.pathToMVPSummary);
                setBusinessOnePager(response.data.reportPromptData?.businessOnePagerSummary);
                setMarketPlan(response.data.reportPromptData?.marketingPlanSummary);
                setGotoStrategy(response.data.reportPromptData?.goToStrategySummary);
            }

        }
        catch (err) {
            console.log(err);
        }

    }

    useEffect(() => {
        getSummary();
    }, [])

    const formatContent = useMemo(() => {
        return (text) => {
            if (!text) return '';
            text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            text = text.replace(/^(.*?):/gm, '<strong>$1</strong>:');
            text = text.replace(/\* (.*?)(\n|$)/g, '<li>$1</li>');
            text = text.replace(/`(.*?)`/g, '<code>$1</code>');
            text = text.replace(/\b(https?:\/\/\S+)\b/g, '<a href="$1">$1</a>');
            // text = text.replace(/(?:\r\n|\r|\n)/g, '<br>'); 
            text = text.replace(/(?:\r\n?|\n)+/g, '<br>');
            return text;
        };
    }, []);

    const handlePlatform = async (platformName) => {
        try {
            const response = await api.post("/reports/check-platform", { platformName });
            if (response.data.success) {
                toast.success(response.data.message);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='overview-profile-body'>
            <div className="overview-profile-startup-heading border-0 text-center">
                {subscribePage ? <h3 className=''><strong>Full Report</strong></h3> : <h3 className=''><strong>Summary Report</strong></h3>}
            </div>
            <div className='summary-side-margin'>
                {subscribePage ? (<div className='subscription-page-main-div rounded-sm shadow-sm p-3 mb-5'>
                    {/* <div className='payment-modal-popup-div'>
                        <p className='text-center'><strong>Subscribe to our Monthly Subscription Plans and enjoy ton of benefits</strong></p>
                    </div> */}
                    <div className='d-flex card-width'>
                        <div className="containerSummary">
                            <div className="card-payment-div border-1 rounded-lg">
                                <h2 className='text-center mt-4'><strong>ChatGpt</strong></h2>
                                <p className='text-center'>Ideal for Small Businesses</p>
                                <h2 className='text-center mb-0 price-color'><strong>$ 20.00</strong></h2>
                                <p className='text-center mb-3'>USD / Month</p>
                                <button type="button" class="btn btn-dark text-light rounded-pill btn-sm mb-2" onClick={() => handlePlatform("chatGpt")} >Generate Report</button>
                                <ul>
                                    <li>AI-powered content generation</li>
                                    <li>Text-to-Image feature</li>
                                    <li>Support for up to 10 projects</li>
                                </ul>
                            </div>
                            <div className="card-payment-div border-1 rounded-lg border-dark">
                                <span className='plan-featured bg-dark text-light'>Most Popular</span>
                                <h2 className='text-center mt-4'><strong>Zos Agi</strong></h2>
                                <p className='text-center'>Ideal for Small Businesses</p>
                                <h2 className='text-center mb-0 price-color'><strong>$ 10.00</strong></h2>
                                <p className='text-center mb-3'>USD / Month</p>
                                <button type="button" class="btn btn-dark text-light rounded-pill btn-sm mb-2" onClick={() => handlePlatform("ZosAgi")}>Generate Report</button>
                                <ul>
                                    <li>AI-powered content generation</li>
                                    <li>Text-to-Image feature</li>
                                    <li>Support for up to 10 projects</li>
                                </ul>
                            </div>
                            <div className="card-payment-div border-1 rounded-lg">
                                <h2 className='text-center mt-4'><strong>Gemini</strong></h2>
                                <p className='text-center'>Ideal for Small Businesses</p>
                                <h2 className='text-center mb-0 price-color'><strong>$ 16.00</strong></h2>
                                <p className='text-center mb-3'>USD / Month</p>
                                <button type="button" class="btn btn-dark text-light rounded-pill btn-sm mb-2" onClick={() => handlePlatform("Gemini")}>Generate Report</button>
                                <ul>
                                    <li>AI-powered content generation</li>
                                    <li>Text-to-Image feature</li>
                                    <li>Support for up to 10 projects</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>) :
                    (<div><h5 className="mb-3 text-center"><strong>Branding</strong></h5>
                        <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(branding) }} />
                        <h5 className="mb-1 mt-3 text-center"><strong>Industry Insight</strong></h5>
                        <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(industryInsight) }} />
                        <h5 className="mb-1 mt-3 text-center"><strong>Market Research</strong></h5>
                        <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(marketResearch) }} />
                        <h5 className="mb-3 mt-3 text-center"><strong>SWOT Analysis</strong></h5>
                        <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(swotAnalysis) }} />
                        <h5 className="mb-1 mt-3 text-center"><strong>Pestle Analysis</strong></h5>
                        <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(pestleAnalysis) }} />
                        <h5 className="mb-1 mt-3 text-center"><strong>Product Requirement</strong></h5>
                        <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(productRequirement) }} />
                        <h5 className="mb-3 mt-3 text-center"><strong>Path to MVP</strong></h5>
                        <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(pathToMVP) }} />
                        <h5 className="mb-1 mt-3 text-center"><strong>Business Onepager</strong></h5>
                        <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(businessOnePager) }} />
                        <h5 className="mb-1 mt-3 text-center"><strong>Marketing Plan</strong></h5>
                        <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(marketPlan) }} />
                        <h5 className="mb-1 mt-3 text-center"><strong>Go-to-strategy</strong></h5>
                        <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(goToStrategy) }} />
                        <div className="d-grid gap-2 mb-2 d-md-flex justify-content-md-center">
                            <button className="btn btn-dark me-md-2 " type="button" onClick={() => (setSubscribePage(true))}>Full Report</button>
                        </div>
                    </div>)
                }


            </div>
        </div>
    )
}

export default SummaryReport