import React, { useContext, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import api from '../apiConfig';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/Auth.context';

const AddRole = () => {

    const [userRole, setUserRole] = useState({ role: "Startup" })
    const [loading, setLoading] = useState(false);
    const { state, dispatch } = useContext(AuthContext);
    const router = useNavigate();

    useEffect(() => {
        const getUrlParameter = (name) => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            return urlSearchParams.get(name);
        };
        const token = getUrlParameter('token');
        if (token) {
            localStorage.setItem('token', JSON.stringify(token));
        }
    }, []);

    const handleRole = (event) => {
        setUserRole({ ...userRole, "role": event.target.value })
    }

    const formSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true)
            const token = JSON.parse(localStorage.getItem("token"));
            const response = await api.put("/all/update-user-role", { userRole, token })
            if (response.data.success) {
                toast.success(response.data.message)
                if (response.data?.userDetail?.Role === "Startup") {
                    dispatch({
                        type: "LOGIN",
                        payload: response.data.userDetail
                    })
                    router("/start-up-account")
                }
                if (response.data?.userDetail?.Role === "Investor") {
                    dispatch({
                        type: "LOGIN",
                        payload: response.data.userDetail
                    })
                    router("/investor-form-account")
                }
            }
        }
        catch (err) {
            toast.error(err.response.data.message)
            console.log(err);
        }
    }

    return (
        <div>
            <Card className="text-center verify-otp-div position-absolute start-25">
                <Card.Body>
                    <form onSubmit={formSubmit}>
                        <Card.Title><h2>Buzzstartups</h2></Card.Title>
                        <Card.Text>
                            <div className='verify-input-main-div d-flex justify-content-between mb-3'>
                                <div className='verify-label-div'>
                                    <label className='my-auto'>Select Role :</label>
                                </div>
                                <div className='verify-email-input-div'>
                                    <select className='form-control email-input' name='role' type="text" onChange={handleRole} >
                                        <option value="Startup">Startup</option>
                                        <option value="Investor">Investor</option>
                                    </select>
                                </div>
                            </div>
                        </Card.Text>
                        <Button type='submit' variant="dark">Submit</Button>
                    </form>
                </Card.Body>
            </Card>
        </div>
    )
}

export default AddRole