import React, { useContext, useEffect, useMemo, useState } from "react"
import api from "../apiConfig";
import { AuthContext } from "../../context/Auth.context";
import PaymentModal from "./PaymentModal";

const GotoStrategyComponent = ({ promptDataId }) => {

    const [targetAudience, setTargetAudience] = useState('');
    const [keyComponent, setKeyComponent] = useState('');
    const { state } = useContext(AuthContext);

    const brandingInfo = async () => {
        if (promptDataId) {
            try {
                const token = JSON.parse(localStorage.getItem("token"))
                if (token) {
                    const response = await api.post("/buzz-startup-ai/get-buzzstartups-full-report", { token, promptDataId })
                    if (response.data.success) {
                        setTargetAudience(response?.data?.getFullReport?.goToMarketStrategyTargetAudience);
                        setKeyComponent(response?.data?.getFullReport?.goToMarketStrategyKeyPoints)
                    }
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else {
            console.log("Please provide prompt first");
        }
    }

    useEffect(() => {
        brandingInfo();
    }, [])

    const formatContent = useMemo(() => {
        return (text) => {
            if (!text) return '';
            text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
            text = text.replace(/`(.*?)`/g, '<code>$1</code>');
            text = text.replace(/\b(https?:\/\/\S+)\b/g, '<a href="$1">$1</a>');
            text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
            text = text.replace(/(\d+\.\s[a-zA-Z]+:)/g, '<strong>$1</strong>');
            text = text.replace(/(\d+\.\s.*?:)/g, '<strong>$1</strong>');
            text = text.replace(/# (.*)/g, '<h1 style="font-size: 1.2em;">$1</h1>');
            return text;
        };
    }, []);

    return (
        <div>
            {state?.user?.paymentStatus ? (<div>
                <h2 className="text-center">Go to Strategy</h2>
                <h5 className="mb-1 mt-3"><strong>Target Audience</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(targetAudience) }} />
                <h5 className="mb-1 mt-3"><strong> Key Components of a Successful Go-to-Market Plan</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(keyComponent) }} />
            </div>) :
                (
                    <div>
                        <PaymentModal />
                    </div>
                )}
        </div>
    )

}

export default GotoStrategyComponent