import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import api from '../apiConfig';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {

    const [resetPasswordDetail, setResetPasswordDetail] = useState({ otp: "", email: "", newPassword: "" })
    const router = useNavigate();

    useEffect(() => {
        var email = JSON.parse(localStorage.getItem("userEmail"))
        if (email) {
            setResetPasswordDetail({ ...resetPasswordDetail, "email": email })
        }
    }, []);

    const handleInput = (event) => {
        setResetPasswordDetail({ ...resetPasswordDetail, [event.target.name]: event.target.value })
    }

    const formSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await api.put("/all/reset-password", { resetPasswordDetail })
            if (response.data.success) {
                localStorage.removeItem("userEmail");
                toast.success(response.data.message)
                setResetPasswordDetail({ otp: "", email: "", newPassword: "" })
                router("/login")
            }
        }
        catch (err) {
            toast.error(err.response.data.message)
            console.log(err);
        }
    }

    return (
        <div>
            <Card className="text-center verify-otp-div position-absolute start-25">
                <Card.Body>
                    <form onSubmit={formSubmit}>
                        <Card.Title><h2>Buzzstartups</h2></Card.Title>
                        <Card.Text>
                            <div className='verify-input-main-div d-flex justify-content-between mb-3'>
                                <div className='verify-label-div'>
                                    <label className='my-auto'>Email ID :</label>
                                </div>
                                <div className='verify-email-input-div'>
                                    <input type="email" value={resetPasswordDetail.email} className="form-control email-input" aria-describedby="emailHelp" disabled />
                                </div>
                            </div>
                            <div className='verify-input-main-div d-flex justify-content-between mb-3'>
                                <div className='verify-label-div'>
                                    <label className='my-auto'>Enter OTP :</label>
                                </div>
                                <div className='verify-email-input-div'>
                                    <input type="number" name='otp' className="form-control email-input" onChange={handleInput} aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className='verify-input-main-div d-flex justify-content-between mb-3'>
                                <div className='verify-label-div'>
                                    <label className='my-auto'>New Password :</label>
                                </div>
                                <div className='verify-email-input-div'>
                                    <input type="password" name='newPassword' className="form-control email-input" onChange={handleInput} aria-describedby="emailHelp" />
                                </div>
                            </div>
                        </Card.Text>
                        <Button type='submit' variant="dark">Submit</Button>
                    </form>
                </Card.Body>
            </Card>
        </div>
    )
}

export default ResetPassword