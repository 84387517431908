import React, { useContext, useEffect, useMemo, useState } from "react"
import api from "../apiConfig";
import { AuthContext } from "../../context/Auth.context";
import PaymentModal from "./PaymentModal";

const MarketResearchComponent = ({ promptDataId }) => {

    const [targetAudience, setTargetAudience] = useState('');
    const [marketSize, setMarketSize] = useState('');
    const [customerNeed, setCustomerNeed] = useState('');
    const [competitiveLandscape, setCompetitiveLandscape] = useState('');
    const [usp, setUsp] = useState('');
    const [consumerBehavior, setConsumerBehavior] = useState('');
    const [marketPositioning, setMarketPositioning] = useState('');
    const { state } = useContext(AuthContext);

    const fullReportInfo = async () => {
        if (promptDataId) {
            try {
                const token = JSON.parse(localStorage.getItem("token"))
                if (token) {
                    const response = await api.post("/buzz-startup-ai/get-buzzstartups-full-report", { token, promptDataId })
                    if (response.data.success) {

                        setTargetAudience(response.data.getFullReport?.marketResearchTargetAudience);
                        setMarketSize(response.data.getFullReport?.marketResearchMarketSize);
                        setCustomerNeed(response.data.getFullReport?.marketResearchCustomerNeeds);
                        setCompetitiveLandscape(response.data.getFullReport?.marketResearchCompetitiveLandscape);
                        setUsp(response.data.getFullReport?.marketResearchUSP);
                        setConsumerBehavior(response.data.getFullReport?.marketResearchConsumerBehavior);
                        setMarketPositioning(response.data.getFullReport?.marketResearchMarketPositioning);
                        return;
                    }
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else {
            console.log("Please provide prompt first");
        }
    }

    useEffect(() => {
        fullReportInfo();
    }, [])

    const formatContent = useMemo(() => {
        return (text) => {
            if (!text) return '';
            text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
            text = text.replace(/`(.*?)`/g, '<code>$1</code>');
            text = text.replace(/\b(https?:\/\/\S+)\b/g, '<a href="$1">$1</a>');
            text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
            text = text.replace(/(\d+\.\s[a-zA-Z]+:)/g, '<strong>$1</strong>');
            text = text.replace(/(\d+\.\s.*?:)/g, '<strong>$1</strong>');
            text = text.replace(/# (.*)/g, '<h1 style="font-size: 1.2em;">$1</h1>');
            return text;
        };
    }, []);

    return (
        <div>
            {state?.user?.paymentStatus ? (<div>
                <h2 className="text-center">Market Research</h2>

                <h5 className="mb-0 mt-3"><strong>Target Audience</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(targetAudience) }} />
                <h5 className="mb-1 mt-3"><strong>Market Size</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(marketSize) }} />
                <h5 className="mb-1 mt-3"><strong>Customer Needs, Pain Points & Challenges</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(customerNeed) }} />
                <h5 className="mb-1 mt-3"><strong>Competitive Landscape</strong></h5>
                <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(competitiveLandscape) }} />
                <h5 className="mb-0 mt-3"><strong>Unique Selling Proposition (USP)</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(usp) }} />
                <h5 className="mb-1 mt-3"><strong>Consumer Behavior</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(consumerBehavior) }} />
                <h5 className="mb-1 mt-3"><strong>Market Positioning</strong></h5>
                <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(marketPositioning) }} />
            </div>) :
                (
                    <div>
                        <PaymentModal />
                    </div>
                )}
        </div>
    )
}

export default MarketResearchComponent