import React, { useEffect, useState } from 'react'
import { HashLoader } from 'react-spinners';
import './Loader.css'

const Loader = ({ loading }) => {
//     const [showLoader, setShowLoader] = useState(false);

//   useEffect(() => {
//     const delay = 10000;
//     const timeoutId = setTimeout(() => {
//       setShowLoader(loading);
//     }, delay);
//     return () => clearTimeout(timeoutId);
//   }, [loading]);

    return (
        <div className="loader-container">
            <div><HashLoader color="#000000" loading={loading} size={100} /></div>            
        </div>
    )
}

export default Loader