import React, { useEffect, useMemo, useState } from 'react'
import api from '../apiConfig';

const StartupSummary = ({promptDataId}) => {

    const [branding,setBranding] = useState("");
    const [industryInsight,setIndustryInsight] = useState("")
    const [marketResearch,setMarketResearch] = useState("")
    const [swotAnalysis,setSwotAnalysis] = useState("")
    const [pestleAnalysis,setPestleAnalysis] = useState("")
    const [productRequirement,setProductRequirement] = useState("")
    const [pathToMVP,setPathToMVP] = useState("")
    const [businessOnePager,setBusinessOnePager] = useState("")
    const [marketPlan,setMarketPlan] = useState("")
    const [goToStrategy,setGotoStrategy] = useState("")

    const getSummary = async () => {
        if (promptDataId) {
            try {
                const token = JSON.parse(localStorage.getItem("token"))
                if (token) {
                    const response = await api.post("/buzz-startup-ai/get-buzzstartups-full-report", { token, promptDataId })
                    if (response.data.success) {
                        setBranding(response.data.getFullReport?.startupBrandingSummary);
                        setIndustryInsight(response.data.getFullReport?.startupIndustryInsightSummary)
                        setMarketResearch(response.data.getFullReport?.startupMarketResearchSummary);
                        setSwotAnalysis(response.data.getFullReport?.startupSWOTAnalysisSummary);
                        setPestleAnalysis(response.data.getFullReport?.startupPestleAnalysisSummary);
                        setProductRequirement(response.data.getFullReport?.startupProductRequirementSummary)
                        setPathToMVP(response.data.getFullReport?.startupPathToMVPSummary);
                        setBusinessOnePager(response.data.getFullReport?.startupBusinessOnePagerSummary);
                        setMarketPlan(response.data.getFullReport?.startupMarketingPlanSummary);
                        setGotoStrategy(response.data.getFullReport?.startupGoToStrategySummary);
                    }
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else {
            console.log("Please provide prompt first");
        }
    }

    useEffect(() => {
        getSummary();
    }, [])

    const formatContent = useMemo(() => {
        return (text) => {
            if (!text) return '';
            text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
            text = text.replace(/`(.*?)`/g, '<code>$1</code>');
            text = text.replace(/\b(https?:\/\/\S+)\b/g, '<a href="$1">$1</a>');
            text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
            text = text.replace(/(\d+\.\s[a-zA-Z]+:)/g, '<strong>$1</strong>');
            text = text.replace(/(\d+\.\s.*?:)/g, '<strong>$1</strong>');
            text = text.replace(/# (.*)/g, '<h1 style="font-size: 1.2em;">$1</h1>');
            return text;
        };
    }, []);

  return (
    <div>
        <h2 className="text-center"><strong>My Startup Idea</strong></h2>
            <h5 className="mb-3 mt-3 text-center"><strong>Branding</strong></h5>
            <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(branding) }} />
            <h5 className="mb-1 mt-3 text-center"><strong>Industry Insight</strong></h5>
            <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(industryInsight) }} />
            <h5 className="mb-1 mt-3 text-center"><strong>Market Research</strong></h5>
            <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(marketResearch) }} />
            <h5 className="mb-3 mt-3 text-center"><strong>SWOT Analysis</strong></h5>
            <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(swotAnalysis) }} />
            <h5 className="mb-1 mt-3 text-center"><strong>Pestle Analysis</strong></h5>
            <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(pestleAnalysis) }} />
            <h5 className="mb-1 mt-3 text-center"><strong>Product Requirement</strong></h5>
            <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(productRequirement) }} />
            <h5 className="mb-3 mt-3 text-center"><strong>Path to MVP</strong></h5>
            <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(pathToMVP) }} />
            <h5 className="mb-1 mt-3 text-center"><strong>Business Onepager</strong></h5>
            <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(businessOnePager) }} />
            <h5 className="mb-1 mt-3 text-center"><strong>Marketing Plan</strong></h5>
            <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(marketPlan) }} />
            <h5 className="mb-1 mt-3 text-center"><strong>Go-to-strategy</strong></h5>
            <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(goToStrategy) }} />
    </div>
  )
}

export default StartupSummary