import React, { useContext, useEffect, useMemo, useState } from "react"
import api from "../apiConfig"
import './FullReport.css'
import ReactMarkdown from 'react-markdown';
import { AuthContext } from "../../context/Auth.context";
import PaymentModal from "./PaymentModal";

const BrandingReport = ({ promptDataId }) => {

    const [businessTitle, setBusinessTitle] = useState('');
    const [businessSummary, setBusinessSummary] = useState('');
    const [businessFeature, setBusinessFeature] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [businessElevatorPitch, setBusinessElevatorPitch] = useState('');
    const [brandingFullData,setBrandingFullData] = useState();
    const { state } = useContext(AuthContext);

    const brandingInfo = async () => {
        if (promptDataId) {
            try {
                const token = JSON.parse(localStorage.getItem("token"))
                if (token) {
                    const response = await api.post("/buzz-startup-ai/get-buzzstartups-full-report", { token, promptDataId })
                    if (response.data.success) {
                        setBusinessTitle(response.data.getFullReport?.brandingBusinessTitle);
                        setBusinessSummary(response.data.getFullReport?.brandingBusinessSummary)
                        setBusinessFeature(response.data.getFullReport?.brandingBusinessFeature);
                        setBusinessName(response.data.getFullReport?.brandingBusinessName);
                        setBusinessElevatorPitch(response.data.getFullReport?.brandingBusinessElevatorPitch)
                        setBrandingFullData(response.data.getFullReport?.dummyBrandingDetail)
                    }
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else {
            console.log("Please provide prompt first");
        }
    }

    useEffect(() => {
        brandingInfo();
    }, [])

    const formatContent = useMemo(() => {
        return (text) => {
            if (!text) return '';
            text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
            text = text.replace(/`(.*?)`/g, '<code>$1</code>');
            text = text.replace(/\b(https?:\/\/\S+)\b/g, '<a href="$1">$1</a>');
            text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
            text = text.replace(/(\d+\.\s[a-zA-Z]+:)/g, '<strong>$1</strong>');
            text = text.replace(/(\d+\.\s.*?:)/g, '<strong>$1</strong>');
            text = text.replace(/# (.*)/g, '<h1 style="font-size: 1.2em;">$1</h1>');
            return text;
        };
    }, []);

    return (
        <div>
            {state?.user?.paymentStatus ?
                (<div>
                    <h2 className="text-center">Branding</h2>
                    {/* <h5 className="mb-3 mt-3"><strong>Business Name : {businessTitle}</strong></h5>
                    <h5 className="mb-0 mt-3"><strong>Summary</strong></h5>
                    <p>{businessSummary}</p>
                    <h5 className="mb-1 mt-3"><strong>Feature</strong></h5>
                    <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(businessFeature) }} />
                    <h5 className="mb-1 mt-3"><strong>Business Name & Domain Name</strong></h5>
                    <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(businessName) }} />
                    <h5 className="mb-1 mt-3"><strong>Elevator pitch</strong></h5>
                    <div className="reportFontSize mb-4" dangerouslySetInnerHTML={{ __html: formatContent(businessElevatorPitch) }} /> */}
                    {/* <h5 className="mb-1 mt-3"><strong>Feature</strong></h5> */}
                    <div className="reportFontSize mb-2" dangerouslySetInnerHTML={{ __html: formatContent(brandingFullData) }} />

                    
                    <div>
                        {
                            brandingFullData
                        }
                    </div>
                </div>)
                :
                (<div>
                    <PaymentModal />
                </div>)
            }
        </div>
    );
}

export default BrandingReport