import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './AdminHomeDetail.css'
import api from '../apiConfig'
import { useTable, usePagination } from 'react-table'
import Switch from "react-switch";
import toast from 'react-hot-toast';
import Loader from '../loader-component/Loader';
import { Link, useNavigate } from 'react-router-dom';

const AdminHomeDetail = () => {
    const [userData, setUserData] = useState([]);
    const [selectedRole, setSelectedRole] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('');
    const router = useNavigate();

    const getUserData = async () => {
        setLoading(true);
        try {
            const response = await api.get("/admin/get-all-users");
            if (response.data.success) {
                const filteredUsers = response.data.allUsers.filter(
                    user => user.role === "Investor" || user.role === "Startup" || user.role === "Partner"
                );
                const updatedUsers = filteredUsers.map(user => ({
                    ...user,
                    isBlocked: user.isBlocked || false,
                }));

                setUserData(updatedUsers);
                setSelectedRole(updatedUsers);
                localStorage.setItem('userData', JSON.stringify(updatedUsers));
            }
        } catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    };

    const handleSwitchChange = useCallback(
        async (rowData, checked) => {
            const updatedUserData = selectedRole.map((user) => {
                if (user.userId === rowData.userId) {
                    return {
                        ...user,
                        isBlocked: checked,
                    };
                }
                return user;
            });
            setSelectedRole(updatedUserData);
            try {
                const endpoint = checked ? "/admin/block-user" : "/admin/unblock-user";
                const response = await api.patch(endpoint, { userId: rowData.userId });
                if (response.data.success) {
                    toast.success(response.data.message)
                    await getUserData();
                }
            } catch (error) {
                console.error("Error making API call:", error);
            }
        },
        [selectedRole]
    );

    const columns = useMemo(
        () => [
            {
                Header: 'FirstName',
                accessor: 'firstName',
                Cell: ({ row }) => (
                    <Link to={`/startup-investor-detail/${row.original.userId}`} className="custom-link">
                        {row.original.firstName}
                    </Link>
                ),
            },
            {
                Header: 'LastName',
                accessor: 'lastName',
                Cell: ({ row }) => (
                    <Link to={`/startup-investor-detail/${row.original.userId}`} className="custom-link">
                        {row.original.lastName}
                    </Link>
                ),
            },
            {
                Header: 'Email',
                accessor: 'email',
                Cell: ({ row }) => (
                    <Link to={`/startup-investor-detail/${row.original.userId}`} className="custom-link">
                        {row.original.email}
                    </Link>
                ),
            },
            {
                Header: 'Role',
                accessor: 'role',
                Cell: ({ row }) => (
                    <Link to={`/startup-investor-detail/${row.original.userId}`} className="custom-link">
                        {row.original.role}
                    </Link>
                ),
            },
            {
                Header: 'Date',
                accessor: 'date',
                Cell: ({ row }) => (
                    <span>{row.original.date ? row.original.date : '--'}</span>
                ),
                Cell: ({ row }) => (
                    <Link to={`/startup-investor-detail/${row.original.userId}`} className="custom-link">
                        {row.original.date}
                    </Link>
                ),
            },
            {
                Header: 'Block/Unblock',
                accessor: 'actions',
                Cell: ({ row }) => (
                     <Switch
                        onChange={(checked) => handleSwitchChange(row.original, checked)}
                        checked={row.original.isBlocked}
                        className="react-switch"
                    />
                ),
            },
            // {
            //     Header: 'Block/Unblock',
            //     accessor: 'actions',
            //     Cell: ({ row }) => (
            //         <Switch
            //         onChange={(checked) => handleSwitchChange(row.original, checked)}
            //         checked={row.original.isBlocked}
            //         className="react-switch"
            //     />
            //     ),
            // },
        ],
        [handleSwitchChange]
    );

    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        state: { pageIndex },
        pageCount,
        gotoPage,
    } = useTable({
        columns,
        data: selectedRole,
        initialState: { pageSize: 10 }
    }, usePagination
    )

    useEffect(() => {
        getUserData();
    }, []);

    const handleFilter = (selectedRole) => {
        setSelectedFilter(selectedRole);
        if (selectedRole === '') {
            setSelectedRole(userData);
        } else {
            const filtered = userData.filter(user => user.role === selectedRole);
            setSelectedRole(filtered);
        }
    };

    // const handleFilter = (selectedRole, blocked) => {
    //     setSelectedFilter(selectedRole);
    //     if (selectedRole === '') {
    //         setSelectedRole(userData);
    //     } else {
    //         let filtered = userData.filter(user => user.role === selectedRole);

    //         // Apply block/unblock filter
    //         if (blocked !== null) {
    //             filtered = filtered.filter(user => user.isBlocked === blocked);
    //         }

    //         setSelectedRole(filtered);
    //     }
    // };

    return (
        <div className='admin-home-main-div'>
            {loading ? (<Loader loading={true} />)
                : <div>
                    <div>
                        <div className='d-flex justify-content-center align-items-center mx-auto'>
                            <label className='mr-1'>
                                Select Role :
                            </label>
                            <select value={selectedFilter} className='form-control select-opportunity-width mb-1' onChange={(e) => handleFilter(e.target.value)}>
                                <option value="">All Roles</option>
                                <option value="Investor">Investor</option>
                                <option value="Startup">Startup</option>
                                <option value="Partner">Partner</option>
                            </select>

                            {/* <select value={selectedFilter} className='form-control select-opportunity-width mb-1' onChange={(e) => handleFilter(e.target.value, e.target.value === 'Blocked' ? true : e.target.value === 'Unblocked' ? false : null)}>
                                <option value="">All Users</option>
                                <option value="Blocked">Blocked Users</option>
                                <option value="Unblocked">Unblocked Users</option>
                            </select> */}

                        </div>
                        <table {...getTableProps()}>
                            <thead>
                                {
                                    headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps()}>
                                                        {column.render("Header")}
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {
                                    page.map(row => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()} >
                                                {/* <tr {...row.getRowProps()} onClick={() => router(`/startup-investor-detail/${row.original.userId}`)} > */}
                                                {
                                                    row.cells.map(cell => {
                                                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='btn-container'>
                        <button disabled={pageIndex === 0} onClick={() => gotoPage(0)}>First</button>
                        <button disabled={!canPreviousPage} onClick={previousPage}>Prev</button>
                        <span>{pageIndex + 1} of {pageCount}</span>
                        <button disabled={!canNextPage} onClick={nextPage}>Next</button>
                        <button disabled={pageIndex >= pageCount - 1} onClick={() => gotoPage(pageCount - 1)}>Last</button>
                    </div>
                </div>
            }
        </div>
    );
}

export default AdminHomeDetail