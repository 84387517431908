import React, { useEffect, useMemo, useState } from 'react'
import Loader from '../loader-component/Loader'
import api from '../apiConfig'
import { usePagination, useTable } from 'react-table';
import toast from 'react-hot-toast';
import './AdminHomeDetail.css';
import Swal from 'sweetalert2';

const ShowInterestedInvestorToAdmin = () => {
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState([]);

    const getUserData = async () => {
        setLoading(true);
        try {
            const response = await api.get("/admin/get-interested-investor-in-startup");
            if (response.data.success) {
                setUserData(response.data.userDetail);
                // localStorage.setItem('userData', JSON.stringify(updatedUsers));
            }
        } catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    const handleDelete = async (interestId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }
        ).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                try {
                    const response = await api.delete("/admin/delete-interested-investor-by-admin", { data: { interestId } });
                    if (response.data.success) {
                        toast.success(response.data.message);
                        getUserData();
                    }
                } catch (err) {
                    console.log(err);
                }
                finally {
                    setLoading(false);
                }
            }
        })
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Investor Name',
                accessor: 'investorName',
            },
            {
                Header: 'Investor Email',
                accessor: 'investorEmail',
            },
            {
                Header: 'Startup Name',
                accessor: 'startupName',
            },
            {
                Header: 'Startup Sector',
                accessor: 'startupSector',
            },
            {
                Header: 'Date',
                accessor: 'date',
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: ({ row }) => (
                    <button className="delete-investor-btn-css" onClick={() => handleDelete(row.original.interestId)}>Delete</button>
                ),
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        state: { pageIndex },
        pageCount,
        gotoPage,
    } = useTable(
        {
            columns,
            data: userData,
            initialState: { pageSize: 10 },
        },
        usePagination
    );

    return (
        <div className='admin-investor-verification-div'>
            <h3 className='text-center'>Interested Investor</h3>
            {
                loading ? (<Loader loading={true} />) : (
                    <div>
                        <div>
                            <table {...getTableProps()}>
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => (
                                                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className='btn-container'>
                            <button disabled={pageIndex === 0} onClick={() => gotoPage(0)}>
                                First
                            </button>
                            <button disabled={!canPreviousPage} onClick={previousPage}>
                                Prev
                            </button>
                            <span>
                                {pageIndex + 1} of {pageCount}
                            </span>
                            <button disabled={!canNextPage} onClick={nextPage}>
                                Next
                            </button>
                            <button disabled={pageIndex >= pageCount - 1} onClick={() => gotoPage(pageCount - 1)}>
                                Last
                            </button>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default ShowInterestedInvestorToAdmin