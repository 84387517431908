import React, { useEffect, useState } from 'react'
import Loader from '../../loader-component/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../apiConfig';
import toast from 'react-hot-toast';
import './InvestorVerification.css';

const InvestorDocumentDetail = () => {

    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [investorDetail, setInvestorDetail] = useState(null);
    const [verificationStatus, setVerificationStatus] = useState(null);
    const router = useNavigate();

    const getInvestorDetails = async () => {
        setLoading(true)
        try {
            const response = await api.post("/admin/get-startup-investor-detail", { userId: id })
            if (response.data.success) {
                setInvestorDetail(response.data.userData)
            }
        }
        catch (err) {
            toast.error(err.response.data.message)
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getInvestorDetails()
    }, [])

    const handleVerificationChange = (e) => {
        const value = e.target.value === 'approve' ? 1 : 2;
        setVerificationStatus(value)
    }

    const handleSave = async () => {
        try {
            const response = await api.patch("/admin/update-investor-verification-by-admin", { userId: id, isInvestorVerified: verificationStatus })
            if (response.data.success) {
                toast.success(response.data.message)
                router("/admin-home-detail")
            }
        }
        catch (err) {
            toast.error(err.response.data.message)
        }
    }

    return (
        <div>
            {loading ? (<Loader loading={true} />)
                :
                <div className='interest-single-startup-main-div d-flex justify-content-center'>
                    {
                        investorDetail ? <div className='mt-3 fouder-detail-css single-startup-detail-width test-width p-3'>
                            <div>
                                <h2 className='text-center'>Investor Detail</h2>
                            </div>
                            <div className='border-top pt-3'>
                                <h4 className='text-start'>Basic Detail</h4>
                            </div>
                            {
                                investorDetail?.investor ? <div>
                                    <div className='startup-general-body'>
                                        <div className='startup-general-startup-name-div'>
                                            <div className='py-2'>Investor Name :</div>
                                            <div className='py-2'>
                                                {investorDetail?.investor?.InvestorName ? <p>{investorDetail?.investor?.InvestorName}</p> : <p>--</p>}
                                            </div>
                                        </div>
                                        <div className='startup-general-startup-name-div'>
                                            <div className='py-2'>Investor Email :</div>
                                            <div className='py-2'>
                                                {investorDetail?.investor?.InvestorEmail ? <p>{investorDetail?.investor?.InvestorEmail}</p> : <p>--</p>}
                                            </div>
                                        </div>
                                        <div className='startup-general-startup-name-div'>
                                            <div className='py-2'>Investor Phone No :</div>
                                            <div className='py-2'>
                                                {investorDetail?.investor?.InvestorNumber ? <p>{investorDetail?.investor?.InvestorNumber}</p> : <p>--</p>}
                                            </div>
                                        </div>
                                        <div className='startup-general-startup-name-div'>
                                            <div className='py-2'>Investor Country :</div>
                                            <div className='py-2'>
                                                {investorDetail?.investor?.InvestorCountry ? <p>{investorDetail?.investor?.InvestorCountry}</p> : <p>--</p>}
                                            </div>
                                        </div>
                                        <div className='startup-general-startup-name-div'>
                                            <div className='py-2'>Investor State :</div>
                                            <div className='py-2'>
                                                {investorDetail?.investor?.InvestorState ? <p>{investorDetail?.investor?.InvestorState}</p> : <p>--</p>}
                                            </div>
                                        </div>
                                        <div className='startup-general-startup-name-div'>
                                            <div className='py-2'>Investor City :</div>
                                            <div className='py-2'>
                                                {investorDetail?.investor?.InvestorCity ? <p>{investorDetail?.investor?.InvestorCity}</p> : <p>--</p>}
                                            </div>
                                        </div>
                                        <div className='startup-general-startup-name-div'>
                                            <div className='py-2'>Investor Pincode :</div>
                                            <div className='py-2'>
                                                {investorDetail?.investor?.InvestorPincode ? <p>{investorDetail?.investor?.InvestorPincode}</p> : <p>--</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                    <div className='startup-general-body'>
                                        No data available
                                    </div>
                            }
                            <div className='pt-3'>
                                <h4 className='text-start'>Other Detail</h4>
                            </div>
                            {
                                investorDetail?.investor ?
                                    <div>
                                        <div className='startup-general-body'>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Investor Linkdin :</div>
                                                <div className='py-2'>
                                                    {investorDetail?.investor?.InvestorLinkdinUrl ? <p>{investorDetail?.investor?.InvestorLinkdinUrl}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Investor Facebook :</div>
                                                <div className='py-2'>
                                                    {investorDetail?.investor?.InvestorFacebookUrl ? <p>{investorDetail?.investor?.InvestorFacebookUrl}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Investor Twitter :</div>
                                                <div className='py-2'>
                                                    {investorDetail?.investor?.InvestorTwitterUrl ? <p>{investorDetail?.investor?.InvestorTwitterUrl}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Investor Activities :</div>
                                                <div className='py-2'>
                                                    {investorDetail?.investor?.InvestorAddActvities ? <p>{investorDetail?.investor?.InvestorAddActvities}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Investor Education Background :</div>
                                                <div className='py-2'>
                                                    {investorDetail?.investor?.InvestorEducationBackground ? <p>{investorDetail?.investor?.InvestorEducationBackground}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Investor Work Experience :</div>
                                                <div className='py-2'>
                                                    {investorDetail?.investor?.InvestorWorkExperience ? <p>{investorDetail?.investor?.InvestorWorkExperience}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Investor Association :</div>
                                                <div className='py-2'>
                                                    {investorDetail?.investor?.InvestorAssociation ? <p>{investorDetail?.investor?.InvestorAssociation}</p> : <p>--</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className='startup-general-body'>
                                        No data available
                                    </div>

                            }
                            <div className='pt-3'>
                                <h4 className='text-start'>Investor Aadharcard</h4>
                            </div>
                            {
                                investorDetail?.investorProfile?.length ?
                                    investorDetail?.investorProfile.map((document, index) =>
                                        document.aadharFront || document.aadharBack ? (
                                            <div className='startup-general-body' key={index}>
                                                <div className='startup-general-startup-name-div'>
                                                    <div className='py-2'>Investor Aadharcard Front image :</div>
                                                    <div className='py-2'>
                                                        {document.aadharFront ? <img src={document.aadharFront} className='doc-image-width' alt="Aadhar Front" /> : <p>--</p>}
                                                    </div>
                                                </div>
                                                <div className='startup-general-startup-name-div '>
                                                    <div className='py-2'>Investor Aadharcard Back image :</div>
                                                    <div className='py-2'>
                                                        {document.aadharBack ? <img src={document.aadharBack} className='doc-image-width' alt="Aadhar Back" /> : <p>--</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null) :
                                    <div className='startup-general-body'>
                                        No data available
                                    </div>
                            }
                            <div className='pt-3'>
                                <h4 className='text-start'>Investor Pancard</h4>
                            </div>
                            {
                                investorDetail?.investorProfile?.length ?
                                    investorDetail?.investorProfile.map((document, index) => document.panCard ? (
                                        <div className='startup-general-body' key={index}>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Investor Pancard image :</div>
                                                <div className='py-2'>
                                                    {document?.panCard ? <img src={document?.panCard} className='doc-image-width' alt="" /> : <p>--</p>}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null)
                                    :
                            <div className='startup-general-body'>
                                No data available
                            </div>
                            }
                            <div className='pt-3'>
                                <h4 className='text-start'>Investor Passport</h4>
                            </div>
                            {
                                investorDetail?.investorProfile?.length ?
                                    investorDetail?.investorProfile.map((document, index) => document.passportFront || document.passportBack ? (
                                        <div className='startup-general-body' key={index}>
                                            <div className='startup-general-startup-name-div'>
                                                <div className='py-2'>Investor Passport Front image :</div>
                                                <div className='py-2'>
                                                    {document.passportFront ? <img src={document.passportFront} className='doc-image-width' alt="" /> : <p>--</p>}
                                                </div>
                                            </div>
                                            <div className='startup-general-startup-name-div '>
                                                <div className='py-2'>Investor Passport Back image :</div>
                                                <div className='py-2'>
                                                    {document.passportBack ? <img src={document.passportBack} className='doc-image-width' alt="" /> : <p>--</p>}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null)
                                    :
                                    <div className='startup-general-body'>
                                        No data available
                                    </div>
                            }

                            <div className=' pt-2 d-flex justify-content-center'>
                                <input type="radio" className='mx-2 verification-padding' value='approve' checked={verificationStatus === 1} onChange={handleVerificationChange} />
                                <label className='verification-padding'>Approve
                                </label>

                                <input type="radio" className='mx-2 ' value='reject' checked={verificationStatus === 2} onChange={handleVerificationChange} />
                                <label className='verification-padding'>Reject</label>
                                <button type='submit' className='mx-2 verify-submit-button verification-padding' onClick={handleSave}>
                                    Save
                                </button>
                            </div>

                        </div> : <div className='mt-3 fouder-detail-css single-startup-detail-width test-width p-3'>
                            <h1 className='text-center'>No Data Available</h1>
                            <div className=' pt-2 d-flex justify-content-center'>
                                <input type="radio" className='mx-2 verification-padding' value='approve' checked={verificationStatus === 1} onChange={handleVerificationChange} />
                                <label className='verification-padding'>Approve
                                </label>

                                <input type="radio" className='mx-2 ' value='reject' checked={verificationStatus === 2} onChange={handleVerificationChange} />
                                <label className='verification-padding'>Reject</label>
                                <button type='submit' className='mx-2 verify-submit-button verification-padding' onClick={handleSave}>
                                    Save
                                </button>
                            </div>
                        </div>

                    }

                </div>
            }
        </div>
    )
}

export default InvestorDocumentDetail